import * as meetings from './meetingsActionTypes';
import * as restaurants from './restaurantsActionTypes';
import * as core from './coreActionTypes';
import * as ageGroups from './ageGroupsActionTypes';
import * as ageRanges from './ageRangesActionTypes';
import * as cities from './citiesActionTypes';
import * as clubs from './clubsActionTypes'
import * as settings from './settingsActionTypes';
import * as participants from './participantsActionTypes';
import * as resources from './resourcesActionTypes';
import * as messagesTypes from './messagesTypesActionTypes';
import * as messagesEvents from './messagesEventsActionTypes';
import * as meetingsMessages from './meetingsMessagesActionTypes';
import * as participantMessages from './participantMessagesActionTypes';
import * as emailTemplates from './emailTemplatesActionTypes';

const actionTypes = {
	meetings,
	restaurants,
	core,
	ageGroups,
	ageRanges,
	cities,
	clubs,
	participants,
	settings,
	resources,
	messagesTypes,
	messagesEvents,
	meetingsMessages,
	participantMessages,
	emailTemplates,
};

export default actionTypes;