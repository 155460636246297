import React, { Component } from 'react'
import { withLang } from '../../utils/withLang'
import MeetingCard from './MeetingCard'
import Pagination from '../../components/Pagination'
import { connect } from 'react-redux'
import selectors from '../../redux/selectors'
import actions from '../../redux/actions'
import { setDocumentTitle } from '../../utils/functions'
import SpinnerBox from '../../components/SpinnerBox'
import PageTitle from '../../components/PageTitle'
import { PATHS } from '../../config/constants'
import { withRouter } from 'react-router-dom'
import {Button} from "reactstrap";
import LineAwesome from "../../components/LineAwesome";
import {
  duplicateCurrentWeek,
  duplicateLastMeetingWeek,
  sendSMSFillUpMen
} from "../../redux/actions/meetingActions";

const mapStateToProps = state => ({
  ids: selectors.meetings.getIds(state),
  count: selectors.meetings.getCount(state),
  isFetching: selectors.meetings.getIsFetching(state),
})

class MeetingsHome extends Component {
  state = {
    page: null,
  }

  componentDidMount () {
    setDocumentTitle('Meetings')

    this.getPage()
  }

  componentDidUpdate () {
    this.getPage()
  }

  // Get number of page from Pagination input. And fetch in teh end.
  getPage = () => {
    let params = new URLSearchParams(this.props.location.search)

    const pageParam = params.get('page') ? +params.get('page') : 1

    if (pageParam !== this.state.page) {
      this.setState(
        { page: pageParam },
        () => this.fetch(),
      )
    }
  }

  //Open modal for creation new Meeting
  handleNewClick = () => {
    this.props.dispatch(actions.meetings.openModalForm())
  }

  //Open modal for updating new Meeting
  handleOpenClick = meetingId => {
    this.props.dispatch(actions.meetings.openModalForm(meetingId))
  }

  handleDuplicateCurrentWeekClick = () => {
    this.props.dispatch(duplicateCurrentWeek())
  }

  handleDuplicateLastMeetingClick = () => {
    this.props.dispatch(duplicateLastMeetingWeek())
  }

  // Get data from server. Dispatch action to reducer.
  fetch () {
    const { page } = this.state
    this.props.dispatch(
      actions.meetings.crudRead({ page }),
    )
  }

  render () {
    const { count, ids, isFetching, lang } = this.props
    if (isFetching)
      return (
        <SpinnerBox spinner={true}/>
      )

    const Pag = () => (
      <Pagination
        totalItems={count}
        path={PATHS.MEETINGS}
      />
    )

    return (
      <div className="meetings">
        <PageTitle
          title={lang.meetings}
          icon="calendar-check-o"
          onCreateClick={this.handleNewClick}
        />
        <Button
            onClick={this.handleDuplicateLastMeetingClick}
            color="info">
            <LineAwesome icon="retweet"/> {lang.duplicate_last_meeting_week}
          </Button>

        <Button
            className={'mr-2'}
            onClick={this.handleDuplicateCurrentWeekClick}
            color="info">
            <LineAwesome icon="recycle"/> {lang.duplicate_current_week}
          </Button>

        <Pag/>


        <div className="row">
          {
            ids.map(id => (
              <div className="col" key={id}>
                <MeetingCard
                  meetingId={id}
                  onClick={this.handleOpenClick}
                />
              </div>
            ))
          }
        </div>

        {
          ids.length === 0 && (
            <p className="text-muted text-center">{lang.empty}</p>
          )
        }

        <Pag/>
      </div>
    )
  }
}

export default withRouter(withLang(connect(mapStateToProps)(MeetingsHome)))