import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import selectors from '../redux/selectors/index'
import { connect } from 'react-redux'


const mapStateToProps = state => ({
  clubs: selectors.clubs.getAll(state),
})

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

class SelectClub extends Component {
  
  render () {
    const {
      clubs,
      value,
      onChange,
      ...otherProps
    } = this.props

    const clubsOptions = clubs.map(({ id, display_name }) => ({
      value: id,
      label: display_name,
    }))

    return (
      <Select
        type="select"
        placeholder=""
        isClearable
        value={clubsOptions.filter(option => option.value === value)}
        options={clubsOptions}
        onChange={( clubOption ) => {
          clubOption ? onChange(clubOption.value) : onChange(null)
          }}
        {...otherProps}
      />
    )
  }
}

SelectClub.propTypes = propTypes

export default connect(mapStateToProps)(SelectClub)