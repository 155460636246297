import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import MeetingsModule from '../../modules/meetings/MeetingsModule'
import ParticipantsModule from '../../modules/participants/ParticipantsModule'
import RestaurantsModule from '../../modules/restaurants/RestaurantsModule'
import AgeGroups from '../../modules/settings/AgeGroups/AgeGroupsHome'
import MeetingCycle from '../../modules/settings/MeetingCycle/MeetingCycle'
import { HOME_PATH, PATHS } from '../../config/constants'
import SignIn from '../../modules/auth/SignIn'
import NotFound from '../../modules/common/NotFound'
import MessagesTypesModule from '../../modules/messages-types/MessagesTypesModule'
import MessagesEventsModule from '../../modules/messages-events/MessagesEventsModule'
import EmailTemplatesModule from "../../modules/email-templates/EmailTemplatesModule";
import CampaignsModule from "../../modules/campaigns/CampaignsModule";

class AppRoutes extends Component {
  render () {
    return (
      <Switch>
        <Redirect
          exact
          path="/"
          to={HOME_PATH}
        />
        <Route
          path={PATHS.MEETINGS}
          component={MeetingsModule}
        />
        <Route
          path={PATHS.PARTICIPANTS}
          component={ParticipantsModule}
        />
        <Route
          path={PATHS.RESTAURANTS}
          component={RestaurantsModule}
        />
        <Route
          exact
          path={PATHS.AGE_GROUPS}
          component={AgeGroups}
        />
        <Route
          exact
          path={PATHS.MEETING_CYCLE}
          component={MeetingCycle}
        />
        <Route
          exact
          path={PATHS.SIGNIN}
          component={SignIn}
        />
        <Route
          exact
          path={PATHS.MESSAGES_TYPES}
          component={MessagesTypesModule}
        />
        <Route
          exact
          path={PATHS.MESSAGES_EVENTS}
          component={MessagesEventsModule}
        />
        <Route
          exact
          path={PATHS.CAMPAIGNS}
          component={CampaignsModule}
        />
        <Route
          exact
          path={PATHS.EMAIL_TEMPLATES}
          component={EmailTemplatesModule}
        />
        <Route
          path="**"
          component={NotFound}
        />
      </Switch>
    )
  }
}

export default AppRoutes