import React, { Component } from 'react'
import MeetingEventForm from './MeetingEventForm'
import { connect } from 'react-redux'
import MeetingEventsList from './MeetingEventsList'

const mapStateToProps = state => ({
  meeting: state.forms.meeting
})

class MeetingEvents extends Component {
  render () {
    const { meeting } = this.props

    return (
      <div className="meeting-events">
          <MeetingEventForm/>
          <br/>
          <MeetingEventsList
          meeting={meeting}
          />

      </div>
    )
  }
}

export default connect(mapStateToProps)(MeetingEvents)