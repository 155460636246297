import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions as ReduxFormActions, Form } from 'react-redux-form'
import { withLang } from '../../../utils/withLang'
import { customFormUpdate } from '../../../redux/actions/coreActions'
import { Checkbox } from '../../../components/Checkbox'
import selectors from '../../../redux/selectors'

const mapStateToProps = state => ({
  meetingTasks: state.forms.meetingState.tasks,
  meetingCycle: selectors.settings.get(state),
})

const placeholder = document.createElement('li')
placeholder.className = 'placeholder'

class MeetingStateTasksForm extends Component {
  dragged = null
  over = null

  globalTaskIndex = null

  constructor (props) {
    super(props)

    this.dragStart = this.dragStart.bind(this)
    this.dragEnd = this.dragEnd.bind(this)
    this.dragOver = this.dragOver.bind(this)
  }

  dragStart (e) {
    this.dragged = e.currentTarget
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', this.dragged)
  }

  dragEnd () {
    const { meetingTasks: state, dispatch } = this.props
    this.dragged.style.display = 'flex'
    this.over.parentNode.removeChild(placeholder)

    let taskGlobalIndexFrom = +this.dragged.dataset.globalIndex,
      taskGlobalIndexTo = +this.over.dataset.globalIndex,
      draggedTask = { ...state[taskGlobalIndexFrom] },
      replacedTask = { ...state[taskGlobalIndexTo] }

    // Add a condition: if taskGlobalIndexTo > taskGlobalIndexFrom ? taskGlobalIndexTo should be decreased on 1
    if (isNaN(taskGlobalIndexTo)) return

    if (taskGlobalIndexTo > taskGlobalIndexFrom) {
      --taskGlobalIndexTo
    }

    let newState = [
      ...state.slice(0, taskGlobalIndexFrom),
      ...state.slice(taskGlobalIndexFrom + 1),
    ]

    newState.splice(taskGlobalIndexTo, 0, {
      ...draggedTask,
      status: replacedTask.status,
      order: replacedTask.order,
    })

    newState = newState.map((task, globalIndex) => {
      const prevTask = state[globalIndex - 1]
      const prevStatus = prevTask &&
        prevTask.status

      const prevOrder = prevStatus && prevStatus ===
      task.status
        ? prevTask.order
        : -1

      return {
        ...task,
        order: prevOrder + 1,
      }
    })

    dispatch(ReduxFormActions.change(
      `forms.meetingState.tasks`,
      newState,
      ),
    )
  }

  dragOver (e) {
    e.preventDefault()
    this.dragged.style.display = 'none'
    if (e.target.className === 'placeholder') return
    this.over = e.target
    e.target.parentNode.insertBefore(placeholder, e.target)
  }

  updateTaskState = (taskIndex, isDone) => {
    this.props.dispatch(
      customFormUpdate(
        `forms.meetingState.tasks[${taskIndex}].is_done`,
        isDone),
    )
  }

  render () {
    let { meetingCycle } = this.props
    this.globalTaskIndex = 0

    return (
      <Form model="forms.meetingState.tasks">
        {
          meetingCycle.map((stage, stageIndex) => {
              return (
                <div className="tasks-editor-stage"
                     key={stageIndex}>
                  <h5 className="text-center">
                    {stage.name} #{stageIndex + 1}
                  </h5>
                  <ul className="list-group"
                      data-id={stage.id}
                      onDragOver={this.dragOver.bind(this)}>
                    {
                      this.renderTasksForStage(stage, stageIndex)
                    }
                  </ul>
                </div>
              )
            },
          )
        }
      </Form>
    )
  }

  /*
    htmlFor
    The label which gets an event click stores the global task
    index in its `htmlFor` property.
   */
  renderTasksForStage (stage, stageIndex) {
    const { meetingTasks } = this.props

    return meetingTasks.filter(task => task.status === stage.id).map((task, taskIndex) => {
      const output = (
        <li className="list-group-item"
            key={`${stageIndex}-${taskIndex}`}
            data-order={task.order}
            data-global-index={this.globalTaskIndex}
            draggable='true'
            onDragEnd={this.dragEnd.bind(this)}
            onDragStart={this.dragStart.bind(this)}>
          <b>{this.globalTaskIndex + 1}.</b> &nbsp; {task.name}

          <Checkbox
            id={this.globalTaskIndex}
            status={task.is_done}
            onClick={({ target: { htmlFor, tagName } }) => {
              if (tagName !== 'LABEL') return

              this.updateTaskState(
                htmlFor,
                !task.is_done,
              )
            }}
          />
        </li>
      )

      this.globalTaskIndex++

      return output
    })
  }
}

export default withLang(connect(mapStateToProps)(MeetingStateTasksForm))
