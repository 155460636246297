import React, { Component } from 'react'
import Select from 'react-select'
import { withLang } from '../utils/withLang'

class SelectBoolean extends Component {
  render () {
    const {
      value,
      name,
      onChange,
      lang,
      ...otherProps
    } = this.props

    const options = [
      { label: lang.yes, value: 1 },
      { label: lang.no, value: 0 },
    ]

    return (
      <Select
        type="select"
        id={name}
        name={name}
        placeholder=""
        value={options.filter(option => option.value === value)}
        options={options}
        onChange={({ value }) => onChange(value)}
        {...otherProps}
      />
    )
  }
}

export default withLang(SelectBoolean)