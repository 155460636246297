import React, { Component } from 'react'
import { withLang } from '../../../utils/withLang'
import EventTypeSelect from '../../../components/SelectEventType'
import { EVENT_TYPE_GENERAL, EVENT_TYPE_PARTICIPATION } from '../../../config/constants'
import ParticipantStatus from '../../participants/components/ParticipantStatus'
import { Button, Form } from 'reactstrap'
import actions from '../../../redux/actions'
import { connect } from 'react-redux'
import Input from 'reactstrap/es/Input'
import ParticipantSearch from '../../participants/components/ParticipantSearch'
import { getFullName } from '../../../utils/functions'

class MeetingEventForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      type: '',
      participant: null,
      status: null,
      message: ''
    }
  }

  handleTypeChange = type => {
    this.setState({ type })
  }

  handleParticipantChange = participant => {
    this.setState({
      participant
    })
  }

  handleStatusChange = (id, status) => {
    this.setState({
      status
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { dispatch } = this.props
    const { type, participant, status, message } = this.state

    switch (type) {
      case EVENT_TYPE_PARTICIPATION:
        if (!participant || status === null) return
        dispatch(actions.meetings.addParticipationEvent(participant, status))
        break
      case EVENT_TYPE_GENERAL:
        if (!message) return
        dispatch(actions.meetings.addCustomEvent(message))
        break
      default:
        return
    }

    this.setState({
      type: '',
      participant: null,
      status: null,
      message: ''
    })
  }

  handleMessageChange = e => {
    this.setState({
      message: e.target.value
    })
  }

  render () {
    const { lang } = this.props
    const {
      participant,
      status,
      type,
      message
    } = this.state

    return (
      <div className="meeting-event-form">
        <Form onSubmit={this.handleSubmit}>
          <EventTypeSelect
            value={type}
            onChange={this.handleTypeChange}
          />

          {
            type === EVENT_TYPE_PARTICIPATION && (
              <>
                {
                  !participant && (
                    <ParticipantSearch
                      onSelect={this.handleParticipantChange}
                    />
                  )
                }

                {
                  participant && this.renderParticipant()
                }
              </>
            )
          }

          {
            type === EVENT_TYPE_PARTICIPATION && (
              <ParticipantStatus
                label={lang.participation_status}
                status={status}
                onChange={this.handleStatusChange}
              />
            )
          }

          {
            type === EVENT_TYPE_GENERAL && (
              <Input
                value={message}
                onChange={this.handleMessageChange}
                name="type"
              />
            )
          }

          <div className="text-center mt-3">
            <Button
              color="info"
              size="sm"
              type="submit">
              {lang.add_new_event}
            </Button>
          </div>
        </Form>
      </div>
    )
  }

  renderParticipant () {
    const { lang } = this.props
    const {
      participant
    } = this.state

    return (
      <div className="text-center">
        <b>{lang.participant}: </b>
        {getFullName(participant)}

        <Button onClick={() => this.setState({ participant: null })}
                color="link"
                size="sm">
          {lang.change}
        </Button>
      </div>
    )
  }
}

export default withLang(connect()(MeetingEventForm))