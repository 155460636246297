import actions from './index'
import { lang } from '../../utils/withLang'
import { ENDPOINTS, ENTITY_MESSAGE_TYPES, MODAL_MESSAGES_TYPES_MANAGE, } from '../../config/constants'
import { customFormUpdate } from './coreActions'
import selectors from '../selectors'
import actionTypes from '../action-types'
import { disassembleResults, setUrlParams } from '../../utils/functions'
import { storeEntities } from './resourceActions'
import axios from 'axios'
import { toast } from 'react-toastify'
import { generatePath } from 'react-router-dom'

/***********************
 * REQUESTS
 */

export const requestRead = options => (
  axios.get(setUrlParams(ENDPOINTS.MESSAGES_TYPES, options)).then(
    response => response.data)
)

export const requestCreate = messagesType => (
  axios.post(ENDPOINTS.MESSAGES_TYPES, { ...messagesType }).then(
    response => response.data)
)

export const requestUpdate = messagesType => (
  axios.patch(
    generatePath(ENDPOINTS.MESSAGES_TYPE, { id: messagesType.id }),
    { ...messagesType },
  )
)

export const requestDelete = id => {
  return (
    axios.delete(generatePath(ENDPOINTS.MESSAGES_TYPE, { id }))
  )
}

/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = (options = {}) => (dispatch, getState) => {

  dispatch(crudReadPending())
  return requestRead(options).then(response => {
    dispatch(crudReadSucceeded(response))
    return response.data
  })
}

export const crudReadPending = () => ({
  type: actionTypes.messagesTypes.CRUD_READ_PENDING,
})

export const crudReadSucceeded = data => dispatch => {
  const results = disassembleResults(data, ENTITY_MESSAGE_TYPES)
  dispatch(storeEntities(results.entities))
  dispatch({
    type: actionTypes.messagesTypes.CRUD_READ_SUCCEEDED,
    payload: results,
  })
}

/** READ */

/** CREATE */
export const crudCreate = messagesType => dispatch => (
  requestCreate(messagesType).then(
    ({ data: createdMessagesType }) => {
      toast.success(lang.successfully_created)
      dispatch(crudCreateSucceeded(createdMessagesType))
      dispatch(crudRead())
      return createdMessagesType
    })
)

export const crudCreateSucceeded = result => ({
  type: actionTypes.messagesTypes.CRUD_UPDATE_SUCCEEDED,
  payload: { ...result },
})/** CREATE */

/** UPDATE */
export const crudUpdate = messagesType => dispatch => (
  requestUpdate(messagesType).then(response => {
    toast.success(lang.successfully_updated)
    dispatch(crudUpdateSucceeded(response.data))
    dispatch(crudRead())
    return response.data
  })
)

export const crudUpdateSucceeded = messagesType => ({
  type: actionTypes.messagesTypes.CRUD_UPDATE_SUCCEEDED,
  payload: { ...messagesType },
})/** UPDATE */

/** DELETE */

export const crudDelete = messagesType => dispatch => {
  return (
    requestDelete(
      typeof messagesType === 'object' ? messagesType.id : messagesType).then(() => {
      toast.success(lang.successfully_deleted)
      dispatch(crudDeleteSucceeded())
      dispatch(crudRead())
    })
  )
}

export const crudDeleteSucceeded = () => ({
  type: actionTypes.messagesTypes.CRUD_DELETE_SUCCEEDED,
})

export const crudDeleteFailed = () => ({
  type: actionTypes.messagesTypes.CRUD_DELETE_FAILED,
})
/** DELETE */

/***********************
 MANAGE
 **********************/

export const openModalForm = (messagesTypeId = null) => (
  dispatch, getState) => {
  if (messagesTypeId) {
    const messagesType = selectors.messagesTypes.getById(getState(),
      messagesTypeId)
    dispatch(customFormUpdate('forms.messagesType', messagesType, true))
  }
  dispatch(actions.core.openModalForm({
    title: lang[!messagesTypeId ? 'new_message_type' : 'edit_message_type'],
    component: MODAL_MESSAGES_TYPES_MANAGE,
    form: 'messagesType',
    isNew: !messagesTypeId,
  }))
}