import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import {
  GENDER_MAN,
  PARTICIPATION_STATUS_ARRIVED,
  PARTICIPATION_STATUS_CONFIRMED,
  PARTICIPATION_STATUS_REGISTERED,
  PARTICIPATION_STATUS_WAITING,
  PARTICIPATION_STATUS_ANSWERED_QUESTIONNAIRE,
  PARTICIPATION_STATUS_CONFIRMED_CANCELLED_BY_SYS,
  PARTICIPATION_STATUS_CANCELLED_BY_SYS,
} from '../../../config/constants'

const propTypes = {
  participants: PropTypes.array.isRequired,
}

class MeetingParticipantsPreview extends Component {
  initialTable = {
    phone_verified_registered: {
      men: 0,
      women: 0,
    },
    phone_verified_waiting: {
      men: 0,
      women: 0,
    },
    arrived: {
      men: 0,
      women: 0,
    },
    confirmed: {
      men: 0,
      women: 0,
    },
    registered: {
      men: 0,
      women: 0,
    },
    waiting: {
      men: 0,
      women: 0,
    },
    answered_questionnaire: {
      men: 0,
      women: 0,
    },
    canceled_by_sys: {
      men: 0,
      women: 0,
    },
    confirmed_canceled_by_sys: {
      men: 0,
      women: 0,
    },
  }

  constructor (props) {
    super(props)

    this.state = {
      table: { ...this.initialTable },
    }
  }

  componentDidMount () {
    const table = this.calcTable()
    this.setState({ table })
  }

  calcTable () {
    const { participants } = this.props
    const table = { ...this.initialTable }

    participants.forEach(p => {
      const gender = p.gender === +GENDER_MAN ? 'men' : 'women'

      switch (p.status) {

        case PARTICIPATION_STATUS_ANSWERED_QUESTIONNAIRE:
          table.registered[gender] += 1
          table.confirmed[gender] += 1
          table.arrived[gender] += 1
          table.answered_questionnaire[gender] += 1
          if (p.is_phone_verified)
            table.phone_verified_registered[gender] += 1
          break

        case PARTICIPATION_STATUS_ARRIVED:
          table.registered[gender] += 1
          table.confirmed[gender] += 1
          table.arrived[gender] += 1
          if (p.is_phone_verified)
            table.phone_verified_registered[gender] += 1
          break

        case PARTICIPATION_STATUS_CONFIRMED:
          table.registered[gender] += 1
          table.confirmed[gender] += 1
          if (p.is_phone_verified)
            table.phone_verified_registered[gender] += 1
          break

        case PARTICIPATION_STATUS_REGISTERED:
          table.registered[gender] += 1
          if (p.is_phone_verified)
            table.phone_verified_registered[gender] += 1

          break

        case PARTICIPATION_STATUS_WAITING:
          table.waiting[gender] += 1
          if (p.is_phone_verified)
            table.phone_verified_waiting[gender] += 1

          break

        case PARTICIPATION_STATUS_CONFIRMED_CANCELLED_BY_SYS:
          table.confirmed_canceled_by_sys[gender] += 1
          break

        case PARTICIPATION_STATUS_CANCELLED_BY_SYS:
          table.canceled_by_sys[gender] += 1
          break

        default:
          break
      }
    })

    return table
  }

  render () {
    const { lang } = this.props
    const { table } = this.state

    return (
      <Table bordered={true} size="sm">
        <tbody>
        <tr>
          <th/>
          <th>{lang.men}</th>
          <th>{lang.women}</th>
        </tr>
        <tr>
          <th>{lang.registered}</th>
          <td>{table.registered.men}</td>
          <td>{table.registered.women}</td>
        </tr>

        <tr>
          <th>{lang.phone_verified_registered}</th>
          <td>{table.phone_verified_registered.men}</td>
          <td>{table.phone_verified_registered.women}</td>
        </tr>

        <tr>
          <th>{lang.confirmed}</th>
          <td>{table.confirmed.men}</td>
          <td>{table.confirmed.women}</td>
        </tr>

        <tr>
          <th>{lang.arrived}</th>
          <td>{table.arrived.men}</td>
          <td>{table.arrived.women}</td>
        </tr>

        <tr>
          <th>{lang.answered_questionnaire}</th>
          <td>{table.answered_questionnaire.men}</td>
          <td>{table.answered_questionnaire.women}</td>
        </tr>

        <tr>
          <th>{lang.waiting}</th>
          <td>{table.waiting.men}</td>
          <td>{table.waiting.women}</td>
        </tr>
        <tr>
          <th>{lang.phone_verified_waiting}</th>
          <td>{table.phone_verified_waiting.men}</td>
          <td>{table.phone_verified_waiting.women}</td>
        </tr>
        <tr>
          <th>{lang.canceled_by_sys}</th>
          <td>{table.canceled_by_sys.men}</td>
          <td>{table.canceled_by_sys.women}</td>
        </tr>
        <tr>
          <th>{lang.confirmed_canceled_by_sys}</th>
          <td>{table.confirmed_canceled_by_sys.men}</td>
          <td>{table.confirmed_canceled_by_sys.women}</td>
        </tr>
        </tbody>
      </Table>
    )
  }
}

MeetingParticipantsPreview.propTypes = propTypes

export default withLang(MeetingParticipantsPreview)