import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import selectors from '../redux/selectors/index'
import { connect } from 'react-redux'


const mapStateToProps = state => ({
  cities: selectors.cities.getAll(state),
})

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

class SelectCity extends Component {
  render () {
    const {
      cities,
      value,
      onChange,
      ...otherProps
    } = this.props

    const options = cities.map(({ id, name }) => ({
      value: id,
      label: name,
    }))

    return (
      <Select
        type="select"
        name="city"
        id="participant-data-city"
        placeholder=""
        value={options.filter(option => option.value === value)}
        options={options}
        onChange={({ value }) => onChange(value)}
        {...otherProps}
      />
    )
  }
}

SelectCity.propTypes = propTypes

export default connect(mapStateToProps)(SelectCity)