import React, { Component } from 'react'
import Logo from '../../components/Logo'

class ApiUnavailable extends Component {
  render () {
    return (
      <div className="error-page" dir="ltr">
        <Logo/>

        <div className="error-page-title">
          Server is unavailable
        </div>

        <div className="error-page-subtitle">
          The application server did not return a response
          <br/><br/>
          <small>
            Please, refresh the page and try again. <br/>
            If you will see this error again, please provide it to the support.
          </small>
        </div>
      </div>
    )
  }
}

export default ApiUnavailable