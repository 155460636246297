import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import LineAwesome from './LineAwesome'
import { withLang } from '../utils/withLang'
import { connect } from 'react-redux'
import actions from '../redux/actions'

const propTypes = {
  id: PropTypes.number.isRequired,
  entity: PropTypes.string.isRequired,
}

class ReactTableEditCreateButtons extends Component {
  handleEditClick = () => {
    const {
      id,
      entity,
      dispatch,
    } = this.props

    dispatch(actions[entity]['openModalForm'](id))
  }

  handleDeleteClick = () => {
    const { id, entity, lang, dispatch } = this.props

    if (window.confirm(lang.are_you_sure)) {
      dispatch(actions[entity].crudDelete(id))
    }
  }

  render () {
    const { lang } = this.props

    return (
      <>
        <Button color="icon"
                size="sm"
                className="text-info ml-3"
                onClick={this.handleEditClick}>
          <LineAwesome icon="folder-open"/> {lang.edit}
        </Button>
        <Button color="icon"
                size="sm"
                className="text-danger"
                onClick={this.handleDeleteClick}>
          <LineAwesome icon="trash"/> {lang.delete}
        </Button>
      </>
    )
  }
}

ReactTableEditCreateButtons.propTypes = propTypes

export default withLang(connect()(ReactTableEditCreateButtons))