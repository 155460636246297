import * as core from './coreActions'
import * as resources from './resourceActions'
import * as meetings from './meetingActions'
import * as restaurants from './restaurantActions'
import * as participants from './participantActions'
import * as ageGroups from './ageGroupActions'
import * as ageRanges from './ageRangesActions'
import * as cities from './citiesActions'
import * as settings from './settingActions'
import * as messagesTypes from './messagesTypesActions'
import * as messagesEvents from './messagesEventsActions'
import * as meetingsMessages from './meetingsMessagesActions'
import * as emailTemplates from './emailTemplatesActions'

const actions = {
  core,
  resources,
  meetings,
  restaurants,
  participants,
  messagesTypes,
  messagesEvents,
  ageGroups,
  ageRanges,
  cities,
  settings,
  meetingsMessages,
  emailTemplates,
}

export default actions