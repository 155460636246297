import React, { Component } from 'react';
import { withLang } from '../../utils/withLang';
import { createToken, disableLayout } from '../../redux/actions/coreActions';
import SpinnerBox from '../../components/SpinnerBox';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LineAwesome from '../../components/LineAwesome';
import {
	Control,
	Form as ReactReduxForm,
	actions as ReduxFormActions,
} from 'react-redux-form';
import { Button, Form, Input, Label } from 'reactstrap';
import selectors from '../../redux/selectors';
import { isAuthenticated } from '../../utils/functions';
import { PATHS } from '../../config/constants';
import { auth as authInitialState } from '../../redux/forms';

const mapStateToProps = state => ({
	isAuth: isAuthenticated(selectors.core.getAuth(state)),
});

class SignIn extends Component {
	constructor (props) {
		super(props);

		this.state = {
			isFetching: false,
			isSuccessful: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);

		props.dispatch(disableLayout());
	}

	handleSubmit ({ login, password, otp }) {
		const { history, dispatch } = this.props;

		this.setState({ isFetching: true });

		dispatch(createToken(login, password, otp)).
			then(() => {
				history.push(PATHS.MEETINGS);
			}).
			catch(() => this.setState({ isFetching: false }));
	}

	// reset the auth form
	componentWillUnmount () {
		const { dispatch } = this.props;
		dispatch(ReduxFormActions.change('forms.auth', authInitialState));
	}

	render () {
		const { lang } = this.props;
		const { isFetching, isSuccessful } = this.state;

		return (
			<div className="sign-in">
				<img src="/images/ananas_color.png" className="logo"
					 alt="Happy Ananas" title="Happy Ananas"/>

				<div className="sign-in-box">
					<Form
						tag={ReactReduxForm}
						model='forms.auth'
						onSubmit={this.handleSubmit}>

						<div className="form-group">
							<Label>{lang.email}: </Label>
							<Input
								required
								model=".login"
								tag={Control.text}
								name="email"
							/>
						</div>

						<div className="form-group">
							<Label>{lang.password}: </Label>
							<Input
								type="password"
								required
								model=".password"
								tag={Control.text}
								name="password"
							/>
						</div>

						<div className="form-group">
							<Label>{lang.code}: </Label>
							<Input
								type="text"
								required
								model=".otp"
								tag={Control.text}
								name="otp"
							/>
						</div>

						<Button color="primary">
							<LineAwesome icon="lock"/> {lang.sign_in}
						</Button>

					</Form>

					<SpinnerBox
						spinner={isFetching}
						success={isSuccessful}
					/>
				</div>
			</div>
		);
	}
}

export default withRouter(withLang(connect(mapStateToProps)(SignIn)));