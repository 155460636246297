import React, { Component } from 'react'
import { connect } from 'react-redux'
import selectors from '../../redux/selectors'
import { withLang } from '../../utils/withLang'
import ReactTable from 'react-table'
import PageTitle from '../../components/PageTitle'
import actions from '../../redux/actions'
import { setDocumentTitle } from '../../utils/functions'
import { withRouter } from 'react-router-dom'

  const mapStateToProps = state => ({
})

class CampaignsHome extends Component {


  componentDidMount () {
    setDocumentTitle('Campaign send')
  }

  render () {
    let {  lang, isFetching } = this.props

    return (
      <div className="age-groups">

        <PageTitle
          title={lang.campaign_send}
          icon="bullhorn"
        />


      </div>
    )
  }
}

export default withRouter(
  withLang(connect(mapStateToProps)(CampaignsHome)))