import axios from 'axios'
import actionTypes from '../action-types'
import { toast } from 'react-toastify'
import { lang } from '../../utils/withLang'
import { ENDPOINTS } from '../../config/constants'

/***********************
 SETTINGS CRUD
 **********************/

/** READ */
export const readSettings = () => dispatch => (
  axios.get(ENDPOINTS.SETTINGS).then(response => {
    dispatch(readSettingsSucceeded(response.data))
  }).catch(error => {
    dispatch(readSettingsFailed(error))
  })
)

export const readSettingsSucceeded = settings => {
  return {
    type: actionTypes.settings.READ_SETTINGS_SUCCEEDED,
    payload: {
      settings,
    },
  }
}

export const readSettingsFailed = error => ({
  type: actionTypes.settings.READ_SETTINGS_FAILED,
  payload: {
    ...error,
  },
})
/** READ */

/** UPDATE */
export const updateSettings = (value) => (dispatch) => {

  if (typeof value === 'object' || Array.isArray(value)) {
    value = JSON.stringify(value)
  }

  return axios.post(ENDPOINTS.SETTINGS, value).then(response => {
    toast.success(lang.success)
    dispatch(updateSettingsSucceeded(response.data))
    dispatch(readSettings())
  }).catch(error => {
    toast.error(lang.success)
    dispatch(updateSettingsFailed(error))
  })
}

export const updateSettingsSucceeded = setting => ({
  type: actionTypes.settings.UPDATE_SETTINGS_SUCCEEDED,
  payload: {
    setting,
  },
})

export const updateSettingsFailed = error => ({
  type: actionTypes.settings.UPDATE_SETTINGS_FAILED,
  payload: {
    ...error,
  },
})
/** UPDATE */