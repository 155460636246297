import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withLang } from '../../../utils/withLang'
import StageForm from './StageForm'

const propTypes = {
  stages: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

class MeetingCycleStagesList extends Component {
  constructor (props) {
    super(props)

    this.handleStageRemove = this.handleStageRemove.bind(this)
  }

  handleStageRemove (stageIndex) {
    this.props.onChange(stageIndex, null)
  }

  render () {
    const { stages, onChange, lang } = this.props

    return (
      <div className="meeting-cycle-stages-list">
        {
          stages.map((stage, stageIndex) => (
            <StageForm
              stage={stage}
              stageIndex={stageIndex}
              key={stageIndex}
              onChange={onChange}
              onRemove={() => this.handleStageRemove(stageIndex)}
            />
          ))
        }

        {
          !stages.length &&
          <p className="text-muted text-center w-100">{lang.empty}</p>
        }
      </div>

    )
  }
}

MeetingCycleStagesList.propTypes = propTypes

export default withLang(MeetingCycleStagesList)