import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import FormInput from '../../../components/FormInput'

class RestaurantFormOther extends Component {
  render () {
    const { lang } = this.props

    return (
      <div>
        <h3>{lang.additional_details}</h3>

        <Row className="align-items-center">
          <Col md={4}>
            <FormInput
              model=".music"
              label={lang.music}
            />
          </Col>

          <Col md={4}>
            <FormInput
              model=".standing"
              label={lang.standing}
            />
          </Col>

          <Col md={4}>
            <FormInput
              model=".origin"
              label={lang.origin}
            />
          </Col>

          <Col md={4}>
            <FormInput
              type="date"
              model=".reg_date"
              label={lang.join_date}
            />
          </Col>

          <Col md={4}>
            <FormInput
              type="text"
              model=".management_system"
              label={lang.management_system}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default withLang(RestaurantFormOther)