import React, { Component } from 'react'
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import PropTypes from 'prop-types'
import LineAwesome from './LineAwesome'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

class OneFieldForm extends Component {
  state = {
    value: ''
  }

  handleKeyPress = e => {
    const { key } = e
    if (key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    const { onSubmit } = this.props
    const { value } = this.state

    if (value) {
      onSubmit(value)
      this.setState({ value: '' })
    }
  }

  render () {
    const { placeholder } = this.props
    const { value } = this.state

    return (
      <div className="mt-3">
        <InputGroup>
          <Input
            type="text"
            placeholder={placeholder}
            value={value}
            onKeyPress={this.handleKeyPress}
            onChange={({ target: { value } }) => this.setState({ value })}
          />
          <InputGroupAddon addonType="append">
            <Button onClick={this.handleSubmit} color="primary">
              <LineAwesome icon="plus"/>
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    )
  }
}

OneFieldForm.propTypes = propTypes

export default OneFieldForm