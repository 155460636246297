import moment from 'moment';
import { normalize } from 'normalizr';
import {
	APP_NAME,
	DATE_FORMAT,
	DATETIME_FORMAT,
	RESPONSE_DATA_FIELD,
	SCHEMAS,
} from '../config/constants';
import actions from '../redux/actions';

export const isAuthenticated = auth => (
	auth
	&& auth.access_token !== ''
	&& auth.expired > getUnixTimestamp()
);

export const getUnixTimestamp = () => Math.round((new Date()).getTime() / 1000);

export const formatDateTime = datetime => moment(datetime).
	format(DATETIME_FORMAT);
export const formatDate = date => moment(date).format(DATE_FORMAT);

/**
 * Struct API response
 * @param response
 * @param schemaName
 * @returns {{entities: any, count: *, ids: any}}
 */
export const disassembleResults = (response, schemaName) => {

	const normalizedData = normalize(response[RESPONSE_DATA_FIELD],
		SCHEMAS[schemaName]);

	return {
		entities: normalizedData.entities,
		ids: normalizedData.result,
		count: response.count,
	};
};

export const setDocumentTitle = (title = '') => {
	document.title = `${title ? title + ' — ' : ''}${APP_NAME}`;
};

export const setUrlParams = (url, params) => (
	url + '?' +
	Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')
);

export const getFullName = obj => {
	if (typeof obj === 'object' && obj.hasOwnProperty('first_name') &&
		obj.hasOwnProperty('last_name')) {
		return `${obj.first_name} ${obj.last_name}`;
	}
	return null;
};

export const getActionsForForm = form => actions[`${form}s`];