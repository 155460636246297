import axios from 'axios'
import actionTypes from '../action-types'
import {ENDPOINTS, ENTITY_CLUBS,} from '../../config/constants'
import {disassembleResults} from "../../utils/functions";
import {storeEntities} from "./resourceActions";

/***********************
 * REQUESTS
 */

export const requestRead = () => (
  axios.get(ENDPOINTS.CLUBS).then(
    response => response.data)
)


/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = () => dispatch => {
  dispatch(crudReadPending())

  return requestRead().then(response => {
    dispatch(crudReadSucceeded(response))
    return response
  })
}

export const crudReadPending = () => ({
  type: actionTypes.clubs.CRUD_READ_PENDING,
})


export const crudReadSucceeded = data => dispatch => {
  dispatch({
    type: actionTypes.clubs.CRUD_READ_SUCCEEDED,
    payload: data.results,
  })
}
/** READ */

