import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import FormInput from '../../../components/FormInput'

class RestaurantFormFood extends Component {
  render () {
    const { lang } = this.props

    return (
      <div>
        <h3>{lang.food}</h3>

        <Row>
          <Col md={2}>
            <FormInput
              model=".food_type"
              label={lang.food_type}
            />
          </Col>

          <Col md={3}>
            <FormInput
              type="cosher_type"
              model=".kosher"
              label={lang.kosher}
            />
          </Col>

          <Col md={3}>
            <FormInput
              type="checkbox"
              model=".table_is_builtin"
              id="table_is_builtin"
              label={lang.table_is_builtin}
            />
          </Col>

          <Col md={2}>
            <FormInput
              type="checkbox"
              id="table_shape"
              model=".table_shape"
              label={lang.round_table}
            />
          </Col>

          <Col md={2}>
            <FormInput
              model=".average_price"
              label={lang.average_price}
            />
          </Col>
        </Row>

        <FormInput
          type="wysiwyg"
          model=".menu_description"
          label={lang.menu_description}
        />
      </div>
    )
  }
}

export default withLang(RestaurantFormFood)