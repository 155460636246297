import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLang } from '../../utils/withLang';
import ParticipantForm from './components/ParticipantFormGeneral';
import ParticipantEvents from './components/ParticipantNotes';
import ParticipantContacts from './components/ParticipantContacts';
import ParticipantRestaurants from './components/ParticipantRestaurants';
import Tabs from '../../components/Tabs';
import ParticipantMessages from './components/ParticipantMessages';
import ParticipantActionEvents from './components/ParticipantActionEvents';

const mapStateToProps = state => ({
	participant: state.forms.participant,
});

class ParticipantsFormTabs extends Component {
	tabs = [
		{
			title: this.props.lang.general,
			label: 'data',
			component: ParticipantForm,
		},
		{
			title: this.props.lang.notes,
			label: 'notes',
			component: ParticipantEvents,
		},
		{
			title: this.props.lang.contacts,
			label: 'contacts',
			component: ParticipantContacts,
		},
		{
			title: this.props.lang.restaurants,
			label: 'restaurants',
			component: ParticipantRestaurants,
		},
		{
			title: this.props.lang.messages,
			label: 'messages',
			component: ParticipantMessages,
		},
		{
			title: this.props.lang.actions,
			label: 'actions',
			component: ParticipantActionEvents,
		},
	];

	state = {
		activeTab: this.tabs[0].label,
	};

	render () {
		const { participant } = this.props;
		const { activeTab } = this.state;

		const tabs = !participant.id ? [this.tabs[0], this.tabs[1]] : this.tabs;

		return (
			<Tabs
				tabs={tabs}
				activeTab={activeTab}
				onTabClick={tab => this.setState({ activeTab: tab })}
			/>
		);
	}
}

export default withRouter(
	withLang(connect(mapStateToProps)(ParticipantsFormTabs)));