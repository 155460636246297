import axios from 'axios'
import actionTypes from '../action-types'
import actions from './index'
import { toast } from 'react-toastify'
import { lang } from '../../utils/withLang'
import selectors from '../selectors'
import { generatePath } from 'react-router-dom'
import { ENDPOINTS, MODAL_AGE_GROUPS_MANAGE } from '../../config/constants'
import { customFormUpdate } from './coreActions'
import { is } from 'date-fns/locale'
import { club } from '../forms'

/***********************
 * REQUESTS
 */

export const requestRead = () => (
  axios.get(ENDPOINTS.AGE_GROUPS).then(
    response => response.data)
)

export const requestCreate = ageGroup => (
  axios.post(ENDPOINTS.AGE_GROUPS, { ...ageGroup }).then(
    response => response.data)
)

export const requestUpdate = ageGroup => (
  axios.patch(
    generatePath(ENDPOINTS.AGE_GROUP, { id: ageGroup.id }),
    { ...ageGroup },
  )
)

export const requestDelete = id => (
  axios.delete(generatePath(ENDPOINTS.AGE_GROUP, { id }))
)

/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = () => dispatch => {
  dispatch(crudReadPending())

  return requestRead().then(response => {
    dispatch(crudReadSucceeded(response))
    return response
  })
}

export const crudReadPending = () => ({
  type: actionTypes.ageGroups.CRUD_READ_PENDING,
})

export const crudReadSucceeded = data => dispatch => {
  dispatch({
    type: actionTypes.ageGroups.CRUD_READ_SUCCEEDED,
    payload: data.results,
  })
}
/** READ */

/** CREATE */
export const crudCreate = ageGroup => dispatch => (
  requestCreate(ageGroup).then(({ data: createdAgeGroup }) => {
    toast.success(lang.successfully_created)
    dispatch(crudCreateSucceeded(createdAgeGroup))
    dispatch(crudRead())
    return createdAgeGroup
  })
)

export const crudCreateSucceeded = result => ({
  type: actionTypes.ageGroups.CRUD_UPDATE_SUCCEEDED,
  payload: { ...result },
})
/** CREATE */

/** UPDATE */
export const crudUpdate = ageGroup => dispatch => (
  requestUpdate(ageGroup).then(response => {
    toast.success(lang.successfully_updated)
    dispatch(crudUpdateSucceeded(response.data))
    dispatch(crudRead())
    return response.data
  })
)

export const crudUpdateSucceeded = ageGroup => ({
  type: actionTypes.ageGroups.CRUD_UPDATE_SUCCEEDED,
  payload: { ...ageGroup },
})
/** UPDATE */

/** UPDATE DAILY GROUPS */

export const updateDailyGroup = groupId => (
  dispatch, getState) => {

  dispatch(crudReadPending())

  return requestUpdateDailyGroup(groupId).then(() => {
    toast.success(lang.successfully_updated)
    dispatch(crudRead())
  })

}
export const requestUpdateDailyGroup = groupId => (
  axios.put(
    generatePath(ENDPOINTS.AGE_GROUP_UPDATE_DAILY_GROUP,
        {id: groupId})
  )
)

/** SEND CAMPAIGN */

export const sendCampaignMen = (groupId, emailTemplateId, messageTypeId, clubId, isCampaignTravelAbroadInterested) => (
  dispatch, getState) => {
    if (emailTemplateId == null || isCampaignTravelAbroadInterested == null || (isCampaignTravelAbroadInterested == true && clubId != null)) {
      toast.error(lang.did_not_send)
    } else {
      if (messageTypeId && clubId) {
        dispatch(crudReadPending())
        return requestSendCampaignMenWithFollowupToClub(groupId, emailTemplateId, messageTypeId, clubId).then(() => {
          toast.success(lang.sent)
          dispatch(crudRead())
        })
      } else if (messageTypeId) {
        dispatch(crudReadPending())
        return requestSendCampaignMenWithFollowup(groupId, emailTemplateId, messageTypeId, isCampaignTravelAbroadInterested).then(() => {
          toast.success(lang.sent)
          dispatch(crudRead())
        })}
      else if (clubId) {
        dispatch(crudReadPending())
        return requestSendCampaignMenToClub(groupId, emailTemplateId, clubId).then(() => {
          toast.success(lang.sent)
          dispatch(crudRead())
        })}
      else {
        dispatch(crudReadPending())
        return requestSendCampaignMen(groupId, emailTemplateId, isCampaignTravelAbroadInterested).then(() => {
          toast.success(lang.sent)
          dispatch(crudRead())
        })
      }
    }
}
export const requestSendCampaignMen = (groupId, emailTemplateId, isCampaignTravelAbroadInterested) => (
    axios.put(
      generatePath(ENDPOINTS.AGE_GROUP_SEND_CAMPAIGN_MEN,
          {id: groupId, emailTemplate:emailTemplateId}),
          {
            isCampaignTravelAbroadInterested:isCampaignTravelAbroadInterested
          }
          ) 
)

export const requestSendCampaignMenWithFollowup = (groupId, emailTemplateId, messageTypeId, isCampaignTravelAbroadInterested) => (
axios.put(
  generatePath(ENDPOINTS.AGE_GROUP_SEND_CAMPAIGN_MESSAGE_MEN,
      {id: groupId, emailTemplate:emailTemplateId, messageTemplate:messageTypeId}),
      {
        isCampaignTravelAbroadInterested:isCampaignTravelAbroadInterested
      }
      )
)

export const requestSendCampaignMenToClub = (groupId, emailTemplateId, clubId) => (
  axios.put(
    generatePath(ENDPOINTS.AGE_GROUP_SEND_CAMPAIGN_MEN_CLUB,
        {id: groupId, emailTemplate:emailTemplateId, club:clubId})
  )
)
export const requestSendCampaignMenWithFollowupToClub = (groupId, emailTemplateId, messageTypeId, clubId) => (
  axios.put(
    generatePath(ENDPOINTS.AGE_GROUP_SEND_CAMPAIGN_MESSAGE_MEN_CLUB,
        {id: groupId, emailTemplate:emailTemplateId, messageTemplate:messageTypeId, club:clubId})
  )
)


export const sendCampaignWomen = (groupId, emailTemplateId, messageTypeId, clubId, isCampaignTravelAbroadInterested) => (
  dispatch, getState) => {

    if (emailTemplateId == null || isCampaignTravelAbroadInterested == null || (isCampaignTravelAbroadInterested == true && clubId != null)) {
        toast.error(lang.did_not_send)
    } else {
        if (messageTypeId && clubId) {
            dispatch(crudReadPending())
            return requestSendCampaignWomenWithFollowupToClub(groupId, emailTemplateId, messageTypeId, clubId).then(() => {
                toast.success(lang.sent)
                dispatch(crudRead())
            })
        } else if (messageTypeId) {
            dispatch(crudReadPending())
            return requestSendCampaignWomenWithFollowup(groupId, emailTemplateId, messageTypeId, isCampaignTravelAbroadInterested).then(() => {
                toast.success(lang.sent)
                dispatch(crudRead())
            })
        } else if (clubId) {
            dispatch(crudReadPending())
            return requestSendCampaignWomenToClub(groupId, emailTemplateId, clubId).then(() => {
                toast.success(lang.sent)
                dispatch(crudRead())
            })
        } else {
            dispatch(crudReadPending())
            return requestSendCampaignWomen(groupId, emailTemplateId, isCampaignTravelAbroadInterested).then(() => {
                toast.success(lang.sent)
                dispatch(crudRead())
            })
        }
    }
}



export const requestSendCampaignWomen = (groupId, emailTemplateId, isCampaignTravelAbroadInterested) => (
    axios.put(
      generatePath(ENDPOINTS.AGE_GROUP_SEND_CAMPAIGN_WOMEN,
          {id: groupId, emailTemplate:emailTemplateId}),
          {
            isCampaignTravelAbroadInterested:isCampaignTravelAbroadInterested
          }
          )
)

export const requestSendCampaignWomenWithFollowup = (groupId, emailTemplateId, messageTypeId, isCampaignTravelAbroadInterested) => (
    axios.put(
      generatePath(ENDPOINTS.AGE_GROUP_SEND_CAMPAIGN_MESSAGE_WOMEN, 
          {id: groupId, emailTemplate:emailTemplateId, messageTemplate:messageTypeId}),
          {
            isCampaignTravelAbroadInterested:isCampaignTravelAbroadInterested
          }
          )
)

export const requestSendCampaignWomenToClub = (groupId, emailTemplateId, clubId) => (
  axios.put(
    generatePath(ENDPOINTS.AGE_GROUP_SEND_CAMPAIGN_WOMEN_CLUB,
        {id: groupId, emailTemplate:emailTemplateId, club:clubId})
  )
)

export const requestSendCampaignWomenWithFollowupToClub = (groupId, emailTemplateId, messageTypeId, clubId) => (
  axios.put(
    generatePath(ENDPOINTS.AGE_GROUP_SEND_CAMPAIGN_MESSAGE_WOMEN_CLUB,
        {id: groupId, emailTemplate:emailTemplateId, messageTemplate:messageTypeId, club:clubId}),
  )
)

/** DELETE */
export const crudDelete = ageGroup => dispatch => (
  requestDelete(typeof ageGroup === 'object' ? ageGroup.id : ageGroup).then(() => {
    toast.success(lang.successfully_deleted)
    dispatch(crudDeleteSucceeded())
    dispatch(crudRead())
  })
)

export const crudDeleteSucceeded = () => ({
  type: actionTypes.ageGroups.CRUD_DELETE_SUCCEEDED,
})

export const crudDeleteFailed = () => ({
  type: actionTypes.ageGroups.CRUD_DELETE_FAILED,
})
/** DELETE */

/***********************
 MANAGE
 **********************/
export const openModalForm = (ageGroupId = null) => (dispatch, getState) => {
  if (ageGroupId) {
    const ageGroup = selectors.ageGroups.getById(getState(), ageGroupId)
    dispatch(customFormUpdate('forms.ageGroup', ageGroup, true))
  }

  dispatch(actions.core.openModalForm({
    title: lang[!ageGroupId ? 'new_age_group' : 'edit_age_group'],
    component: MODAL_AGE_GROUPS_MANAGE,
    form: 'ageGroup',
    isNew: !ageGroupId,
  }))
}