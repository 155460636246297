import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withLang } from '../../../utils/withLang'
import { PARTICIPATION_STATUSES } from '../../../config/constants'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const MeetingSelectParticipantType = (props) => {

  const {
    value,
    onChange,
    lang,
    ...otherProps
  } = props

  const participantTypeOptions = Object.keys(PARTICIPATION_STATUSES).map(
    type => ({
      label: lang[PARTICIPATION_STATUSES[type]],
      value: +type,
    }))

  return (
    <Select
      type="select"
      name="participant-type"
      id="select-participant-type"
      placeholder=""
      value={participantTypeOptions.filter(
        option => option.value === value)}
      options={participantTypeOptions}
      onChange={({ value }) => onChange(value)}
      {...otherProps}
    />
  )
}

MeetingSelectParticipantType.propTypes = propTypes

export default withLang(MeetingSelectParticipantType)