import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import LineAwesome from '../../../components/LineAwesome'
import { lang, withLang } from '../../../utils/withLang';
import { connect } from 'react-redux'
import { sendMessage, } from '../../../redux/actions/meetingsMessagesActions'

const propTypes = {
  id: PropTypes.number.isRequired,
  entity: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  meeting: state.forms.meeting,
  meetingsMessages: state.meetingsMessages,
})

class MeetingsMessageSendButton extends Component {
  handleSendClick = () => {
    const { meeting, id: messageId, dispatch, meetingsMessages } = this.props
    dispatch(sendMessage(meeting.id, messageId, meetingsMessages.page))
  }

  render () {
    const { lang, status } = this.props
    switch (status) {
      case 'new': {
        return (
          <Button color="primary"
                  size="sm"
                  className="text-light ml-3"
                  onClick={this.handleSendClick}>
            <LineAwesome icon="envelope"/> {lang.send}
          </Button>
        )
      }

      case 'sent': {
        return <span>{lang.sent}</span>
      }
      case 'שליחה': {
        return <span>{lang.sent}</span>
      }
      case 'queued': {
        return <span>{lang.queued}</span>
      }
      case 'failed': {
        return <span>{lang.failed}</span>
      }
      case 'delivered': {
        return <span>{lang.delivered}</span>
      }
      case 'undelivered': {
        return <span>{lang.undelivered}</span>
      }
      case 'sending': {
        return <span>{lang.sending}</span>
      }

      case 'dumped to slack': {
        return <span>{lang.dumped_to_slack}</span>;
      }

      case 'delayed': {
        return <span>{lang.delayed}</span>;
      }

      case 'read': {
        return <span>{lang.read}</span>;
      }

      default: {
        return <span>{lang.undefined_status}</span>
      }
    }
  }
}

MeetingsMessageSendButton.propTypes = propTypes

export default withLang(connect(mapStateToProps)(MeetingsMessageSendButton))