import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withLang } from '../../../utils/withLang'
import LineAwesome from '../../../components/LineAwesome'
import { TASK_STATUSES } from '../../../config/constants'
import { Checkbox } from '../../../components/Checkbox'

const propTypes = {
  state: PropTypes.array,
}

class MeetingStateTasksPreview extends Component {
  renderNoTasks () {
    return <p
      className="text-muted text-center h-25">{this.props.lang.no_tasks}</p>
  }

  renderTasksList(currentStage) {
    if (!currentStage || !currentStage.tasks)
      return this.renderNoTasks();

    return (
      <ul className="tasks-list">
        {
          (currentStage).map((task, i) => {
            return (
              <li
                key={i}
                className={`tasks-list-item tasks-list-item_${TASK_STATUSES[task.status]} row justify-content-start`}>
                <Checkbox
                  status={task.is_done}
                  id={i}
                />
                {task.name}
              </li>
            )
          })
        }
      </ul>
    )
  }

  render () {
    const { lang, tasksList } = this.props

    return (
      <div className="meeting-card-content__block meeting-card-tasks">
        <h3 className="meeting-card__sub-title">
          <LineAwesome icon="list-alt"/> {lang.state}
        </h3>

        {this.renderTasksList(tasksList)}
      </div>

    )
  }
}

MeetingStateTasksPreview.propTypes = propTypes

export default withLang(MeetingStateTasksPreview)