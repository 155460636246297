import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const defaultProps = {
  color: 'white'
}

const propTypes = {
  color: PropTypes.oneOf(['white', 'color'])
}

class Logo extends Component {
  render () {
    return (
      <Link to="/" className="logo">
        <img src={`/images/ananas_${this.props.color}.png`}
             alt="Happy Ananas"
             title="Happy Ananas"
        />
      </Link>
    )
  }
}

Logo.defaultProps = defaultProps
Logo.propTypes = propTypes

export default Logo