import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withLang } from '../../../utils/withLang'
import Select from 'react-select'
import { PARTICIPATION_STATUSES } from '../../../config/constants'
import { Label } from 'reactstrap'

const propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  status: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

class ParticipantStatus extends Component {
  render () {
    const { id, status, label, onChange, lang } = this.props
    const statusText = PARTICIPATION_STATUSES[status]

    const options = Object.keys(PARTICIPATION_STATUSES).map(status => ({
      value: +status,
      label: lang[PARTICIPATION_STATUSES[status]],
    }))

    return (
      <div className={`participant-status participant-status_${statusText}`}>
        {label && <Label>{label}</Label>}

        <Select
          size="sm"
          options={options}
          onChange={(option) => onChange(id, option.value)}
          value={options.filter(option => option.value === status)}
          placeholder=''
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    )
  }
}

ParticipantStatus.propTypes = propTypes

export default withLang(ParticipantStatus)