import React, { Component } from 'react'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'
import { actions as ReduxFormActions } from 'react-redux-form'
import MeetingStateTasksForm from './MeetingStateTasksForm'

const mapStateToProps = (state) => ({
  meeting: state.forms.meetingState,
  stages: state.settings,
})

class MeetingStateTasks extends Component {
  handleTaskCreate = title => {
    const { meeting: { tasks }, dispatch, stages } = this.props
    dispatch(
      ReduxFormActions.push(
        `forms.meetingState.tasks`,
        {
          name: title,
          order: tasks.length - 1,
          status: stages[stages.length - 1],
          is_done: false,
        },
      ),
    )
  }

  render () {
    return (
      <div className="tasks-editor">
        <MeetingStateTasksForm/>

        {/*Input for adding new task -> hide*/}
        {/*<OneFieldForm*/}
        {/*    onSubmit={this.handleTaskCreate}*/}
        {/*    placeholder={lang.add_new_task}*/}
        {/*/>*/}
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(MeetingStateTasks))
