import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import selectors from '../redux/selectors/index'
import { connect } from 'react-redux'

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  ageGroups: selectors.ageGroups.getAll(state),
})

class SelectAgeGroup extends Component {
  render () {
    const {
      ageGroups,
      value,
      onChange,
      ...otherProps
    } = this.props

    const ageGroupOptions = Object.keys(ageGroups).
      map(groupId => ({
        value: ageGroups[groupId].id,
        label: ageGroups[groupId].title,
      }))

    return (
      <Select
        type="select"
        value={ageGroupOptions.filter(option => option.value === +value)}
        options={ageGroupOptions}
        onChange={({ value }) => onChange(value)}
        {...otherProps}
      />
    )
  }
}

SelectAgeGroup.propTypes = propTypes

export default connect(mapStateToProps)(SelectAgeGroup)