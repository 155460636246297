import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import MeetingCycleStagesList from './MeetingCycleStagesList'
import selectors from '../../../redux/selectors'
import actions from '../../../redux/actions'
import LineAwesome from '../../../components/LineAwesome'
import { withLang } from '../../../utils/withLang'
import PageTitle from '../../../components/PageTitle'

const mapStateToProps = state => ({
  meetingCycle: selectors.settings.get(state),
})

class MeetingCycle extends Component {
  constructor (props) {
    super(props)

    this.state = {
      stages: [],
    }

    this.handleNewStageClick = this.handleNewStageClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    this.setState({ stages: this.props.meetingCycle })
  }

  handleNewStageClick () {
    const stages = this.state.stages.slice(0)

    stages.push({
      name: '',
      color: '',
      task_prototypes: [],
    })

    this.setState({ stages })
  }

  handleSaveClick () {
    const { dispatch } = this.props
    dispatch(actions.settings.updateSettings(this.state.stages))
  }

  handleChange (stageIndex, stage) {
    const stages = this.state.stages.slice(0)
    if (stage) {
      stages[stageIndex] = stage
    } else {
      stages.splice(stageIndex, 1)
    }
    this.setState({ stages })
  }

  render () {
    const { lang } = this.props
    const { stages } = this.state

    return (
      <div className="meeting-cycle">
        <PageTitle
          title={lang.meeting_cycle}
          icon="rotate-right"
        />

        <MeetingCycleStagesList
          stages={stages}
          onChange={this.handleChange}
        />

        <div className="text-center">
          <Button onClick={this.handleNewStageClick}
                  color="secondary"
                  size="sm">
            <LineAwesome icon="plus"/> {lang.stage} {lang.new}
          </Button>

          <Button onClick={() => this.setState({ stages: [] })}
                  color="link"
                  className="text-danger"
                  size="sm">
            <LineAwesome icon="trash"/> {lang.clear_all}
          </Button>
          <br/>
          <br/>

          <Button
            onClick={this.handleSaveClick}
            color="success">
            <LineAwesome icon="save"/> {lang.save}
          </Button>
        </div>
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(MeetingCycle))