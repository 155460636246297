import selectors from './index';
import { ENTITY_PARTICIPANT_MESSAGES } from '../../config/constants';

export const getIds = state => state.participantMessages.ids;
export const getAll = state => state.participantMessages.ids.map(
	id => selectors.collections.getEntity(state, ENTITY_PARTICIPANT_MESSAGES,
		id));
export const getById = (
	state, id) => state.resources[ENTITY_PARTICIPANT_MESSAGES]['' + id];
export const getPage = state => state.participantMessages.page;
export const getCount = state => state.participantMessages.count;
export const getIsFetching = state => state.participantMessages.isFetching;
export const getSearch = state => state.participantMessages.search;
