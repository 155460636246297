import React from 'react'
import CampaignsHome from './CampaignsHome'

const CampaignsModule = () => {
  return (
    <div id="module-campaigns-types">
      <CampaignsHome/>
    </div>
  )
}

export default CampaignsModule