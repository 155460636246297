import React, { Component } from 'react'

class DotsPreloader extends Component {
  render () {
    return (
      <div className="dots-preloader">
        <span/>
        <span/>
        <span/>
      </div>
    )
  }
}

export default DotsPreloader