import actionTypes from '../action-types'

// Requested resources (normalized)
const initialState = {
  meetings: {},
  participants: {}, //does not use?
  restaurants: {},
  cities: {},
  messagesTypes: {},
  messagesEvents: {},
}

export default function (state = initialState, action) {
  switch (action.type) {

    case actionTypes.resources.STORE_COLLECTIONS:
      return {
        ...state,
        ...action.payload,
      }

    case actionTypes.resources.ADD_ENTITY:
      return Object.assign({}, state, {
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          [action.payload.entity.id]: action.payload.entity,
        },
      })

    case actionTypes.resources.UPDATE_ENTITY:
      return Object.assign({}, state, {
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          [action.payload.entity.id]: {
            ...state[action.payload.entityType][action.payload.entity.id],
            ...action.payload.entity,
          },
        },
      })

    default:
      return state
  }
};