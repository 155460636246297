import { crudRead as restaurantsCrudRead } from './restaurantActions'
import { readSettings } from './settingActions'
import { crudRead as ageGroupsCrudRead } from './ageGroupActions'
import { crudRead as ageRangesCrudRead } from './ageRangesActions'
import { crudRead as citiesCrudRead } from './citiesActions'
import { crudRead as clubsCrudRead } from './clubsActions'
import { crudRead as messagesTypesCrudRead } from './messagesTypesActions'
import { crudRead as messagesEventsCrudRead } from './messagesEventsActions'
import { crudRead as emailTemplatesCrudRead } from './emailTemplatesActions'
import axios from 'axios'
import { toast } from 'react-toastify'
import { lang } from '../../utils/withLang'
import actionTypes from '../action-types'
import selectors from '../selectors'
import {
  ENDPOINTS,
  LSTORAGE_FIELDS,
  TOKEN_EXPIRATION_PERIOD,
} from '../../config/constants'
import actions from './index'
import { actions as ReduxFormActions } from 'react-redux-form'

export const initApp = () => (dispatch, getState) => {
  const fetches = []

  // Set a access_token to the fetch
  axios.defaults.headers.common['Authorization'] = 'Token ' +
    selectors.core.getAccessToken(getState())

  fetches.push(
    dispatch(restaurantsCrudRead()),
    dispatch(readSettings()),
    dispatch(ageGroupsCrudRead()),
    dispatch(ageRangesCrudRead()),
    dispatch(citiesCrudRead()),
    dispatch(clubsCrudRead()),
    dispatch(messagesTypesCrudRead()),
    dispatch(messagesEventsCrudRead()),
    dispatch(emailTemplatesCrudRead()),
  )

  return Promise.all(fetches).then(() => {
    dispatch(enableLayout())
    dispatch(initAppSucceeded())
  }).catch(() => console.log(`initApp failed`))
}

export const initAppSucceeded = () => ({
  type: actionTypes.core.INIT_APP_SUCCEEDED,
})

export const serverError = () => dispatch => {
  toast.error(lang.server_error)
  dispatch({ type: actionTypes.core.SERVER_ERROR })
}

export const badRequest = errors => dispatch => {
  Object.keys(errors).forEach(field => {
    [].concat(errors[field]).forEach(error => toast.warn(`${field}: ${error}`))
  })
  dispatch(updateModal({ isValid: false }))
}

export const serverUnavailable = () => ({
  type: actionTypes.core.SERVER_UNAVAILABLE,
})

/***********************
 FETCH TOKEN
 **********************/
export const createToken = (email, password, otp) => dispatch => {
  // Set a access_token to the fetch
  axios.defaults.headers.common['Authorization'] = 'Basic ' +
    btoa(`${email}:${password}`)

  return new Promise((resolve, reject) => {
    axios.post(ENDPOINTS.SIGN_IN, { 'otp': otp }).then(response => {  //here add new {"data": newField}
      dispatch(createTokenSucceeded(response.data))
      dispatch(initApp())
      toast.success(lang.welcome)
      resolve(response)
    }).catch(errors => {
      toast.error(lang.errors.check_entered_data)
      reject(errors)
    })
  })
}

export const createTokenSucceeded = response => {
  const expired = +(new Date().setHours(
    new Date().getHours() + TOKEN_EXPIRATION_PERIOD))

  const payload = {
    access_token: response.token,
    user: response.user,
    expired,
  }

  localStorage.setItem(LSTORAGE_FIELDS.FIELD_AUTH_TOKEN, payload.access_token)
  localStorage.setItem(LSTORAGE_FIELDS.FIELD_AUTH_TOKEN_EXPIRED, '' + expired)
  localStorage.setItem(LSTORAGE_FIELDS.FIELD_AUTH_USER,
    JSON.stringify(payload.user))

  return {
    type: actionTypes.core.CREATE_AUTH_SUCCEEDED,
    payload,
  }
}

export const signOut = () => dispatch => {
  axios.post(ENDPOINTS.SIGN_OUT).
    catch((e) => console.log(`Authorization problem: ${e.message}`))

  localStorage.removeItem(LSTORAGE_FIELDS.FIELD_AUTH_TOKEN)
  localStorage.removeItem(LSTORAGE_FIELDS.FIELD_AUTH_TOKEN_EXPIRED)
  localStorage.removeItem(LSTORAGE_FIELDS.FIELD_AUTH_USER)

  dispatch({
    type: actionTypes.core.DELETE_AUTH,
  })
}

export const enableLayout = () => dispatch => {
  dispatch({ type: actionTypes.core.LAYOUT_HEADER_ENABLED })
  dispatch({ type: actionTypes.core.LAYOUT_SIDEBAR_ENABLED })
}

export const disableLayout = () => dispatch => {
  dispatch({ type: actionTypes.core.LAYOUT_HEADER_DISABLED })
  dispatch({ type: actionTypes.core.LAYOUT_SIDEBAR_DISABLED })
}

/***********************
 MODAL
 **********************/
export const openModalForm = (options, submodal = false) => ({
  type: !submodal ?
    actionTypes.core.OPEN_MODAL :
    actionTypes.core.OPEN_SUBMODAL,
  payload: {
    ...options,
  },
})

export const updateModal = options => ({
  type: actionTypes.core.UPDATE_MODAL,
  payload: { ...options },
})

export const closeModal = (forced = false, submodal = false) => (
  dispatch, getState) => {
  const formName = selectors.core[!submodal ? 'getModal' : 'getSubModal'](
    getState()).form
  const isTouched = getState().forms.forms[formName].$form.touched

  if (!forced && isTouched && !window.confirm(lang.unsaved_form)) {
    return
  }

  if (formName) {
    dispatch(ReduxFormActions.reset(`forms.${formName}`))
  }

  dispatch({
    type: actionTypes.core[!submodal ? 'CLOSE_MODAL' : 'CLOSE_SUBMODAL'],
  })
}

export const modalActionSubmit = formName => dispatch => {
  const form = this.props[formName]
  const formActions = actions[`${formName}s`]['crudCreate']
  const formSubmitAction = formActions[!form.id ? 'crudCreate' : 'crudUpdate']

  dispatch(formSubmitAction(form)).then(() => {
    dispatch(formActions['manageClear']())
  })
}

export const customFormUpdate = (model, value, isSilent = false) =>
  dispatch => {
    if (!isSilent) {
      dispatch(ReduxFormActions.blur(model))
    }

    dispatch(ReduxFormActions.change(model, value))
  }