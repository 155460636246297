import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withLang } from '../utils/withLang'
import { GENDER_MAN, GENDER_WOMAN } from '../config/constants'

const propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

class SelectGender extends Component {
  render () {
    const {
      value,
      onChange,
      lang,
      ...otherProps
    } = this.props

    const genderOptions = [
      { value: +GENDER_MAN, label: lang.man },
      { value: +GENDER_WOMAN, label: lang.woman },
    ]

    return (
      <Select
        type="select"
        name="gender"
        id="select-gender"
        placeholder=""
        value={genderOptions.filter(option => option.value === value)}
        options={genderOptions}
        onChange={({ value }) => onChange(value)}
        {...otherProps}
      />
    )
  }
}

SelectGender.propTypes = propTypes

export default withLang(SelectGender)