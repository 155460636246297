import { disassembleResults, setUrlParams } from '../../utils/functions'
import { storeEntities } from './resourceActions'
import axios from 'axios'
import actionTypes from '../action-types'
import actions from './index'
import selectors from '../selectors'
import { toast } from 'react-toastify'
import { lang } from '../../utils/withLang'
import { generatePath } from 'react-router-dom'
import {
  DATE_FORMAT, DATEPICKER_DATE_FORMAT,
  ENDPOINTS,
  ENTITY_RESTAURANTS,
  MODAL_RESTAURANTS_MANAGE,
} from '../../config/constants'
import { customFormUpdate } from './coreActions'
import { clearEmptyContacts } from '../../modules/restaurants/functions'
import moment from 'moment'

/***********************
 * REQUESTS
 */

export const requestRead = options => (
  axios.get(setUrlParams(ENDPOINTS.RESTAURANTS, options)).then(
    response => response.data)
)

export const requestCreate = restaurant => (
  axios.post(ENDPOINTS.RESTAURANTS, { ...restaurant }).then(
    response => response.data)
)

export const requestUpdate = restaurant => (
  axios.patch(
    generatePath(ENDPOINTS.RESTAURANT, { id: restaurant.id }),
    { ...restaurant },
  )
)

export const requestDelete = id => (
  axios.delete(generatePath(ENDPOINTS.RESTAURANT, { id }))
)

/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = (options = {}) => (dispatch, getState) => {
  options.page = options.page || selectors.restaurants.getPage(getState())

  dispatch(crudReadPending())

  return requestRead(options).then(response => {
    dispatch(crudReadSucceeded(response))
    return response.data
  })
}

export const crudReadPending = () => ({
  type: actionTypes.restaurants.CRUD_READ_PENDING,
})

export const crudReadSucceeded = data => dispatch => {
  const results = disassembleResults(data, ENTITY_RESTAURANTS)
  dispatch(storeEntities(results.entities))
  dispatch({
    type: actionTypes.restaurants.CRUD_READ_SUCCEEDED,
    payload: results,
  })
}
/** READ */

/** CREATE */
export const crudCreate = restaurant => dispatch => {
  if (restaurant.kosher === '') {
    delete (restaurant.kosher)
  }
  if (restaurant.table_shape === '') {
    delete (restaurant.table_shape)
  }

  return requestCreate(clearEmptyContacts(restaurant)).then(
    ({ data: createdRestaurant }) => {
      toast.success(lang.successfully_created)
      dispatch(crudCreateSucceeded(createdRestaurant))
      dispatch(crudRead())
      return createdRestaurant
    })
}

export const crudCreateSucceeded = result => ({
  type: actionTypes.restaurants.CRUD_UPDATE_SUCCEEDED,
  payload: { ...result },
})
/** CREATE */

/** UPDATE */
export const crudUpdate = restaurant => dispatch => {
  if (restaurant.kosher === '') {
    delete (restaurant.kosher)
  }
  if (restaurant.table_shape === '') {
    delete (restaurant.table_shape)
  }
  restaurant.reg_date = moment(restaurant.reg_date).
    locale('he').
    format(DATE_FORMAT)

  return requestUpdate(clearEmptyContacts(restaurant)).then(response => {
    toast.success(lang.successfully_updated)
    dispatch(crudUpdateSucceeded(response.data))
    dispatch(crudRead())
    return response.data
  })
}

export const crudUpdateSucceeded = restaurant => ({
  type: actionTypes.restaurants.CRUD_UPDATE_SUCCEEDED,
  payload: { ...restaurant },
})
/** UPDATE */

/** DELETE */
export const crudDelete = restaurant => dispatch => (
  requestDelete(typeof restaurant === 'object' ? restaurant.id : restaurant).
    then(
      () => {
        toast.success(lang.successfully_deleted)
        dispatch(crudDeleteSucceeded())
        dispatch(crudRead())
      })
)

export const crudDeleteSucceeded = () => ({
  type: actionTypes.restaurants.CRUD_DELETE_SUCCEEDED,
})

export const crudDeleteFailed = () => ({
  type: actionTypes.restaurants.CRUD_DELETE_FAILED,
})
/** DELETE */

/***********************
 MANAGE
 **********************/
export const openModalForm = (restaurantId = null) => (dispatch, getState) => {
  if (restaurantId) {
    const restaurant = selectors.restaurants.getById(getState(), restaurantId)
    dispatch(customFormUpdate(`forms.restaurant`, restaurant, true))
  }

  dispatch(actions.core.openModalForm({
    title: lang[!restaurantId ? 'new_restaurant' : 'edit_restaurant'],
    component: MODAL_RESTAURANTS_MANAGE,
    form: 'restaurant',
    isNew: !restaurantId,
  }))
}
