import React from 'react'
import {
  MESSAGE_EVENTS_WHEN_SEND,
  MESSAGES_EVENTS_TYPES,
  PARTICIPATION_STATUSES,
  WEEKDAYS,
} from '../../../config/constants'
import { lang } from '../../../utils/withLang'
import selectors from '../../../redux/selectors'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return ({
    meetingStatuses: selectors.settings.getNamesIds(state),
  })
}

const MessagesEventsRestInformation = (props) => {

  const { original, meetingStatuses } = props

  const setCell = (original) => {
    if (original.event_type === MESSAGES_EVENTS_TYPES[1]) {

      // ParticipantStatusBasedSendingEvent
      const engName = PARTICIPATION_STATUSES[original.trigger_status]
      return `${lang.participation_status}: ${lang[engName]}`
    } else {

      // MeetingStatusBasedSendingEvent

      // collect offset_days
      let offset_days = original.offset_days || '-'

      // collect meeting Statuses Names
      const meetingStatusesNames = original.meeting_statuses.map((status) => {
        return meetingStatuses.find((obj) => {
          return +status === +obj.value
        }).label
      }).join(', ')

      // collect participation Statuses Names
      const participationStatuses = original.participation_statuses.split(',')
      const participationStatusesNames = participationStatuses.map((status) => {
        const engStatus = PARTICIPATION_STATUSES[status]
        return lang[engStatus]
      }).join(', ')

      // collect when send
      const whenSendEng = MESSAGE_EVENTS_WHEN_SEND[original.when]
      const whenSend = lang[whenSendEng]

      // collect weekday
      const weekdayNum = original.weekday
      const weekdayEng = WEEKDAYS[weekdayNum]
      const weekday = lang[weekdayEng] || '-'

      return (
        <div>
          <span>{lang.participation_status} : {participationStatusesNames}</span><br/>
          <span>{lang.meeting_status} : {meetingStatusesNames}</span><br/>
          <span>{lang.when_send} : {whenSend}</span><br/>
          <span>{lang.weekday} : {weekday}</span><br/>
          <span>{lang.days_before_after} : {offset_days}</span><br/>
        </div>
      )
    }
  }

  return (
    setCell(original)
  )
}

export default connect(mapStateToProps)(MessagesEventsRestInformation)
