import React, { Component } from 'react'
import AppRoutes from './AppRoutes'

class AppContent extends Component {
  render () {
    return (
      <main className="app-content">
        <AppRoutes/>
      </main>
    )
  }
}

export default AppContent