import React from 'react'
import {Button, Col, Form, FormGroup, Label, Row} from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'
import { Form as ReduxForm } from 'react-redux-form'
import RestaurantCard from '../../restaurants/components/RestaurantCard'
import validators from '../../../utils/validators'
import FormInput from '../../../components/FormInput'
import MeetingRegLink from './MeetingRegLink'
import LineAwesome from '../../../components/LineAwesome'
import {
    cancelMeeting, createWhatsappGroupForMeeting,
    duplicateMeeting,
    duplicateMeetingWeek,
    undraftMeeting
} from "../../../redux/actions/meetingActions";

const mapStateToProps = state => ({
  meeting: state.forms.meeting,
})

function MeetingFormGeneral (props) {
  const {
    meeting,
    lang,
    dispatch
  } = props

    const handleClickCancel = (meeting) => {
    dispatch(cancelMeeting(meeting))
  }

      const handleClickUndraft = (meeting) => {
    dispatch(undraftMeeting(meeting))
  }

        const handleClickDuplicateWeek = (meeting) => {
    dispatch(duplicateMeetingWeek(meeting))
  }
  const handleClickDuplicate = (meeting) => {
    dispatch(duplicateMeeting(meeting))
  }
  const handleClickCreateWhatsappGroup = (meeting) => {
    dispatch(createWhatsappGroupForMeeting(meeting))
  }

    return (
    <div className="meeting-data">
        <div className={`text-left d-flex justify-content-between`}>
            {meeting.is_to_be_notice &&
                <Button
                    color="warning"
                >
                    <LineAwesome icon="bell"/> {lang.is_to_be_notice}
                </Button>
            }
            {meeting.is_draft &&
                <Button
                    color="info"
                    onClick={() => handleClickUndraft(meeting)}

                >
                    <LineAwesome icon="music"/> {lang.make_not_draft}
                </Button>
            }

            {!meeting.is_draft &&
                <div>
                <Button
                    className={'ml-2'}
                    color="info"
                    onClick={() => handleClickDuplicate(meeting)}

                >
                    <LineAwesome icon="copy"/> {lang.duplicate}
                </Button>

                <Button
                    color="info"
                    onClick={() => handleClickDuplicateWeek(meeting)}

                >
                    <LineAwesome icon="retweet"/> {lang.duplicate_week}
                </Button>

                </div>
            }
            <Button
                color="danger"
                onClick={() => handleClickCancel(meeting)}
            >
                <LineAwesome icon="ban"/> {lang.cancel_meeting}
            </Button>

        </div>


        <Form
            tag={ReduxForm}
            model="forms.meeting">

            <Row className="justify-content-center">
                <Col md={3}>
                    <FormInput
                        type="age_groups"
              model=".age_group"
              label={lang.age_group}
              validators={{ required: validators.required }}
              errorMessages={{ required: lang.errors.required_field }}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="datetime"
              model=".date_time"
              label={lang.date}
              validators={{ required: validators.required }}
              errorMessages={{ required: lang.errors.required_field }}
            />
          </Col>
          <Col md={5}>

            <FormGroup>
              <Label for="meeting-data-time">
                {lang.seats}
              </Label>
              <Row>

                <Col md={6}>
                  <FormInput
                    type="text"
                    model=".men_seats"
                    prepend={<LineAwesome icon="male"/>}
                    placeholder={lang.men}
                    validators={{
                      required: validators.requiredOrZero,
                      number: validators.number,
                    }}
                    errorMessages={{
                      required: lang.errors.required_field,
                      number: lang.errors.non_int_value,
                    }}
                  />
                </Col>

                <Col md={6}>
                  <FormInput
                    type="text"
                    model=".women_seats"
                    prepend={<LineAwesome icon="female"/>}
                    placeholder={lang.women}
                    validators={{
                      required: validators.requiredOrZero,
                      number: validators.number,
                    }}
                    errorMessages={{
                      required: lang.errors.required_field,
                      number: lang.errors.non_int_value,
                    }}
                  />
                </Col>

              </Row>
            </FormGroup>

          </Col>

        </Row>
        <Row>
          <Col md={3}>
            <FormInput
              type="restaurant"
              model=".restaurant"
              label={lang.restaurant}
              classNamePrefix={'react-select'}
              validators={{ required: validators.required }}
              errorMessages={{ required: lang.errors.required_field }}
            />
          </Col>

          <Col md={3}>
            <FormInput
              type="meeting_type"
              model=".meeting_type"
              label={lang.meeting_type}
            />
          </Col>
          <Col md={3}>
            <FormInput
              type="meeting_schedule_type"
              model=".meeting_schedule_type"
              label={lang.meeting_schedule_type}
            />
          </Col>
            <Col md={3}>
            <FormInput
              type="meeting_day_part"
              model=".meeting_day_part"
              label={lang.meeting_day_part}
            />
          </Col>
        </Row>
         <Row>
           <Col md={4}>
            <FormInput
              type="text"
              model=".price"
              label={lang.price}
              validators={{ number: validators.number }}
              errorMessages={{ number: lang.errors.non_int_value }}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="boolean"
              model=".is_first_drink_free"
              label={lang.is_first_drink_free}
            />
          </Col>
          <Col md={4}>
            <FormInput
              type="boolean_readonly"
              model=".is_full"
              label={lang.is_full}
            />
          </Col>


        </Row>
        <Row>
          <Col md={4}>
              <FormInput
                type="text"
                model=".minimum_participants_each_gender"
                label={lang.minimum_participants_each_gender}
                validators={{
                  number: validators.number,
                }}
                errorMessages={{
                  number: lang.errors.non_int_value,
                }}
              />
          </Col>
          <Col md={4}>
              <FormInput
                  readOnly={true}
                  type="boolean_readonly"
                  model=".is_taking_place"
                  label={lang.is_taking_place}
              />
            </Col>

           <Col md={4}>
              <FormInput
                  type="boolean_readonly"
                  model=".is_archived"
                  label={lang.is_archived}
              />
            </Col>

        </Row>
        {meeting.is_planned && <Row>
          <Col md={12}>
            <FormInput
              type="text"
              model=".sys_cancel_reason"
              label={lang.sys_cancel_reason}
            />
          </Col>
        </Row>}
        <Row>
          <Col md={12}>
            <FormInput
              type="text"
              model=".whatsapp_group_url"
              label={lang.whatsapp_group_url}
              icon={"whatsapp"}
              helpText={meeting.id ? <Button
                    color="info"
                    onClick={() => handleClickCreateWhatsappGroup(meeting)}>
                    <LineAwesome icon="plus"/> {lang.create_whatsapp_group} <LineAwesome icon="whatsapp"/>
            </Button>:''}
            />
          </Col>

        </Row>


        {meeting.restaurant &&
        <RestaurantCard restaurantId={meeting.restaurant}/>}

        {
          meeting.id && <MeetingRegLink meetingId={meeting.id}/>
        }
      </Form>
    </div>
  )
}

export default withLang(connect(mapStateToProps)(MeetingFormGeneral))
