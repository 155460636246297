import actionTypes from '../action-types'
import { LSTORAGE_FIELDS } from '../../config/constants'

const initialState = {
  layout: {
    isHeader: false,
    isSidebar: false,
  },
  auth: {
    access_token: localStorage.getItem(LSTORAGE_FIELDS.FIELD_AUTH_TOKEN) ||
      null,
    expired: localStorage.getItem(LSTORAGE_FIELDS.FIELD_AUTH_TOKEN_EXPIRED) ||
      null,
    user: (
      localStorage.getItem(LSTORAGE_FIELDS.FIELD_AUTH_USER)
      && JSON.parse(localStorage.getItem(LSTORAGE_FIELDS.FIELD_AUTH_USER))
    ) || null,
  },
  isAppInitiated: false,
  isApiConnected: true,
  modal: {
    isOpen: false,
    component: null,
    title: null,
    size: 'lg',
    centered: true,
    className: null,
    form: null,
    isNew: true,
  },
  submodal: {
    isOpen: false,
    component: null,
    title: null,
    size: 'lg',
    centered: true,
    className: null,
    form: null,
    isNew: false,
  },
}

export default function core (state = initialState, action) {
  switch (action.type) {

    /* AUTH SECTION */

    case actionTypes.core.CREATE_AUTH_SUCCEEDED:
      return {
        ...state, auth: action.payload,
      }
    case actionTypes.core.DELETE_AUTH:
      return {
        ...state,
        auth: {
          token: null, expired: null, manager: null,
        },
      }

    /* LAYOUT SECTION */

    case actionTypes.core.LAYOUT_HEADER_ENABLED:
      return {
        ...state,
        layout: { ...state.layout, isHeader: true },
      }
    case actionTypes.core.LAYOUT_HEADER_DISABLED:
      return {
        ...state,
        layout: { ...state.layout, isHeader: false },
      }
    case actionTypes.core.LAYOUT_SIDEBAR_ENABLED:
      return {
        ...state,
        layout: { ...state.layout, isSidebar: true },
      }
    case actionTypes.core.LAYOUT_SIDEBAR_DISABLED:
      return {
        ...state,
        layout: { ...state.layout, isSidebar: false },
      }

    case actionTypes.core.INIT_APP_SUCCEEDED:
      return { ...state, isAppInitiated: true }

    case actionTypes.core.SERVER_ERROR:
      return {
        ...state,
        isApiConnected: false,
      }

    case actionTypes.core.SERVER_UNAVAILABLE:
      return {
        ...state,
        isApiConnected: false,
      }

    /**
     * MODAL
     */
    case actionTypes.core.OPEN_MODAL:
    case actionTypes.core.OPEN_SUBMODAL:
      return {
        ...state,
        [action.type === actionTypes.core.OPEN_MODAL ? 'modal' : 'submodal']: {
          ...state.modal,
          ...action.payload,
          isOpen: true,
        },
      }

    case actionTypes.core.UPDATE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
        },
      }

    case actionTypes.core.CLOSE_MODAL:
    case actionTypes.core.CLOSE_SUBMODAL:
      return {
        ...state,
        [action.type === actionTypes.core.CLOSE_MODAL ? 'modal' : 'submodal']: {
          ...initialState.modal,
        },
      }

    default:
      return state
  }
}