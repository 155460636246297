import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner'
import LineAwesome from './LineAwesome'

const propTypes = {
  spinner: PropTypes.bool,
  success: PropTypes.bool,
}

class SpinnerBox extends Component {
  render () {
    const { spinner, success } = this.props

    const isActive = !!spinner || !!success
    return (
      <div className={`spinner-box ${isActive ? 'active' : ''}`}>
        {spinner && <Spinner/>}
        {success && <LineAwesome icon="check-circle"/>}
      </div>
    )
  }
}

SpinnerBox.propTypes = propTypes

export default SpinnerBox