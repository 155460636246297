import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import meetings from './meetingsReducer'
import core from './coreReducer'
import participants from './participantsReducer'
import restaurants from './restaurantReducer'
import resources from './resourcesReducer'
import ageGroups from './ageGroupsReducer'
import ageRanges from './ageRangesReducer'
import cities from './citiesReducer'
import clubs from './clubsReducer'
import settings from './settingsReducer'
import messagesTypes from './messagesTypesReducer'
import meetingsMessages from './meetingsMessagesReducer'
import messagesEvents from './messagesEventsReducer'
import participantMessages from './participantMessagesReducer'
import emailTemplates from './emailTemplatesReducer'
import { combineForms } from 'react-redux-form'
import {
  ageRange,
  city,
  club,
  ageGroup,
  auth,
  manuallyMessage,
  manuallyMessageRestaurant,
  meeting,
  meetingState,
  messagesEvent,
  messagesType,
  participant,
  restaurant,
  emailTemplate,
} from '../forms'

export default history => combineReducers({
  router: connectRouter(history),
  meetings,
  meetingsMessages,
  participants,
  core,
  restaurants,
  resources,
  ageGroups,
  ageRanges,
  cities,
  clubs,
  messagesTypes,
  messagesEvents,
  settings,
  participantMessages,
  emailTemplates,
  forms: combineForms({
    meeting,
    auth,
    ageGroup,
    city,
    club,
    ageRange,
    participant,
    messagesType,
    messagesEvent,
    restaurant,
    manuallyMessage,
    manuallyMessageRestaurant,
    meetingState,
    emailTemplate,
  }, 'forms'),
});