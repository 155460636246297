import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form as ReduxForm } from 'react-redux-form'
import { Col, Form, Row } from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import FormInput from '../../../components/FormInput'
import validators from '../../../utils/validators'
import ParticipantLinkID from './ParticipantLinkID'
import LineAwesome from "../../../components/LineAwesome";

const mapStateToProps = state => ({
  participant: state.forms.participant,
})

class ParticipantFormGeneral extends Component {
  render () {
    const {
      participant,
      lang,
    } = this.props

    const colorPhoneVerified = participant.is_phone_verified ? '#2bc34e' : 'black'
    const colorEmailVerified = participant.is_email_verified ? '#2bc34e' : 'black'

    return (
      <div className="participant-form">
        <Form
          tag={ReduxForm}
          model="forms.participant">
          <Row>
            <Col md={4}>
              <FormInput
                type="text"
                model=".first_name"
                label={lang.first_name}
                validators={{ required: validators.required }}
                errorMessages={{ required: lang.errors.required_field }}
              />
            </Col>
            <Col md={4}>
              <FormInput
                type="text"
                model=".last_name"
                label={lang.last_name}
                errorMessages={{ required: lang.errors.required_field }}
              />
            </Col>
            <Col md={4}>
              <FormInput
                  type="date_readonly"
                  model=".created_at"
                  label={lang.joined_at}
              />
            </Col>

            <Col md={3}>
              <FormInput
                type="phone"
                model=".phone"
                label={lang.phone}
                color={colorPhoneVerified}
              />
            </Col>

            <Col md={3}>
              <FormInput
                type="email"
                model=".email"
                label={lang.email}
                color={colorEmailVerified}
                validators={{
                  required: validators.required,
                  email: validators.email,
                }}
                errorMessages={{
                  required: lang.errors.required_field,
                  email: lang.errors.check_email,
                }}
              />
            </Col>

            <Col md={3}>
              <FormInput
                type="city"
                model=".city"
                label={lang.city}
              />
            </Col>

            <Col md={3}>
              <FormInput
                type="gender"
                model=".gender"
                label={lang.gender}
              />
            </Col>

            <Col md={3}>
              <FormInput
                type="age_range"
                model=".age_range"
                label={lang.age_range}
              />
            </Col>

            <Col md={3}>
              <FormInput
                type="age_groups_readonly"
                model=".age_group_id"
                label={lang.age_groups}
              />
            </Col>
            <Col md={3}>
              <FormInput
                type="credits"
                model=".credits"
                label={lang.credits}
              />
            </Col>
            <Col md={3}>
            <FormInput
              type="link_id"
              model=".link_id"
              label={<ParticipantLinkID participant_link_id={participant.link_id}/>}
            />
            </Col>

            <Col md={3}>
              <FormInput
                  type="checkbox"
                  id="list13"
                  model=".list13"
                  label={lang.list13}
              />
            </Col>

            <Col md={3}>
              <FormInput
                  type="checkbox"
                  id="list14"
                  model=".list14"
                  label={lang.list14}
              />
            </Col>
            <Col md={3}>
              <FormInput
                  type="checkbox"
                  id="not_accepting_to_get_arrows"
                  model=".not_accepting_to_get_arrows"
                  label={lang.not_accepting_to_get_arrows}
              />
            </Col>
            <Col md={3}>
              <FormInput
                  type="checkbox"
                  id="unsubscribed_from_mobile"
                  model=".unsubscribed_from_mobile"
                  label={lang.unsubscribed_from_mobile}
              />
            </Col>

            <Col md={3}>
              <FormInput
                  type="club"
                  id="club"
                  model=".club"
                  label={lang.club}
              />
            </Col>

            <Col md={6}>
              <FormInput
                  type="textarea"
                  model=".note"
                  label={lang.note}
              />
            </Col>
            <Col md={4}>
              <FormInput
                type="date"
                model=".birthday"
                label={lang.birthday}
                helpText={lang.helps.field_date}
                errorMessages={{ required: lang.errors.required_field }}
              />
            </Col>



          </Row>

          {
            participant.interested_in_age_groups && (
              <div className="text-center text-muted mt-3">
                {lang.interested_in}: {participant.interested_in_age_groups}
              </div>
            )
          }
        </Form>
      </div>
    )
  }
}

export default withLang(connect(
  mapStateToProps,
)(ParticipantFormGeneral))
