import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatDateTime, getFullName } from '../../../utils/functions'
import {
  EVENT_TYPE_GENERAL,
  EVENT_TYPE_PARTICIPATION,
  PARTICIPATION_STATUSES,
  EVENT_TYPE_PAYMENT,
  PAYMENT_STATUSES,
  EVENT_TYPE_SMS_FILL_UP
} from '../../../config/constants'
import { withLang } from '../../../utils/withLang'
import { Table } from 'reactstrap'
import LineAwesome from '../../../components/LineAwesome'

const propTypes = {
  meeting: PropTypes.object.isRequired
}

class MeetingEventsList extends Component {
  getMessage (event) {
    const { meeting, lang } = this.props

    switch (event.type) {
      case EVENT_TYPE_PARTICIPATION:
        const participant = meeting.participants.find(p => p.participant_id === +event.participant_id)
        return (
          <span>
              <b>{getFullName(participant)}:</b> &nbsp;
            {lang.event_messages[`participant_${PARTICIPATION_STATUSES[event.status]}`]}
            </span>
        )
      case EVENT_TYPE_PAYMENT:
        const p_participant = meeting.participants.find(p => p.participant_id === +event.participant_id)
        return (
          <span>
              <b>{getFullName(p_participant)}:</b> &nbsp;
            {lang.event_messages[`payment_${PAYMENT_STATUSES[event.is_successful]}`]} ( {lang.charge_attempts} #{event.charge_attempts} )
            </span>
        )
      case EVENT_TYPE_GENERAL:
        return (
          <span>{event.message}</span>
        )
      case EVENT_TYPE_SMS_FILL_UP:
        return (
          <span>{event.message}</span>
        )
      default:
        return
    }
  }

  render () {
    const { meeting: { events }, lang } = this.props

    const isEventsEmpty = !events || !events.length

    return (
      <div className="meeting-events-list">
        <h3><LineAwesome icon="bell"/> {lang.events}</h3>
        {
          !isEventsEmpty && (
            <div className="meeting-events-list-table">
              <Table>
                <thead>
                <tr>
                  <th>{lang.message}</th>
                  <th>{lang.type}</th>
                  <th>{lang.by}</th>
                  <th>{lang.date}</th>
                </tr>
                </thead>
                <tbody>
                {
                  events.map(event => (
                    <tr key={event.date}>
                      <td>{this.getMessage(event)}</td>
                      <td>{lang[event.type]}</td>
                      <td>{getFullName(event.author) || '-'}</td>
                      <td className="text-muted">{formatDateTime(+event.date)}</td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </div>
          )
        }

        {
          isEventsEmpty && (
            <p className="meeting-events__empty">{lang.no_events}</p>
          )
        }
      </div>
    )
  }
}

MeetingEventsList.propTypes = propTypes

export default withLang(MeetingEventsList)