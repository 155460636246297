import React from 'react'
import MessagesTypesHome from './MessagesTypesHome'

const MessagesTypesModule = () => {
  return (
    <div id="module-messages-types">
      <MessagesTypesHome/>
    </div>
  )
}

export default MessagesTypesModule