import React, { Component } from 'react'
import { AppContent, AppFooter, AppHeader, AppSidebar, } from './components/layout'
import { isAuthenticated } from './utils/functions'
import { withRouter } from 'react-router-dom'
import { initApp } from './redux/actions/coreActions'
import { connect } from 'react-redux'
import SpinnerBox from './components/SpinnerBox'
import { ToastContainer } from 'react-toastify'
import selectors from './redux/selectors'
import { PATHS, TOAST_SETTINGS } from './config/constants'
import ApiUnavailable from './modules/common/ApiUnavailable'
import ModalWrapper from './components/ModalWrapper'

const mapStateToProps = state => ({
  isAuth: isAuthenticated(selectors.core.getAuth(state)),
  isAppInitiated: selectors.core.getIsAppInitiated(state),
  isApiConnected: selectors.core.getIsApiConnected(state),
})

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isFetching: true,
      isAppInitiating: false,
      isAuth: false,
    }
  }

  componentDidMount () {
    this.init()
  }

  componentDidUpdate () {
    this.init()
  }

  init () {
    if (this.checkAuth()) this.checkAppInit()
  }

  checkAuth () {
    const {
      isAuth,
      location: { pathname },
      history,
    } = this.props
    const { isFetching } = this.state

    if (!isAuth) {
      if (pathname !== PATHS.SIGNIN) {
        history.push(PATHS.SIGNIN)
      }
      if (isFetching) {
        this.setState({ isFetching: false })
      }
    }

    return isAuth
  }

  checkAppInit () {
    const { isAppInitiated, dispatch } = this.props
    const { isAppInitiating, isFetching } = this.state

    if (!isAppInitiated) {

      if (!isFetching) {
        this.setState({ isFetching: true })
      }

      if (!isAppInitiating) {
        dispatch(initApp())
        this.setState({ isAppInitiating: true })
      }

    } else if (isAppInitiated && isAppInitiating) {
      this.setState({ isFetching: false, isAppInitiating: false })
    }
  }

  render () {
    const { isApiConnected } = this.props
    const { isFetching } = this.state

    if (!isApiConnected) {
      return <ApiUnavailable/>
    }

    if (isFetching) {
      return (
        <SpinnerBox spinner={true}/>
      )
    }

    return (
      <div className="app">
        <AppHeader/>
        <AppSidebar/>
        <AppContent/>
        <AppFooter/>
        <ToastContainer {...TOAST_SETTINGS}/>
        <ModalWrapper/>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(App))