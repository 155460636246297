import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { MEETING_DAY_PARTS } from '../../../config/constants'
import { withLang } from '../../../utils/withLang'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const MeetingDayPartSelect = (props) => {

  const {
    value,
    onChange,
    lang,
    ...otherProps
  } = props

  const meeting_day_parts = Object.keys(MEETING_DAY_PARTS).map(
    part => ({
      label: lang[MEETING_DAY_PARTS[part]],
      value: +part,
    }))

  return (
    <Select
      type="select"
      name="meeting_day_part"
      id="select-meeting-day-part"
      placeholder=""
      value={meeting_day_parts.filter(
        option => option.value === value)}
      options={meeting_day_parts}
      onChange={({ value }) => onChange(value)}
      {...otherProps}
    />
  )
}

MeetingDayPartSelect.propTypes = propTypes

export default withLang(MeetingDayPartSelect)