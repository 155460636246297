import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { routerMiddleware } from 'connected-react-router'
import ReduxThunk from 'redux-thunk'
import { reduxBatch } from '@manaflair/redux-batch'
import createRootReducer from './reducers/rootReducer'
import history from '../config/history'

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(routerMiddleware(history), ReduxThunk)
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(routerMiddleware(history), ReduxThunk,
      createLogger({ collapsed: true }))
  }
}

const store = createStore(
  createRootReducer(history),
  composeWithDevTools(getMiddleware(), reduxBatch),
)

if (process.env.NODE_ENV !== 'production') {
  window.store = store
}

export default store
