import React from 'react'
import { connect } from 'react-redux'
import selectors from '../../../redux/selectors'
import { lang } from '../../../utils/withLang'

const mapStateToProps = (state, ownProps) => {
  return ({
    messagesEvent: selectors.messagesEvents.getById(state, ownProps.id),
    messageEventId: ownProps.id,
    isAutoSend : ownProps.is_auto
  })
}

// Component for react-table: accepts ID messageType  -> return Name of messageType
const MessagesEventsById = (props) => {

  const messagesEvent = props.messagesEvent
  const messagesEventId = props.messageEventId
  const isAutoSend = props.isAutoSend

  if (!messagesEvent) {
    if (!messagesEventId) {
      return lang.manually_sending
    }
    else if (isAutoSend) {
    return lang.schedule_sending
  }
    else {
      return lang.schedule_sending
    }
  }
  else if (isAutoSend) {
    return lang.schedule_sending
  }
  else {
    return lang.manually_sending
  }

  return (
    <span>
        {messagesEvent.name}
      </span>
  )
}

export default connect(mapStateToProps)(MessagesEventsById)
