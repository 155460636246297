import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'
import selectors from '../../../redux/selectors'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  messagesTypes: selectors.messagesTypes.getAll(state),
})

const MeetingSelectMessagesType = (props) => {

  let {
    messagesTypes,
    value,
    onChange,
    lang,
    ...otherProps
  } = props

  const messagesTypeOptions = Object.keys(messagesTypes).map(type => ({
    value: messagesTypes[type].id,
    label: messagesTypes[type].name,
  }))

  return (
    <Select
      type="select"
      name="message-type"
      id="select-message-type"
      placeholder=""
      value={messagesTypeOptions.filter(
        option => option.value === value)}
      options={messagesTypeOptions}
      onChange={({ value }) => onChange(value)}
      {...otherProps}
    />
  )
}

MeetingSelectMessagesType.propTypes = propTypes

export default withLang(connect(mapStateToProps)(MeetingSelectMessagesType))