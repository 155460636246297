import axios from 'axios'
import actionTypes from '../action-types'
import {ENDPOINTS, ENTITY_CITIES, ENTITY_RESTAURANTS} from '../../config/constants'
import {disassembleResults} from "../../utils/functions";
import {storeEntities} from "./resourceActions";

/***********************
 * REQUESTS
 */

export const requestRead = () => (
  axios.get(ENDPOINTS.CITIES).then(
    response => response.data)
)


/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = () => dispatch => {
  dispatch(crudReadPending())

  return requestRead().then(response => {
    dispatch(crudReadSucceeded(response))
    return response
  })
}

export const crudReadPending = () => ({
  type: actionTypes.cities.CRUD_READ_PENDING,
})


export const crudReadSucceeded = data => dispatch => {
  const results = disassembleResults(data, ENTITY_CITIES)
  dispatch(storeEntities(results.entities))
  dispatch({
    type: actionTypes.cities.CRUD_READ_SUCCEEDED,
    payload: results,
  })
}
/** READ */

