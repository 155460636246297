import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { MEETING_SCHEDULE_TYPES, WEEKDAYS } from '../../../config/constants'
import { withLang } from '../../../utils/withLang'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const MeetingScheduleTypeSelect = (props) => {

  const {
    value,
    onChange,
    lang,
    ...otherProps
  } = props

  const meeting_schedule_types = Object.keys(MEETING_SCHEDULE_TYPES).map(
    type => ({
      label: lang[MEETING_SCHEDULE_TYPES[type]],
      value: +type,
    }))

  return (
    <Select
      type="select"
      name="meeting_schedule_type"
      id="select-meeting-schedule-type"
      placeholder=""
      value={meeting_schedule_types.filter(
        option => option.value === value)}
      options={meeting_schedule_types}
      onChange={({ value }) => onChange(value)}
      {...otherProps}
    />
  )
}

MeetingScheduleTypeSelect.propTypes = propTypes

export default withLang(MeetingScheduleTypeSelect)