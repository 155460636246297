/**
 * Takes `meeting_participant_id` from participants array
 * and place it to `meeting.participants`
 * @param meeting
 * @param participants
 * @returns {*}
 */
export const combineMeetingParticipantsIds = (meeting, participants) => {
  meeting.participants = meeting.participants.map(mp => {
    const { meeting_participant_id } = participants.filter(
      p => p.participant_id === mp.participant_id)[0]
    return Object.assign({}, mp, { meeting_participant_id })
  })
  return meeting
}

export const mapPrototypeTasksToMeetingState = (meetingCycle) => {
  const tasks = []

  meetingCycle.forEach(stage => {
    tasks.push(
      ...stage.task_prototypes.map((prototype, index) => ({
          name: prototype.name,
          order: index,
          status: stage.id,
          is_done: false,
        }),
      ),
    )
  })

  return tasks
}
