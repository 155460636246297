import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LineAwesome from './LineAwesome'
import { Button } from 'reactstrap'

const propTypes = {
  onClick: PropTypes.func
}

class PageTitleButton extends Component {
  render () {
    return (
      <Button className="page-title-button btn-icon" {...this.props}>
        <LineAwesome icon="plus-square"/>
      </Button>
    )
  }
}

PageTitleButton.propTypes = propTypes

export default PageTitleButton