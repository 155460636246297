export default {

  email: value => !value ||
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value),

  required: value => !!value,

  requiredOrZero: (value) => {
    return !!value || value === 0
  },

  number: value => !value || !(value && isNaN(Number(value))),

  link: value => !value || new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i').test(value),

  smsLength: value => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = value
    const length = tmp.textContent.length || tmp.innerText.length || 0
    return length < 321
  },

}