import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Col, FormGroup, FormText, Input, Label} from 'reactstrap'
import { Control as ReduxFormControl, Errors } from 'react-redux-form'
import { DATEPICKER_DATE_FORMAT, DATEPICKER_DATETIME_FORMAT, TIME_FORMAT, } from '../config/constants'
import DatePicker from 'react-datepicker'
import SelectAgeRange from './SelectAgeRange'
import SelectAgeGroup from './SelectAgeGroup'
import SelectCity from './SelectCity'
import SelectEmailTemplate from './SelectEmailTemplate'
import SelectRestaurant from '../modules/restaurants/components/SelectRestaurant'
import InputMask from 'react-input-mask'
import SelectGender from './SelectGender'
import SelectCosher from './SelectCosher'
import BooleanSelect from './SelectBoolean'
import MeetingSelectParticipantType from '../modules/meetings/components/MeetingSelectParticipantType'
import MeetingSelectMessagesType from '../modules/meetings/components/MeetingSelectMessagesType'
import InputGroup from 'reactstrap/es/InputGroup'
import ReactQuillWrapper from './ReactQuillWrapper'
import MessagesEventsSelectDay from '../modules/messages-events/components/MessagesEventsSelectDay'
import MessagesEventsSelectMeetingStatus from '../modules/messages-events/components/MessagesEventsSelectMeetingStatus'
import MessagesEventsWhenSendSelect from '../modules/messages-events/components/MessagesEventsWhenSendSelect'
import MessagesEventsMultiSelectParticipantType
  from '../modules/messages-events/components/MessagesEventsMultiSelectParticipantType'
import MeetingMessagesMultiSelectParticipants
  from '../modules/meetings/components/MeetingMessagesMultiSelectParticipants'
import MeetingMessagesMultiSelectContacts
  from '../modules/meetings/components/MeetingMessagesMultiSelectContacts'
import MeetingTypeSelect from '../modules/meetings/components/MeetingTypeSelect'
import MeetingScheduleTypeSelect from '../modules/meetings/components/MeetingScheduleTypeSelect'
import LineAwesome from "./LineAwesome";
import {Checkbox} from "./Checkbox";
import MeetingDayPartSelect from "../modules/meetings/components/MeetingDayPartSelect";
import SelectClub from './SelectClub'

const defaultProps = {
  tag: 'text',
  type: 'text',
  model: null,
  color: 'black',
  showErrors: field => field.touched && !field.focus,
}

const propTypes = {
  model: PropTypes.string,
  prepend: PropTypes.any,
  name: PropTypes.string,
  type: PropTypes.string,
  validators: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  errorMessages: PropTypes.object,
  showErrors: PropTypes.func,
}



class FormInput extends Component {
  getInputTag () {
    const { model } = this.props

    if (!model) return null

    switch (this.props.type) {
      case 'textarea':
        return ReduxFormControl.textarea
      case 'text':
      case 'number':
      default:
        return ReduxFormControl.text
    }
  }

  render () {
    const {
      model,
      prepend,
      showErrors: show,
      label,
      icon,
      helpText,
      errorMessages: messages,
    } = this.props

    const errorProps = {
      model,
      className: 'invalid-feedback d-block',
      messages,
      show,
    }

    const Container = prepend ? InputGroup : FormGroup

    return (
      <Container>
        {label && <Label>{label}</Label>} {icon && <LineAwesome icon={icon}/>}
        {
          prepend && (
            <div className="input-group-prepend">
              <span className="input-group-text">{prepend}</span>
            </div>
          )
        }
        {this.renderInput()}
        {messages && <Errors {...errorProps} />}

        {
          helpText && (
            <FormText color="muted">
              {helpText}
            </FormText>
          )
        }
      </Container>
    )
  }

  renderInput () {
    const {
      model,
      type,
      name,
      value,
      color,
      onChange,
      placeholder,
      validators,
      ...passProps
    } = this.props

    const inputProps = {
      tag: this.getInputTag(),
      type,
      onChange,
      color,
      name,
      placeholder,
      validators,
    }

    if (model) {
      inputProps.model = model
    }

    if (value) {
      inputProps.value = value
    }

    switch (type) {
      case 'datetime':
      case 'date':
        return (
          <ReduxFormControl
            model={model}
            component={DatePicker}
            showTimeSelect={type === 'datetime'}
            showYearDropdown
            value={null}
            validators={validators}
            dateFormat={type === 'datetime' ?
              DATEPICKER_DATETIME_FORMAT :
              DATEPICKER_DATE_FORMAT}
            className="form-control"
            autoComplete="off"
            mapProps={{
              selected: (props) => props.modelValue ?
                new Date(props.modelValue) :
                null,
            }}
            {...passProps}

          />
        )
      case 'time':
        return (
          <ReduxFormControl
            model={model}
            component={DatePicker}
            showTimeSelect
            showTimeSelectOnly
            value={null}
            validators={validators}
            dateFormat={TIME_FORMAT}
            className="form-control"
            autoComplete="off"
            mapProps={{
              selected: (props) => props.modelValue ?
                new Date(props.modelValue) :
                null,
            }}
            {...passProps}

          />

        )

      case 'age_groups':
        return (
          <ReduxFormControl
            model={model}
            component={SelectAgeGroup}
            validators={validators}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'age_groups_readonly':
        return (
          <ReduxFormControl
            isDisabled={true}
            model={model}
            component={SelectAgeGroup}
            validators={validators}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'age_range':
        return (
          <ReduxFormControl
            model={model}
            component={SelectAgeRange}
            validators={validators}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'city':
        return (
          <ReduxFormControl
            model={model}
            component={SelectCity}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )
      
      case 'club':
        return (
          <ReduxFormControl
            model={model}
            component={SelectClub}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}
          />
        )

      case 'emailTemplate':
        return (
          <ReduxFormControl
            model={model}
            component={SelectEmailTemplate}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'restaurant':
        return (
          <ReduxFormControl
            model={model}
            component={SelectRestaurant}
            validators={validators}
            autoComplete="off"
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}
          />
        )

      case 'gender':
        return (
          <ReduxFormControl
            model={model}
            component={SelectGender}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'cosher_type':
        return (
          <ReduxFormControl
            model={model}
            component={SelectCosher}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )
      case 'participant_type':
        return (
          <ReduxFormControl
            model={model}
            component={MeetingSelectParticipantType}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'multi_participant_type':
        return (
          <ReduxFormControl
            model={model}
            component={MessagesEventsMultiSelectParticipantType}
            {...passProps}

          />
        )

      case 'weekdays':
        return (
          <ReduxFormControl
            model={model}
            component={MessagesEventsSelectDay}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'meeting_type':
        return (
          <ReduxFormControl
            model={model}
            component={MeetingTypeSelect}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

        case 'meeting_day_part':
        return (
          <ReduxFormControl
            model={model}
            component={MeetingDayPartSelect}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'meeting_schedule_type':
        return (
          <ReduxFormControl
            model={model}
            component={MeetingScheduleTypeSelect}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'when_send':
        return (
          <ReduxFormControl
            model={model}
            component={MessagesEventsWhenSendSelect}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}
          />
        )

      case 'message_type':
        return (
          <ReduxFormControl
            model={model}
            component={MeetingSelectMessagesType}
            validators={validators}
            mapProps={{
              selected: (props) => props.modelValue ?
                +props.modelValue :
                null,
            }}
            {...passProps}

          />
        )

      case 'meeting_status':
        return (
          <ReduxFormControl
            model={model}
            component={MessagesEventsSelectMeetingStatus}
            validators={validators}
            {...passProps}

          />
        )

      case 'meeting_participant':
        return (
          <ReduxFormControl
            model={model}
            component={MeetingMessagesMultiSelectParticipants}
            validators={validators}
            {...passProps}

          />
        )
      case 'restaurant_contacts':
        return (
          <ReduxFormControl
            model={model}
            component={MeetingMessagesMultiSelectContacts}
            validators={validators}
            {...passProps}

          />
        )

      case 'phone':
        return (
          <ReduxFormControl
            model={model}
            component={InputMask}
            mask="+\972999999999"
            validators={validators}
            style={{color: color}}
            className="form-control text-left"
            dir="ltr"
            mapProps={{
              value: props => props.modelValue,
            }}
          />
        )
      case 'email':
        return (
          <ReduxFormControl
            model={model}
            style={{color: color}}
            className="form-control text-left"
            dir="ltr"
            mapProps={{
              value: props => props.modelValue,
            }}
          />
        )
      case 'checkbox':
      case 'boolean':
        return (
          <ReduxFormControl
            model={model}
            component={BooleanSelect}
            validators={validators}
            mapProps={{
              value: (props) => props.modelValue ? 1 : 0,
            }}
            {...passProps}

          />
        )

      case 'boolean_readonly':
        return (
          <ReduxFormControl
            model={model}
            component={Checkbox}
            validators={validators}
            mapProps={{
              status: (props) => props.modelValue ? 1 : 0,
            }}
            {...passProps}

          />
        )
      case 'date_readonly':
        return (
          <ReduxFormControl
            model={model}
            component={DatePicker}
            showTimeSelect={type === 'datetime'}
            showYearDropdown
            value={null}
            validators={validators}
            dateFormat={type === 'datetime' ?
              DATEPICKER_DATETIME_FORMAT :
              DATEPICKER_DATE_FORMAT}
            className="form-control"
            autoComplete="off"
            readOnly={true}
            mapProps={{
              selected: (props) => props.modelValue ?
                new Date(props.modelValue) :
                null,
            }}
            {...passProps}

          />
        )
      case 'wysiwyg':
        return (
          <div className="_quill">
            <ReduxFormControl
              model={model}
              formats={this.props.formats}
              component={ReactQuillWrapper}
              validators={validators}
              mapProps={{
                value: (props) => props.modelValue ? props.modelValue : '',
              }}
              {...passProps}

            />
          </div>
        )

      default:
        inputProps.mapprops = {
          value: props => {
            return props.modelValue ? props.modelValue : ''
          },
        }
        return <Input {...inputProps} />
    }
  }
}

FormInput.propTypes = propTypes
FormInput.defaultProps = defaultProps

export default FormInput
