import { storeEntities, updateEntity } from './resourceActions'
import { disassembleResults, setUrlParams } from '../../utils/functions'
import actionTypes from '../action-types'
import axios from 'axios'
import { toast } from 'react-toastify'
import { lang } from '../../utils/withLang'
import selectors from '../selectors'
import { mapPrototypeTasksToMeetingState, } from '../../modules/meetings/functions'
import { generatePath } from 'react-router-dom'
import {
  ENDPOINTS,
  ENTITY_MEETINGS,
  EVENT_TYPE_GENERAL,
  EVENT_TYPE_PARTICIPATION,
  MODAL_MEETING_MANAGE,
} from '../../config/constants'
import { transformToMeetingParticipant } from '../../modules/participants/functions'
import actions from './index'
import { customFormUpdate } from './coreActions'

/***********************
 * REQUESTS
 ***********************/

export const requestRead = options => (
  axios.get(setUrlParams(ENDPOINTS.MEETINGS, options))
)

export const requestCreate = meeting => (
  axios.post(ENDPOINTS.MEETINGS, { ...meeting })
)

export const requestUpdate = meeting => (
  axios.patch(
    generatePath(ENDPOINTS.MEETING, { id: meeting.id }),
    { ...meeting },
  )
)

export const requestDelete = meeting => (
  axios.delete(generatePath(ENDPOINTS.MEETING, { id: meeting.id }))
)

export const requestUpdateParticipant = (id, body) => (
  axios.patch(
    generatePath(ENDPOINTS.MEETING_PARTICIPANT, { id }),
    body,
  )
)

export const requestCreateParticipant = (body) => (
  axios.post(
    generatePath(ENDPOINTS.MEETING_PARTICIPANT_CREATE),
    body,
  )
)

export const requestUpdateMeetingsTasks = (id, body) => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_TASKS, { id }),
    body,
  )
)

/** CREATE SMS GROUPS */

export const createSMSFillUpGroup = meetingId => (
  dispatch, getState) => {

  dispatch(crudReadPending())

  return requestCreateSMSFillupGroup(meetingId).then(() => {
    toast.success(lang.successfully_updated)
    dispatch(crudRead())
  })

}
export const requestCreateSMSFillupGroup = meetingId => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_CREATE_SMS_FILL_UP_GROUP,
        {id: meetingId})
  )
)

/** SMS FILL UP */

export const sendSMSFillUpMen = (meetingId, arrowID) => (
  dispatch, getState) => {

  dispatch(crudReadPending())

  return requestSendSMSFillUpMen(meetingId, arrowID).then(() => {
    toast.success(lang.successfully_updated)
    dispatch(crudRead())
  })

}
export const requestSendSMSFillUpMen = (meetingId, arrowID) => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_SEND_SMS_FILL_UP_MEN,
        {id: meetingId, arrow:arrowID})
  )
)

export const sendSMSFillUpWomen = (meetingId,  arrowID) => (
  dispatch, getState) => {

  dispatch(crudReadPending())

  return requestSendSMSFillUpWomen(meetingId, arrowID).then(() => {
    toast.success(lang.successfully_updated)
    dispatch(crudRead())
  })

}
export const requestSendSMSFillUpWomen = (meetingId,  arrowID) => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_SEND_SMS_FILL_UP_WOMEN,
        {id: meetingId, arrow:arrowID})
  )
)

export const cancelMeeting = (meeting) => (
  dispatch, getState) => {

  const formName = getState().core.modal.form

  if (meeting.is_planned && !meeting.sys_cancel_reason) {
    window.alert(lang.enter_cancellation_reason)
  // for validate the cancelation reason is up to date
  } else if (getState().forms.forms[formName].$form.touched) {
    window.alert(lang.cant_cancel_touched_meeting)
  } else {
    if (window.confirm(lang.are_you_sure)) {

      return requestCancelMeeting(meeting.id).then(() => {
        // closing the modal for avoiding form with unupdated data such as participant meeting status.
        // Otherwise, if the data in the form won't be updated, pressing on save button will save wrong participation status to the DB. 
        dispatch(actions.core.closeModal(true, false))
        
        toast.success(lang.successfully_canceled)
        dispatch(crudRead())
      })

    }
  }
}
export const requestCancelMeeting = (meetingId) => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_CANCEL, {id: meetingId})
  )
)
export const undraftMeeting = (meeting) => (
  dispatch, getState) => {

  const formName = getState().core.modal.form

   if (getState().forms.forms[formName].$form.touched) {
     // TODO - not working
    window.alert(lang.cant_perform_touched_meeting)
  } else {
    if (window.confirm(lang.are_you_sure)) {

      return requestUndraftMeeting(meeting.id).then(() => {
        // closing the modal for avoiding form with unupdated data such as participant meeting status.
        // Otherwise, if the data in the form won't be updated, pressing on save button will save wrong participation status to the DB.
        dispatch(actions.core.closeModal(true, false))

        toast.success(lang.successfully_performed)
        dispatch(crudRead())
      })

    }
  }
}
export const requestUndraftMeeting = (meetingId) => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_UNDRAFT, {id: meetingId})
  )
)

export const createWhatsappGroupForMeeting = (meeting) => (
  dispatch, getState) => {

  const formName = getState().core.modal.form

   if (getState().forms.forms[formName].$form.touched) {
     // TODO - not working
    window.alert(lang.cant_perform_touched_meeting)
  } else {
    if (window.confirm(lang.are_you_sure)) {

      return requestCreateWhatsappGroupForMeeting(meeting.id).then(() => {
        // closing the modal for avoiding form with unupdated data such as participant meeting status.
        // Otherwise, if the data in the form won't be updated, pressing on save button will save wrong participation status to the DB.
        dispatch(actions.core.closeModal(true, false))

        toast.success(lang.successfully_performed)
        dispatch(crudRead())
      }).catch((error) => {
      // Log the error for debugging purposes
      console.error('Failed to create WhatsApp group:', error);

      // Show an error toast notification to the user
      toast.error(lang.operation_failed);
    });

    }
  }
}
export const requestCreateWhatsappGroupForMeeting = (meetingId) => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_CREATE_WHATSAPP_GROUPS, {id: meetingId})
  )
)

export const duplicateMeeting = (meeting) => (
  dispatch, getState) => {

  const formName = getState().core.modal.form

   if (getState().forms.forms[formName].$form.touched) {
    window.alert(lang.cant_perform_touched_meeting)
  } else {
    if (window.confirm(lang.are_you_sure)) {

      return requestDuplicateMeeting(meeting.id).then(() => {
        // closing the modal for avoiding form with unupdated data such as participant meeting status.
        // Otherwise, if the data in the form won't be updated, pressing on save button will save wrong participation status to the DB.
        dispatch(actions.core.closeModal(true, false))

        toast.success(lang.successfully_performed)
        dispatch(crudRead())
      })

    }
  }
}
export const requestDuplicateMeeting = (meetingId) => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_DUPLICATE, {id: meetingId})
  )
)


export const duplicateCurrentWeek = () => (dispatch, getState) => {
  return requestDuplicateCurrentWeek().then(() => {
    toast.success(lang.successfully_performed)
    dispatch(crudRead())
  })
}
export const requestDuplicateCurrentWeek = () => (
  axios.put(
    generatePath(ENDPOINTS.DUPLICATE_CURRENT_WEEK)
  )
)

export const duplicateLastMeetingWeek = () => (dispatch, getState) => {
  return requestDuplicateLastMeetingWeek().then(() => {
    toast.success(lang.successfully_performed)
    dispatch(crudRead())
  })
}
export const requestDuplicateLastMeetingWeek = () => (
  axios.put(
    generatePath(ENDPOINTS.DUPLICATE_LAST_MEETING_WEEK)
  )
)


export const duplicateMeetingWeek = (meeting) => (
  dispatch, getState) => {

  const formName = getState().core.modal.form

   if (getState().forms.forms[formName].$form.touched) {
    window.alert(lang.cant_perform_touched_meeting)
  } else {
    if (window.confirm(lang.are_you_sure)) {

      return requestDuplicateMeetingWeek(meeting.id).then(() => {
        // closing the modal for avoiding form with unupdated data such as participant meeting status.
        // Otherwise, if the data in the form won't be updated, pressing on save button will save wrong participation status to the DB.
        dispatch(actions.core.closeModal(true, false))

        toast.success(lang.successfully_performed)
        dispatch(crudRead())
      })

    }
  }
}

export const requestDuplicateMeetingWeek = (meetingId) => (
  axios.put(
    generatePath(ENDPOINTS.MEETING_DUPLICATE_WEEK, {id: meetingId})
  )
)
/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = (options = {}) => (dispatch, getState) => {
  options.page = options.page || selectors.meetings.getPage(getState())

  dispatch(crudReadPending())

  return requestRead(options).then(response => {
    dispatch(crudReadSucceeded(response.data))
    return response.data
  })
}

export const crudReadPending = () => ({
  type: actionTypes.meetings.CRUD_READ_PENDING,
})

export const crudReadSucceeded = data => dispatch => {
  const results = disassembleResults(data, ENTITY_MEETINGS)
  dispatch(storeEntities(results.entities))
  dispatch({
    type: actionTypes.meetings.CRUD_READ_SUCCEEDED,
    payload: results,
  })
}
/** READ */

/** READ {MESSAGES} */

export const crudReadMessagesSucceeded = (meetingId, messages) =>
  dispatch => {
    dispatch(
      updateEntity(ENTITY_MEETINGS, { id: meetingId, messages }))
  }
/** READ {MESSAGES} */

/** CREATE */
export const crudCreate = meeting => dispatch => (
  requestCreate(meeting).then(({ data: createdMeeting }) => {
    dispatch(crudUpdateRelatedData(Object.assign(createdMeeting, meeting))).then(() => {
      toast.success(lang.successfully_created)
      dispatch(crudCreateSucceeded(createdMeeting))
      dispatch(crudRead())

      dispatch(actions.meetings.openModalForm(meeting.id))
    })
    return createdMeeting
  })
)

export const crudCreateSucceeded = result => ({
  type: actionTypes.meetings.CRUD_UPDATE_SUCCEEDED,
  payload: { ...result },
})
/** CREATE */

/** UPDATE */
export const crudUpdate = meeting => (dispatch, getState) => {
  meeting.tasks = getState().forms.meetingState.tasks
  const reqUpdate = requestUpdate(meeting)
  const reqUpdateRelatedData = dispatch(crudUpdateRelatedData(meeting))

  meeting.men_seats = 5
  meeting.women_seats = 4

  Promise.all([reqUpdate, reqUpdateRelatedData]).then(([response]) => {
    toast.success(lang.successfully_updated)
    dispatch(crudUpdateSucceeded(response.data))
    dispatch(crudRead())
    return response.data
  })

  return reqUpdate
}

export const crudUpdateSucceeded = meeting => ({
  type: actionTypes.meetings.CRUD_UPDATE_SUCCEEDED,
  payload: { ...meeting },
})
/** UPDATE */

/** DELETE */
export const crudDelete = meeting => dispatch => (
  requestDelete(meeting).then(() => {
    toast.success(lang.successfully_deleted)
    dispatch(crudDeleteSucceeded())
    dispatch(crudRead())
  })
)

export const crudDeleteSucceeded = () => ({
  type: actionTypes.meetings.CRUD_DELETE_SUCCEEDED,
})

export const crudDeleteFailed = () => ({
  type: actionTypes.meetings.CRUD_DELETE_FAILED,
})
/** DELETE */

/** UPDATE {RELATED_DATA} */
export const crudUpdateRelatedData = meeting => (dispatch, getState) => {

  const createdParticipants = []
  const updatedParticipants = []

  // get Participants from Store and from Form
  const meetingFromStore = selectors.meetings.getById(getState(), meeting.id)
  const meetingStoreParticipants = meetingFromStore ?
    meetingFromStore.participants :
    []
  const meetingFormParticipants = meeting.participants

  meetingFormParticipants.forEach(mfp => {
    //fill updatedParticipants
    meetingStoreParticipants.forEach((msp) => {
      if (mfp.participant_id === msp.participant_id) {
        if (mfp.status !== msp.status || mfp.is_interviewed !==
          msp.is_interviewed) {
          updatedParticipants.push(mfp)
        }
      }
    })

    //fill createdParticipants
    const createdParticipant = meetingStoreParticipants.find((msp) => {
      return mfp.participant_id === msp.participant_id
    })
    if (!createdParticipant) {
      createdParticipants.push(mfp)
    }
  })

  const createParticipants = dispatch(
    crudAddParticipants(createdParticipants, meeting.id))
  const updateParticipants = dispatch(
    crudUpdateParticipants(updatedParticipants, meeting.id))

  // Meeting tasks
  const tasks = getState().forms.meetingState.tasks
  const updateTasks = dispatch(crudUpdateMeetingsTasks(meeting.id, tasks))

  return Promise.all([createParticipants, updateParticipants, updateTasks])
}

/** ADD {PARTICIPANTS} */
export const crudAddParticipants = (createdParticipants, meeting_id) => () => {
  const requests = []

  createdParticipants.forEach(participant => {
    const { status, is_interviewed, participant_id } = participant
    requests.push(
      requestCreateParticipant(
        {
          status,
          is_interviewed,
          participant: participant_id,
          meeting: meeting_id,
        }),
    )
  })

  return Promise.all(requests)
}

/** UPDATE {PARTICIPANT} */
export const crudUpdateParticipants = (
  updatedParticipants, meeting_id) => () => {
  const requests = []

  updatedParticipants.forEach(participant => {
    const { meeting_participant_id, status, is_interviewed, participant_id } = participant
    requests.push(
      requestUpdateParticipant(meeting_participant_id,
        {
          status,
          is_interviewed,
          participant: participant_id,
          meeting: meeting_id,
        }),
    )
  })

  return Promise.all(requests)
}

export const crudUpdateParticipantsSucceeded = meeting => dispatch => {
  dispatch(updateEntity(ENTITY_MEETINGS, meeting))
}

/** UPDATE {MEETINGS_TASKS} */
export const crudUpdateMeetingsTasks = (meetingId, tasks) => () => {
  return requestUpdateMeetingsTasks(meetingId,
    tasks)
}

/***********************
 * EDIT
 */
export const addParticipationEvent = (participant, status) => (
  dispatch, getState) => {
  const date = +new Date()

  const event = {
    date,
    type: EVENT_TYPE_PARTICIPATION,
    participant_id: participant.id,
    status,
  }

  let participants = getState().forms.meeting.participants

  let isExists = false
  let isUpdated = true

  participants = participants.map(mp => {
    if (participant.id === mp.participant_id) {
      const output = { ...mp }
      isExists = true
      if (output.status === status) {
        isUpdated = false
      } else {
        output.status = status
      }
      return output
    }
    return mp
  })

  if (isExists && !isUpdated) {
    toast.warn(lang.event_exists)
    return null
  }

  if (!isExists) {
    participants.push(transformToMeetingParticipant(participant, status))
  }

  dispatch(
    customFormUpdate('forms.meeting.participants', participants),
  )

  dispatch(unshiftEvent(event))
}

export const addCustomEvent = message => (dispatch) => {
  const date = +new Date()

  const event = {
    date,
    message,
    type: EVENT_TYPE_GENERAL,
  }

  dispatch(unshiftEvent(event))
}

export const unshiftEvent = event => (dispatch, getState) => {
  event.author = getState().core.auth.user

  const events = (getState().forms.meeting.events &&
    getState().forms.meeting.events.slice(0)) || []
  events.unshift(event)

  dispatch(customFormUpdate('forms.meeting.events', events))
}

/***********************
 MANAGE
 **********************/
export const openModalForm = (meetingId = null) => (dispatch, getState) => {
  const meetingCycle = selectors.settings.get(getState())
  if (meetingId) {
    const meeting = selectors.meetings.getById(getState(), meetingId)
    dispatch(customFormUpdate(`forms.meeting`, meeting, true))
    dispatch(customFormUpdate('forms.meetingState.tasks', meeting.tasks, true))
  } else {
    const mappedMeetingCycle = mapPrototypeTasksToMeetingState(meetingCycle)

    dispatch(
      customFormUpdate('forms.meetingState.tasks', mappedMeetingCycle, true))
  }

  dispatch(actions.core.openModalForm({
    title: lang[!meetingId ? 'new_meeting' : 'edit_meeting'],
    component: MODAL_MEETING_MANAGE,
    form: 'meeting',
    isNew: !meetingId,
  }))
}
