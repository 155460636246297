import axios from 'axios'
import actionTypes from '../action-types'
import { ENDPOINTS } from '../../config/constants'

/***********************
 * REQUESTS
 */

export const requestRead = () => (
  axios.get(ENDPOINTS.AGE_RNAGES).then(
    response => response.data)
)


/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = () => dispatch => {
  dispatch(crudReadPending())

  return requestRead().then(response => {
    dispatch(crudReadSucceeded(response))
    return response
  })
}

export const crudReadPending = () => ({
  type: actionTypes.ageRanges.CRUD_READ_PENDING,
})

export const crudReadSucceeded = data => dispatch => {
  dispatch({
    type: actionTypes.ageRanges.CRUD_READ_SUCCEEDED,
    payload: data.results,
  })
}
/** READ */

