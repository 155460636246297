import actionTypes from '../action-types'
import { ITEMS_PER_PAGE } from '../../config/constants'

const initialState = {
  ids: [],
  count: null,
  perPage: ITEMS_PER_PAGE,
  page: 1,
  isFetching: false,
  search: '',
}

export default function participants (state = initialState, action) {
  switch (action.type) {
    case actionTypes.participants.CRUD_READ_PENDING:
      return {
        ...state,
        isFetching: true,
        ids: [],
      }

    case actionTypes.participants.CRUD_READ_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      }

    case actionTypes.participants.CRUD_READ_FAILED:
      return {
        ...state,
        isFetching: false,
      }

    case actionTypes.participants.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      }

    default:
      return state
  }
}