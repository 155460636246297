import React, { Component } from 'react'
import { actions as ReduxFormActions, Fieldset } from 'react-redux-form'
import { restaurantContactPerson } from '../../../redux/forms'
import { Button, Col, Row } from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'
import LineAwesome from '../../../components/LineAwesome'
import FormInput from '../../../components/FormInput'

const mapStateToProps = state => ({
  restaurant: state.forms.restaurant,
})

class RestaurantFormContactPersons extends Component {
  render () {
    const {
      restaurant,
      dispatch,
      lang,
    } = this.props

    const contact_persons = [].concat(restaurant.contact_person)

    return (
      <div>
        <h3>
          {lang.contact_person}

          <Button type="button"
                  size="sm"
                  color="link"
                  className="pull-left"
                  onClick={() => dispatch(
                    ReduxFormActions.push(
                      'forms.restaurant.contact_person',
                      restaurantContactPerson,
                    ),
                  )}>
            <LineAwesome icon="plus"/> {lang.add_contact_person}
          </Button>
        </h3>

        {
          contact_persons.map((cp, index) => (
            <Fieldset model={`.contact_person[${index}]`}
                      key={index}>
              <Row className="justify-content-center">
                <Col md={3}>
                  <FormInput
                    model=".first_name"
                    label={lang.first_name}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    model=".position"
                    label={lang.position}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    type="phone"
                    model=".phone"
                    label={lang.phone}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    type="textarea"
                    model=".note"
                    label={lang.note}
                  />
                </Col>
                  <Col md={3}>
                  <FormInput
                    type="checkbox"
                    id="is_receive_messages"
                    model=".is_receive_messages"
                    label={lang.is_receive_messages}
                  />
                </Col>
              </Row>

              {
                index > 0 && (
                  <div className="text-center">

                    <Button color="danger"
                            size="sm"
                            onClick={() => dispatch(
                              ReduxFormActions.remove(
                                `forms.restaurant.contact_person`,
                                index,
                              ),
                            )}>
                      <LineAwesome icon="trash-o"/>
                    </Button>
                  </div>
                )
              }

              {
                index + 1 !== contact_persons.length &&
                <hr className="w-50"/>
              }

            </Fieldset>
          ))
        }
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(RestaurantFormContactPersons))