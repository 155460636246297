import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LineAwesome from './LineAwesome'
import PageTitleButton from './PageTitleButton'
import { connect } from 'react-redux'

const propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onCreateClick: PropTypes.func
}

class PageTitle extends Component {
  render () {
    const {
      title,
      icon,
      onCreateClick
    } = this.props

    return (
      <h1 className="page-title">
        <LineAwesome icon={icon}/>
        {title}

        {
          module && (
            <PageTitleButton
              onClick={onCreateClick}
            />
          )
        }
      </h1>
    )
  }
}

PageTitle.propTypes = propTypes

export default connect()(PageTitle)