import React, { Component } from 'react'
import { Form } from 'reactstrap'
import { Form as ReduxForm } from 'react-redux-form'
import RestaurantFormGeneral from './components/RestaurantFormGeneral'
import RestaurantFormLocation from './components/RestaurantFormLocation'
import RestaurantFormFood from './components/RestaurantFormFood'
import RestaurantFormContactPersons from './components/RestaurantFormContactPersons'
import RestaurantFormOther from './components/RestaurantFormOther'

class RestaurantForm extends Component {
  render () {
    return (
      <div className="restaurant-data">
        <Form tag={ReduxForm}
              model="forms.restaurant">
          <RestaurantFormGeneral/>
          <hr/>

          <RestaurantFormLocation/>

          <hr/>

          <RestaurantFormFood/>

          <hr/>

          <RestaurantFormContactPersons/>

          <hr/>

          <RestaurantFormOther/>
        </Form>
      </div>
    )
  }
}

export default RestaurantForm