import React, { Component } from 'react'
import { connect } from 'react-redux'
import selectors from '../../redux/selectors'
import { withLang } from '../../utils/withLang'
import { EMAIL_TEMPLATES } from '../../config/table-columns'
import ReactTable from 'react-table'
import PageTitle from '../../components/PageTitle'
import { ITEMS_PER_PAGE } from '../../config/constants'
import actions from '../../redux/actions'
import { setDocumentTitle } from '../../utils/functions'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  emailTemplates: selectors.emailTemplates.getAll(state),
  isFetching: selectors.emailTemplates.getIsFetching(state),
})

class EmailTemplatesHome extends Component {

  handleNewClick = () => {
    this.props.dispatch(actions.emailTemplates.openModalForm())
  }

  componentDidMount () {
    setDocumentTitle('Email Templates')
  }

  render () {
    let { emailTemplates, lang, isFetching } = this.props

    return (
      <div className="age-groups">

        <PageTitle
          title={lang.email_templates}
          icon="la la-envelope"
          onCreateClick={this.handleNewClick}
        />

        <ReactTable
          showPagination={false}
          data={emailTemplates}
          columns={EMAIL_TEMPLATES}
          defaultPageSize={emailTemplates.length}
          className="-striped -highlight"
          loading={isFetching}
        />

      </div>
    )
  }
}

export default withRouter(
  withLang(connect(mapStateToProps)(EmailTemplatesHome)))