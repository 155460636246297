import React, { Component } from 'react'
import { lang, withLang } from '../../utils/withLang'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Tabs from '../../components/Tabs'
import MeetingForm from './components/MeetingFormGeneral'
import ParticipantsData from './components/MeetingParticipants'
import MeetingState from './components/MeetingState'
import MeetingEvents from './components/MeetingEvents'
import MeetingMessages from './components/MeetingMessages'
import MeetingFilling from './components/MeetingFilling'

const mapStateToProps = state => ({
  meeting: state.forms.meeting,
})

class MeetingFormTabs extends Component {
  tabs = [
    {
      title: lang.general,
      label: 'data',
      component: MeetingForm,
    },
    {
      title: lang.participants,
      label: 'participants',
      component: ParticipantsData,
    },
    {
      title: lang.tasks,
      label: 'tasks',
      component: MeetingState,
    },
    {
      title: lang.events,
      label: 'events',
      component: MeetingEvents,
    },
    {
      title: lang.messages,
      label: 'messages',
      component: MeetingMessages,
    },
    {
      title: lang.fill_meeting,
      label: 'fill_meeting',
      component: MeetingFilling,
    },
  ]

  constructor (props) {
    super(props)

    this.state = {
      activeTab: this.tabs[0].label,
    }
  }

  componentDidMount () {
    //if it is a new meeting (without meeting ID) - we don`t need to show tab - MESSAGES
    // so we remove last object in tabs:
    if (!this.props.meeting.id) {
      this.tabs = [...this.tabs.slice(0, -1)]
    }
  }

  render () {
    const { activeTab } = this.state

    return (
      <Tabs
        tabs={this.tabs}
        activeTab={activeTab}
        onTabClick={tab => this.setState({ activeTab: tab })}
      />
    )
  }
}

export default withRouter(withLang(connect(mapStateToProps)(MeetingFormTabs)))
