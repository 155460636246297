export const ageRange = {
  title: '',
  age_from: '',
  age_to: '',
}

export const city = {
  id: '',
  name: ''
}

export const club = {
  id: '',
  name: '',
  display_name: ''
}


export const ageGroup = {
  name: '',
  men_from: '',
  men_to: '',
  women_from: '',
  women_to: '',
}

export const participant = {
  birthday: '',
  city: '',
  email: '',
  first_name: '',
  events: [],
  gender: 0,
  last_name: '',
  phone: '',
  age_group_id: '',
  age_range: '',
  list13: false,
  list14: false,
  not_accepting_to_get_arrows: false,
  unsubscribed_from_mobile: false,
  note: '',
}

export const meeting = {
  title: '',
  date_time: null,
  restaurant: '',
  age_group: 1,
  meeting_type: 1,
  meeting_day_part: 3,
  meeting_schedule_type: 1,
  men_seats: '',
  women_seats: '',
  events: [],
  participants: [],
  price: 20,
  is_first_drink_free: false,
  whatsapp_group_url: '',
  minimum_participants_each_gender:1,
  is_taking_place: false,
  is_full: false,
  is_archived: false,
  is_planned: true,
  is_spontaneous: false,
  sys_cancel_reason: '',
  is_to_be_notice: false,
  is_draft: false,
}

export const meetingState = {
  tasks: [],
}

export const restaurantContactPerson = {
  first_name: '',
  position: '',
  phone: '',
  note: '',
  is_receive_messages: false,
}

export const restaurant = {
  title: '',
  city: '',
  address: '',
  website: '',
  phone: '',
  reg_date: '',
  age_range: '',
  availability: '',
  average_price: '',
  food_type: '',
  kosher: '',
  music: '',
  origin: '',
  parking: '',
  pathway: '',
  waze_link: '',
  parking_waze_link: '',
  parking_directions: '',
  table_shape: '',
  standing: '',
  management_system: '',
  contact_person: [
    restaurantContactPerson,
  ],
}

export const auth = {
  login: '',
  password: '',
  otp: '',
}

export const messagesType = {
  name: '',
  text_men: '',
  text_women: '',
  is_campaign_followup: false,
  send_for_unsubscribed: false,
  active_tab: 'content_for_men',
}

export const emailTemplate = {
  name: '',
  at_template_id_men: '',
  at_template_id_women: '',
  is_campaign: false
}

export const messagesEvent = {
  /** replace event_type with empty string for ReturnMeetingStatusBasedSendingEvent*/
  event_type: 'ParticipantStatusBasedSendingEvent',
  weekday: '',
  name: '',
  meeting_statuses: '',
  offset_days: undefined,
  time: '',
  participation_statuses: '',
  template: '',
  // is_automatic: 0,
  trigger_status: '',
  when: '',

}

export const manuallyMessage = {
  recipients: '',
  text: '',
}

export const manuallyMessageRestaurant = {
  recipients: '',
  text: '',
}
