import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { MEETING_TYPES, WEEKDAYS } from '../../../config/constants'
import { withLang } from '../../../utils/withLang'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const MeetingTypeSelect = (props) => {

  const {
    value,
    onChange,
    lang,
    ...otherProps
  } = props

  const meeting_types = Object.keys(MEETING_TYPES).map(
    type => ({
      label: lang[MEETING_TYPES[type]],
      value: +type,
    }))

  return (
    <Select
      type="select"
      name="meeting_type"
      id="select-meeting-type"
      placeholder=""
      value={meeting_types.filter(
        option => option.value === value)}
      options={meeting_types}
      onChange={({ value }) => onChange(value)}
      {...otherProps}
    />
  )
}

MeetingTypeSelect.propTypes = propTypes

export default withLang(MeetingTypeSelect)