import React, { Component } from 'react'
import { connect } from 'react-redux'
import selectors from '../redux/selectors'
import { Button, ModalFooter, ModalHeader } from 'reactstrap'
import SpinnerBox from './SpinnerBox'
import { MODALS } from '../config/constants'
import ModalBody from 'reactstrap/es/ModalBody'
import { actions as ReduxFormActions } from 'react-redux-form'
import { getActionsForForm } from '../utils/functions'
import actions from '../redux/actions'
import LineAwesome from './LineAwesome'
import { withLang } from '../utils/withLang'
import { toast } from 'react-toastify'

const mapStateToProps = (state, props) => {
  const modal = !props.submodal
    ? selectors.core.getModal(state)
    : selectors.core.getSubModal(state)

  return {
    modal,
    form: modal.form && state.forms[modal.form],
    $form: modal.form && state.forms.forms[modal.form].$form,
  }
}

class ModalForm extends Component {
  state = {
    submitted: false,
    isFetching: false,
  }

  _isMounted = false

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleClickSubmit = () => {
    const {
      form,
      $form,
      modal,
      dispatch,
      lang,
    } = this.props

    dispatch(actions.core.updateModal({
      className: $form.valid ? 'modal_valid' : 'modal_invalid',
    }))

    dispatch(ReduxFormActions.submit(`forms.${modal.form}`))
    if (!$form.valid) {
      toast.warn(lang.errors.check_entered_data)
    } else {
      const action = getActionsForForm(modal.form)[modal.isNew ?
        'crudCreate' :
        'crudUpdate']

      if (this._isMounted) {
        this.setState({ isFetching: true })
        dispatch(action({ ...form })).then(() => {
          dispatch(ReduxFormActions.setUntouched(`forms.${modal.form}`))
          if (this._isMounted) {
            this.setState({ isFetching: false })
          }
        })
      }
    }
  }

  handleClickDelete = () => {
    const {
      form,
      modal,
      dispatch,
      lang,
    } = this.props

    const formActions = getActionsForForm(modal.form)

    if (window.confirm(lang['are_you_sure'])) {
      dispatch(formActions['crudDelete']({ ...form })).then(
        () => dispatch(actions.core.closeModal())).finally(
        () => {
          if (this._isMounted) {
            this.setState({ isFetching: false })
          }
        })
    }
  }

  handleClickClose = () => {
    this.props.dispatch(actions.core.closeModal(false, this.props.submodal))
  }

  render () {
    const {
      modal: {
        title, withoutFooter,
      },
    } = this.props

    const { isFetching } = this.state

    return (
      <>
        {
          isFetching && <SpinnerBox spinner={true}/>
        }
        <ModalHeader>
          {title}
        </ModalHeader>

        <ModalBody>
          {this.renderContent()}
        </ModalBody>

        {
          !withoutFooter &&
          <ModalFooter>
            {this.renderFooter()}
          </ModalFooter>
        }

        {
          withoutFooter &&
          <div className='close-submodal-btn'
               onClick={this.handleClickClose}>
            <LineAwesome icon="remove"/>
          </div>
        }
      </>
    )
  }

  renderContent () {
    const {
      modal: {
        component,
      },
    } = this.props

    //Here we get SOMEMODALFormTabs or another main Form component, e.g. MeetingFormTabs or RestaurantForm.
    const Component = MODALS[component]

    if (!Component) return null
    return <Component/>
  }

  renderFooter () {
    const {
      modal,
      lang,
    } = this.props

    return (
      <div
        className="d-flex w-100 justify-content-between align-items-center">
        <div>
          <Button
            color="success"
            onClick={this.handleClickSubmit}>
            <LineAwesome icon="save"/> {lang.save}
          </Button>
          <Button
            color="link"
            onClick={this.handleClickClose}>
            {lang.close}
          </Button>
        </div>

        {
          !modal.isNew && (
            <div className="text-left">
              <Button
                color="danger"
                size="sm"
                onClick={this.handleClickDelete}>
                <LineAwesome icon="trash"/>
                {lang.delete}
              </Button>
            </div>
          )
        }
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(ModalForm))