import React from 'react'
import MessagesEventsHome from './MessagesEventsHome'

const MessagesEventsModule = () => {
  return (
    <div id="module-messages-events">
      <MessagesEventsHome/>
    </div>
  )
}

export default MessagesEventsModule