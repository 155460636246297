import selectors from './index'
import { ENTITY_PARTICIPANTS } from '../../config/constants'

export const getIds = state => state.participants.ids
export const getAll = state => state.participants.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_PARTICIPANTS, id))
export const getById = (
  state, participantId) => state.resources[ENTITY_PARTICIPANTS]['' +
participantId]
export const getPage = state => state.participants.page
export const getCount = state => state.participants.count
export const getIsFetching = state => state.participants.isFetching
export const getSearch = state => state.participants.search