import React, { Component } from 'react'
import MeetingParticipantsTable from './MeetingParticipantsTable'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'
import { GENDER_MAN, GENDER_WOMAN, PARTICIPATION_STATUS_FILTERS, } from '../../../config/constants'
import ButtonGroup from 'reactstrap/es/ButtonGroup'
import { Button } from 'reactstrap'

const mapStateToProps = state => ({
  meeting: state.forms.meeting,
})

const initialStatePreview = {
  participant: null,
  isOpen: false,
  isFetching: false,
}

class MeetingParticipants extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedFilter: Object.keys(PARTICIPATION_STATUS_FILTERS)[0],
      preview: {
        ...initialStatePreview,
      },
    }
  }

  render () {
    const { lang } = this.props
    const {
      selectedFilter,
    } = this.state

    return (
      <div className="meeting-management-participants">
        <div className="text-center">
          <ButtonGroup size="sm mb-4">
            {
              Object.keys(PARTICIPATION_STATUS_FILTERS).map(filter => (
                <Button color={selectedFilter === filter ?
                  'success' :
                  'secondary'}
                        onClick={() => this.setState(
                          { selectedFilter: filter })}
                        key={filter}>
                  {lang[filter]}
                </Button>
              ))
            }
          </ButtonGroup>
        </div>

        <h3>{lang.men}</h3>
        <MeetingParticipantsTable
          gender={GENDER_MAN}
          filterStatuses={PARTICIPATION_STATUS_FILTERS[selectedFilter]}
        />

        <h3>{lang.women}</h3>
        <MeetingParticipantsTable
          gender={GENDER_WOMAN}
          filterStatuses={PARTICIPATION_STATUS_FILTERS[selectedFilter]}
        />
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(MeetingParticipants))