import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'
import { Form, } from 'react-redux-form'
import validators from '../../../utils/validators'
import FormInput from '../../../components/FormInput'
import LineAwesome from '../../../components/LineAwesome'
import {
  DEFAULT_TEMPLATE_TAGS,
  WYSIWYG_SETTINGS_FORMATS,
} from '../../../config/constants';
import { createManuallyMessages, } from '../../../redux/actions/meetingsMessagesActions'

const mapStateToProps = state => ({
  meeting: state.forms.meeting,
  form: state.forms.manuallyMessage,
})

class MeetingManuallySendForm extends Component {

  handleClickSubmit = () => {
    const { meeting, form, dispatch } = this.props
    dispatch(createManuallyMessages(meeting.id, form))
  }

  render () {
    const {
      lang, meeting,
    } = this.props

    return (
      <div className="meeting-manually-message">
        <Form
          model="forms.manuallyMessage">
          <div className="card-body">

            <div className="row justify-content-center">
              <div className="col-md-12">
                <FormInput
                  type="meeting_participant"
                  model=".recipients"
                  id={meeting.id}
                  label={lang.participation_status}
                  validators={{
                    required: validators.required,
                  }}
                  errorMessages={{
                    required: lang.errors.required_field,
                  }}
                />
              </div>
            </div>


            <div className="row justify-content-center mt-1">
              <div className="col-md-12">
                <FormInput
                  formats={WYSIWYG_SETTINGS_FORMATS}
                  type="wysiwyg"
                  templateTags={DEFAULT_TEMPLATE_TAGS}
                  model=".text"
                  label={lang.message_content}
                  validators={{
                    required: validators.required,
                    smsLength: validators.smsLength
                  }}
                  errorMessages={{
                    required: lang.errors.required_field,
                    smsLength: lang.errors.sms_length
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-1 mb-4">
            <Button
              type="submit"
              color="success"
              onClick={this.handleClickSubmit}>
              <LineAwesome icon="envelope"/> {lang.send_message}
            </Button>
          </div>

        </Form>
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(MeetingManuallySendForm))