import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import Provider from 'react-redux/es/components/Provider'
import store from './redux/store'
import { ConnectedRouter } from 'connected-react-router'
import history from './config/history'
import './config/network'
// SCSS
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css'
import 'react-table/react-table.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap-4.1.3-rtl/css/bootstrap.css'
import './style/app.scss'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import he from 'date-fns/locale/he'
import moment from 'moment'
import 'moment/locale/he'

registerLocale('he', he)
setDefaultLocale('he')
moment().locale('he')

Sentry.init({
  dsn: 'https://91215bfa77191a70aed04f90997f9da7@o4507294481186816.ingest.de.sentry.io/4507321343672400',
  environment: process.env.REACT_APP_ENVIRONMENT || 'unknown'
})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App/>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
