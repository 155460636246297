export const CREATE_AUTH_SUCCEEDED = 'CREATE_AUTH_SUCCEEDED'
export const DELETE_AUTH = 'DELETE_AUTH'
export const INIT_APP_SUCCEEDED = 'INIT_APP_SUCCEEDED'

export const SERVER_ERROR = 'SERVER_ERROR'
export const SERVER_UNAVAILABLE = 'SERVER_UNAVAILABLE'

export const LAYOUT_HEADER_ENABLED = 'LAYOUT_HEADER_ENABLED'
export const LAYOUT_HEADER_DISABLED = 'LAYOUT_HEADER_DISABLED'
export const LAYOUT_SIDEBAR_ENABLED = 'LAYOUT_SIDEBAR_ENABLED'
export const LAYOUT_SIDEBAR_DISABLED = 'LAYOUT_SIDEBAR_DISABLED'

export const OPEN_MODAL = 'OPEN_MODAL'
export const OPEN_SUBMODAL = 'OPEN_SUBMODAL'
export const UPDATE_MODAL = 'UPDATE_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CLOSE_SUBMODAL = 'CLOSE_SUBMODAL'