import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { withLang } from '../../../utils/withLang'
import FormInput from '../../../components/FormInput'

class RestaurantFormLocation extends Component {
  render () {
    const { lang } = this.props

    return (
      <div>
        <h3>{lang.location}</h3>

        <Row className="align-items-center justify-content-center">
          <Col md={4}>
            <FormInput
              model=".city"
              label={lang.city}
            />

            <FormInput
              type="textarea"
              model=".address"
              label={lang.address}
            />

            <FormInput
              model=".parking"
              label={lang.parking}
            />

            <FormInput
              model=".parking_directions"
              label={lang.parking_directions}
            />
          </Col>

          <Col md={4}>
            <FormInput
              type="textarea"
              model=".pathway"
              label={lang.pathway}
            />

            <FormInput
              model=".waze_link"
              label={lang.waze_link}
            />

            <FormInput
              model=".availability"
              label={lang.availability}
            />

            <FormInput
              model=".parking_waze_link"
              label={lang.parking_waze_link}
            />

          </Col>
        </Row>
      </div>
    )
  }
}

export default withLang(RestaurantFormLocation)
