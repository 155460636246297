import React from 'react'

export const lang = require('../config/lang/he-IL')

export function withLang (WrappedComponent) {
  // TODO: do the HOC smarter - check availability and shows console.warning if not exists
  return class extends React.Component {
    render () {
      const props = Object.assign({}, this.props)
      props.lang = lang

      return (
        <WrappedComponent {...props} />
      )
    }
  }
}