import React, { Component } from 'react'
import { connect } from 'react-redux'
import selectors from '../redux/selectors'
import ModalForm from './ModalForm'
import { Modal as BSModal } from 'reactstrap'
import actions from '../redux/actions'

const mapStateToProps = state => ({
  modal: selectors.core.getModal(state),
  submodal: selectors.core.getSubModal(state),
})

class ModalWrapper extends Component {
  componentDidMount () {
    document.addEventListener('keydown', this.escHandler, false)
    document.addEventListener('mouseup', this.globalClickHandler, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.escHandler, false)
    document.removeEventListener('mouseup', this.globalClickHandler, false)
  }

  globalClickHandler = event => {
    const {
      modal
    } = this.props
    if (modal.isOpen && event.target.className === 'modal fade show') {
      this.closeModal()
    }
  }

  escHandler = event => {
    if (event.keyCode === 27) {
      this.closeModal()
    }
  }

  closeModal = () => {
    const submodal = this.props.submodal.isOpen
    this.props.dispatch(actions.core.closeModal(false, submodal))
  }

  render () {
    const {
      modal: {
        isOpen,
        size,
        centered,
        form,
        className
      },
      submodal: submodalProps
    } = this.props

    if (!isOpen) return null

    const modal = {
      centered,
      size,
      isOpen,
      modalTransition: { timeout: 100 },
      backdropTransition: { timeout: 400 },
      className
    }

    const submodal = {
      centered: submodalProps.centered,
      size: submodalProps.size,
      isOpen: submodalProps.isOpen,
      modalTransition: { timeout: 100 },
      backdropTransition: { timeout: 400 },
      className: submodalProps.className
    }

    return (
      <>
        <BSModal {...modal}>
          {form && <ModalForm/>}
        </BSModal>

        <BSModal {...submodal}>
          {form && <ModalForm submodal={true}/>}
        </BSModal>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
)(ModalWrapper)