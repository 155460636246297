import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'
import { PARTICIPATION_STATUSES } from '../../../config/constants'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return ({
    participants: state.forms.meeting.participants,
  })
}

const MeetingMessagesMultiSelectParticipants = (props) => {

  const {
    value,
    onChange,
    lang,
    participants,
    ...otherProps
  } = props

  const participantsOptions = participants.map(
    participant => {
      return ({
        label: `${participant.first_name} ${participant.last_name} (${lang[PARTICIPATION_STATUSES[participant.status]]})`,
        value: participant.participant_id,
      })
    })

  return (
    <Select
      type="select"
      name="meeting-recipients"
      id="select-meeting-recipients"
      isMulti
      getValue={participantsOptions.filter(
        option => option.value === value)}
      options={participantsOptions}
      onChange={(arr) => {
        const ids = arr.map(obj => obj.value)
        onChange(ids)
      }}
      {...otherProps}
    />
  )
}

MeetingMessagesMultiSelectParticipants.propTypes = propTypes

export default withLang(
  connect(mapStateToProps)(MeetingMessagesMultiSelectParticipants))
