import actionTypes from '../action-types';
import { ITEMS_PER_PAGE } from '../../config/constants';

const initialState = {
	ids: [],
	count: null,
	perPage: ITEMS_PER_PAGE,
	page: 1,
	isFetching: false,
};

export default function participantMessages (state = initialState, action) {
	switch (action.type) {
		case actionTypes.participantMessages.CRUD_READ_PENDING:
			return {
				...state,
				isFetching: true,
				ids: [],
			};

		case actionTypes.participantMessages.CRUD_READ_SUCCEEDED:
			return { ...state, ...action.payload, isFetching: false };

		case actionTypes.participantMessages.PARTICIPANT_MESSAGES_SET_PAGE:
			return { ...state, page: action.payload.page };

		default:
			return state;
	}
};