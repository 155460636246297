import actionTypes from '../action-types'
import { ITEMS_PER_PAGE } from '../../config/constants'

const initialState = {
  ids: [],
  count: null,
  perPage: ITEMS_PER_PAGE,
  page: 1,
  isFetching: false,
}

export default function messagesTypes (state = initialState, action) {
  switch (action.type) {
    case actionTypes.messagesTypes.CRUD_READ_PENDING:
      return {
        ...state,
        isFetching: true,
        ids: [],
      }
    case actionTypes.messagesTypes.CRUD_READ_SUCCEEDED:
      return { ...state, ...action.payload, isFetching: false }

    default:
      return state
  }
};