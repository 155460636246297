import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { RESTAURANTS_COLUMNS } from '../../config/table-columns'
import { withLang } from '../../utils/withLang'
import selectors from '../../redux/selectors'
import actions from '../../redux/actions'
import { setDocumentTitle } from '../../utils/functions'
import PageTitle from '../../components/PageTitle'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  restaurants: selectors.restaurants.getAll(state),
})

class RestaurantsHome extends Component {
  componentDidMount () {
    setDocumentTitle('Restaurants')
  }

  handleNewClick = () => {
    this.props.dispatch(actions.restaurants.openModalForm())
  }

  render () {
    const {
      restaurants,
      isFetching,
      lang,
    } = this.props

    return (
      <div className="restaurants">
        <PageTitle
          title={lang.restaurants}
          icon="cutlery"
          onCreateClick={this.handleNewClick}
        />

        <ReactTable
          showPagination={false}
          data={restaurants}
          loading={isFetching}
          columns={RESTAURANTS_COLUMNS}
          defaultPageSize={restaurants.length}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

export default withRouter(withLang(connect(mapStateToProps)(RestaurantsHome)))