import React, { Component } from 'react'

class Spinner extends Component {
  render () {
    return (
      <div role="status" className="spinner text-primary">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }
}

export default Spinner