import { getEntity, getList } from './resourcesSelectors'
import { ENTITY_MEETINGS } from '../../config/constants'

export const getAll = state => getList(state, ENTITY_MEETINGS)
export const getById = (state, id) => getEntity(state, ENTITY_MEETINGS, id)
export const getIds = state => state.meetings.ids
export const getIsFetching = state => state.meetings.isFetching
export const getCount = state => state.meetings.count
export const getPage = state => state.meetings.page
export const getTasksByStageId = (tasks, stageId) => tasks.filter(
  task => task.status === stageId)