import React from 'react'
import Select from 'react-select'
import { withLang } from '../../../utils/withLang'
import { PARTICIPATION_STATUSES } from '../../../config/constants'

const MessagesEventsMultiSelectParticipantType = (props) => {

  const {
    value,
    onChange,
    lang,
  } = props

  const participantTypeOptions = Object.keys(PARTICIPATION_STATUSES).map(
    type => ({
      label: lang[PARTICIPATION_STATUSES[type]],
      value: +type,
    }))

  // Define defaultValue if open UPDATE-MODAL`
  let defValue = null
  if (value) {
    defValue = value.split(',').map((id) => {
      return participantTypeOptions.find(type => type.value === +id)
    })
  }

  return (
    <Select
      className="basic-multi-select"
      classNamePrefix="select"
      isMulti
      defaultValue={defValue}
      name="multi-participant-type"
      getValue={participantTypeOptions.filter(
        option => option.value === value)}
      options={participantTypeOptions}
      onChange={(arr) => {
        const ids = arr.map(obj => obj.value).join(',')
        onChange(ids)
      }}
    />
  )
}

export default withLang(MessagesEventsMultiSelectParticipantType)