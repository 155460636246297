import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from 'reactstrap'
import LineAwesome from '../../../components/LineAwesome'
import { withLang } from '../../../utils/withLang'
import FormInput from '../../../components/FormInput'
import { CirclePicker } from 'react-color'

const propTypes = {
  stage: PropTypes.object.isRequired,
  stageIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

class StageForm extends Component {
  constructor (props) {
    super(props)

    this.handleNewTaskClick = this.handleNewTaskClick.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleTaskChange = this.handleTaskChange.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleSortClick = this.handleSortClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
  }

  handleNewTaskClick () {
    const { stage, stageIndex, onChange } = this.props
    stage.task_prototypes.push({ name: '' })
    return onChange(stageIndex, stage)
  }

  handleTitleChange ({ target: { id, value } }) {
    const { stage, stageIndex } = this.props
    stage.name = value
    return this.props.onChange(stageIndex, stage)
  }

  handleTaskChange ({ target: { id, value } }) {
    const { stage, stageIndex } = this.props
    stage.task_prototypes[id].name = value
    return this.props.onChange(stageIndex, stage)
  }

  handleColorChange (color) {
    const { stage, stageIndex } = this.props
    stage.color = color.hex
    return this.props.onChange(stageIndex, stage)
  }

  handleSortClick (taskIndex, dir) {
    const { stage, stageIndex, onChange } = this.props
    const dest = dir === 'up' ? taskIndex - 1 : taskIndex + 1

    stage.task_prototypes = stage.task_prototypes.map((task, i) => {
      if (i === taskIndex) return stage.task_prototypes[dest]
      if (i === dest) return stage.task_prototypes[taskIndex]
      return task
    })

    return onChange(stageIndex, stage)
  }

  handleRemoveClick (taskIndex) {
    const { stage, stageIndex, onChange } = this.props
    stage.task_prototypes.splice(taskIndex, 1)
    return onChange(stageIndex, stage)
  }

  render () {
    const { stage, stageIndex, onRemove, lang } = this.props
    return (
      <div className="meeting-cycle-stage">

        <h4 style={{ color: stage.color }}>{stageIndex + 1}</h4>

        <CirclePicker
          width="100%"
          circleSize={15}
          circleSpacing={5}
          className="mb-4"
          onChangeComplete={this.handleColorChange}
          color={stage.color}
        />

        <FormInput
          size="sm"
          placeholder={lang.title}
          value={stage.name}
          onChange={this.handleTitleChange}
        />
        {
          stage.task_prototypes.map((task, taskIndex) => (
            <div className="meeting-cycle-stage-task"
                 key={taskIndex}>
              <Input
                className="meeting-cycle-stage__input"
                bsSize="sm"
                id={taskIndex}
                value={task.name}
                onChange={this.handleTaskChange}
                placeholder={lang.task}
                autoComplete="off"
                tabIndex={taskIndex + 1}
              />

              <Button
                color="icon"
                onClick={() => this.handleSortClick(taskIndex, 'up')}
                disabled={taskIndex === 0}
                tabIndex="-1">
                <LineAwesome icon="arrow-up"/>
              </Button>

              <Button
                color="icon"
                onClick={() => this.handleSortClick(taskIndex, 'down')}
                disabled={taskIndex === stage.task_prototypes.length - 1}
                tabIndex="-1">
                <LineAwesome icon="arrow-down"/>
              </Button>

              <Button
                color="icon"
                className="text-danger"
                onClick={() => this.handleRemoveClick(taskIndex)}
                tabIndex="-1">
                <LineAwesome icon="trash"/>
              </Button>
            </div>
          ))
        }

        <Button
          className="meeting-cycle-stage__btn text-success"
          color="icon"
          size="sm"
          block
          onClick={this.handleNewTaskClick}>
          <LineAwesome icon="plus"/> {lang.add_new_task}
        </Button>

        <Button
          className="meeting-cycle-stage__btn text-danger"
          color="icon"
          size="sm"
          block
          onClick={() => onRemove(stageIndex)}>
          <LineAwesome icon="trash"/> {lang.delete}
        </Button>
      </div>
    )
  }
}

StageForm.propTypes = propTypes

export default withLang(StageForm)