import React, { useMemo } from 'react';

import { lang } from '../../../utils/withLang';

const ParticipantMessagesStatus = ({ status }) => {

	const displayStatus = useMemo(() => {
		switch (status) {
			case 'new': {
				return <span>{lang.sent}</span>;
			}

			case 'sent': {
				return <span>{lang.sent}</span>;
			}
			case 'שליחה': {
				return <span>{lang.sent}</span>;
			}
			case 'queued': {
				return <span>{lang.queued}</span>;
			}
			case 'failed': {
				return <span>{lang.failed}</span>;
			}
			case 'delivered': {
				return <span>{lang.delivered}</span>;
			}
			case 'undelivered': {
				return <span>{lang.undelivered}</span>;
			}
			case 'sending': {
				return <span>{lang.sending}</span>;
			}

			case 'dumped to slack': {
				return <span>{lang.dumped_to_slack}</span>;
			}

			case 'delayed': {
				return <span>{lang.delayed}</span>;
			}

			case 'read':{
				return <span>{lang.read}</span>;
			}

			default: {
				return <span>{lang.undefined_status}</span>;
			}
		}
	}, []);

	return (
		<div>
			{displayStatus}
		</div>
	);
};

export default ParticipantMessagesStatus;
