import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { EVENT_TYPES } from '../config/constants'
import { withLang } from '../utils/withLang'
import { FormGroup, Label } from 'reactstrap'

const propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

class SelectEventType extends Component {
  render () {
    const { onChange, value, lang } = this.props

    const options = EVENT_TYPES.map(type => ({
      label: lang[type],
      value: type,
    }))

    return (
      <FormGroup>
        <Label>{lang.event_type}</Label>
        <Select
          size="sm"
          options={options}
          onChange={option => onChange(option.value)}
          value={options.filter(option => option.value === value)}
          placeholder=''
        />
      </FormGroup>
    )
  }
}

SelectEventType.propTypes = propTypes

export default withLang(SelectEventType)