import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatDateTime } from '../../../utils/functions'
import {
  EVENT_TYPE_SMS_FILL_UP
} from '../../../config/constants'
import { withLang} from '../../../utils/withLang'
import { Table } from 'reactstrap'

const propTypes = {
  meeting: PropTypes.object.isRequired
}

class MeetingSMSFillupList extends Component {
  getMessage (event) {
    const { meeting, lang } = this.props

    switch (event.type) {
      case EVENT_TYPE_SMS_FILL_UP:
        return (
          <span>{event.message}</span>
        )
      default:
        return
    }
  }

  render () {
    const { meeting: { events }, lang } = this.props

    const isEventsEmpty = !events || !events.length

    return (
      <div className="meeting-events-list">
        {
          !isEventsEmpty && (
            <div className="meeting-events-list-table">
              <Table>
                <thead>
                <tr>
                  <th>{lang.time_arrow_sent}</th>
                  <th>{lang.arrow_details}</th>
                </tr>
                </thead>
                <tbody>
                {
                  events.filter(event=> event.type === EVENT_TYPE_SMS_FILL_UP).map(event => (
                    <tr key={event.date}>
                      <td className="text-muted">{formatDateTime(+event.date)}</td>
                      <td>{this.getMessage(event)}</td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </div>
          )
        }

        {
          isEventsEmpty && (
            <p className="meeting-events__empty">{lang.no_events}</p>
          )
        }
      </div>
    )
  }
}

MeetingSMSFillupList.propTypes = propTypes

export default withLang(MeetingSMSFillupList)