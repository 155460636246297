import selectors from './index'
import { ENTITY_EMAIL_TEMPLATES } from '../../config/constants'

export const getAll = state => state.emailTemplates.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_EMAIL_TEMPLATES, id))
export const getCampaignTemplatesOnly = state => state.emailTemplates.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_EMAIL_TEMPLATES, id))
  .filter(template => template.is_campaign == true)
export const getById = (state, id) => state.resources[ENTITY_EMAIL_TEMPLATES]['' +
id]
export const getIds = state => state.emailTemplates.ids
export const getCount = state => state.emailTemplates.count
export const getIsFetching = state => state.emailTemplates.isFetching
export const getPage = state => state.emailTemplates.page