import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { MESSAGE_EVENTS_WHEN_SEND } from '../../../config/constants'
import { withLang } from '../../../utils/withLang'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const MessagesEventsWhenSendSelect = (props) => {

  const {
    value,
    onChange,
    lang,
    ...otherProps
  } = props

  const whenSendOptions = Object.keys(MESSAGE_EVENTS_WHEN_SEND).map(
    type => ({
      label: lang[MESSAGE_EVENTS_WHEN_SEND[type]],
      value: +type,
    }))

  return (
    <Select
      type="select"
      name="when_send"
      id="select-when-send"
      placeholder=""
      value={whenSendOptions.filter(
        option => option.value === value)}
      options={whenSendOptions}
      onChange={({ value }) => onChange(value)}
      {...otherProps}
    />
  )
}

MessagesEventsWhenSendSelect.propTypes = propTypes

export default withLang(MessagesEventsWhenSendSelect)