import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import { withLang } from '../../../utils/withLang'

const propTypes = {
  participant_link_id: PropTypes.string.isRequired,
}

class ParticipantLinkID extends Component {
  render () {
    const {
      participant_link_id,
      lang,
    } = this.props


    return (
      <div className="text-center">

        <div className="ltr">

          <CopyToClipboard text={participant_link_id}
                         onCopy={() => toast.success(lang.copied)}>
          <Button size="sm" color="link" className="mr-0 p-0 pr-1">{lang.copy}</Button>
        </CopyToClipboard>
            {lang.link_id}
      </div>
     </div>
    )
  }
}

ParticipantLinkID.propTypes = propTypes

export default withLang(ParticipantLinkID)
