import React, { Component } from 'react'
import ParticipantsHome from './ParticipantsHome'

class ParticipantsModule extends Component {
  render () {
    return (
      <div id="module-participants">
        <ParticipantsHome/>
      </div>
    )
  }
}

export default ParticipantsModule