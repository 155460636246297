import axios from 'axios'
import store from '../redux/store'
import actions from '../redux/actions/index'
import { API_BASE } from './constants'
import { toast } from 'react-toastify'
import { lang } from '../utils/withLang'

/**
 * AXIOS configuration
 */
axios.defaults.baseURL = API_BASE
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.message === 'Network Error' || !error.response.status) {
      store.dispatch(actions.core.serverUnavailable())
    } else if (error.response.status === 500) {
      store.dispatch(actions.core.serverError())
    } else if (error.response.status === 400) {
      store.dispatch(actions.core.badRequest(error.response.data))
    } else if (error.response.status === 401
      && error.request.responseURL.indexOf('logout') === -1) {
      store.dispatch(actions.core.signOut())
      return Promise.reject()
    } else if (error.response.status === 409) {
      toast.error(lang.cannot_be_deleted)
    }

    return Promise.reject(error)
  })