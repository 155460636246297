import React from 'react'
import { connect } from 'react-redux'
import selectors from '../../../redux/selectors'
import { lang } from '../../../utils/withLang'

const mapStateToProps = (state, ownProps) => {
  return ({
    messageType: selectors.messagesTypes.getById(state, ownProps.id),
  })
}

// Component for react-table: accepts ID messageType  -> return Name of messageType
const MessagesTypesById = (props) => {

  const messageType = props.messageType

  if (!messageType) {
    return lang.manually_sending
  }

  return (
    <span>
        {messageType.name}
      </span>
  )
}

export default connect(mapStateToProps)(MessagesTypesById)
