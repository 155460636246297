import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Select from 'react-select'
import selectors from '../../../redux/selectors/index'

const mapStateToProps = state => ({
  restaurants: selectors.restaurants.getAll(state),
})

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

class SelectRestaurant extends Component {
  render () {
    const {
      restaurants,
      value,
      onChange,
      ...otherProps
    } = this.props

    const options = restaurants.map(({ id, title }) => ({
      value: id,
      label: title,
    }))

    return (
      <Select
        type="select"
        name="age"
        id="meeting-data-age"
        placeholder=""
        value={options.filter(option => option.value === value)}
        options={options}
        onChange={({ value }) => onChange(value)}
        {...otherProps}
      />
    )
  }
}

SelectRestaurant.propTypes = propTypes

export default connect(mapStateToProps)(SelectRestaurant)