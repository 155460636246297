import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Nav, NavItem, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { customFormUpdate } from '../redux/actions/coreActions'
import { connect } from 'react-redux'

const propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
}

class Tabs extends Component {
  render () {
    const { tabs, activeTab, onTabClick, dispatch, isEmptyContent } = this.props

    return (
      <>
        <Nav tabs>
          {
            tabs.map(tab => (
              <NavItem
                key={tab.label}>
                <Button
                  color="link"
                  onClick={() => {
                    dispatch(customFormUpdate('forms.messagesType.active_tab',
                      tab.label))
                    onTabClick(tab.label)
                  }}
                  className={classnames('nav-link',
                    { active: activeTab === tab.label })}>
                  {tab.title}
                </Button>
              </NavItem>
            ))
          }
        </Nav>
          { !isEmptyContent && <TabContent activeTab={activeTab}>
          {
            tabs.map(tab => {
              const TabComponent = tab.component
              const props = { ...tab.props }
              return TabComponent ? (
                <TabPane
                  key={tab.label}
                  tabId={tab.label}>
                  <TabComponent {...props} />
                </TabPane>
              ) : null
            })
          }
        </TabContent> }
      </>
    )
  }
}

Tabs.propTypes = propTypes

export default connect()(Tabs)