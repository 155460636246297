import React, { Component } from 'react'
import PropTypes from 'prop-types'
import history from '../config/history'
import { withRouter } from 'react-router-dom'
import LineAwesome from './LineAwesome'
import { ITEMS_PER_PAGE } from '../config/constants'
import { setUrlParams } from '../utils/functions'

const defaultProps = {
  perPage: ITEMS_PER_PAGE,
  page: null,
}

const propTypes = {
  totalItems: PropTypes.number,
  totalPages: PropTypes.number,
  path: PropTypes.string,
  page: PropTypes.number,
  onChange: PropTypes.func,
  perPage: PropTypes.number,
  isFetching: PropTypes.bool,
}

class Pagination extends Component {
  constructor (props) {
    super(props)

    this.state = {
      page: null,
      totalPages: null,
      totalItems: null,
      pageInput: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  componentDidMount () {
    this.computeState()
  }

  componentWillUpdate () {
    this.computeState()
  }

  computeState () {
    const {
      totalItems,
      totalPages: totalPagesFromProps,
      perPage,
      location: {
        search,
      },
      page: pageFromProps,
    } = this.props

    const params = new URLSearchParams(search)

    // get page from props and condition if()
    const page = pageFromProps
      ? pageFromProps
      : (params.get('page') && +params.get('page')) || 1

    if (page !== this.state.page) {
      this.setState({
        page,
        pageInput: page,
        totalItems,
        totalPages: totalPagesFromProps
          ? totalPagesFromProps
          : Math.ceil(totalItems / perPage),
      })
    }
  }

  go (page) {
    const {
      path,
      location,
      onChange,
    } = this.props

    const {
      totalPages,
    } = this.state

    if (page > totalPages || page < 1)
      return

    if (onChange) {
      return onChange(page)
    }

    const search = {
      page: '' + page,
    }

    const params = new URLSearchParams(location.search)
    params.forEach((value, key) => {
      if (key !== 'page') {
        search[key] = value
      }
    })

    this.setState({ page })
    history.push({
      pathname: path,
      search: setUrlParams('', search),
    })
  }

  render () {
    const { isFetching } = this.props
    const {
      totalPages,
      pageInput,
      page,
    } = this.state

    if (!totalPages || totalPages === 1)
      return null

    return (
      <div className="pg">
        <button className="pg__btn pg__btn_arrow"
                onClick={() => this.go(page - 1)}
                disabled={isFetching}>
          <LineAwesome icon="arrow-right"/>
        </button>

        <div className="pg-input">
          <form onSubmit={this.handleSubmit}>
            <input
              type="text"
              className="pg-input__el pg-input-field form-control form-control-sm"
              value={pageInput}
              onChange={this.handleChange}
              onBlur={this.handleSubmit}
            />
          </form>

          <span className="pg-input__el pg-input-sep">/</span>
          <span className="pg-input__el pg-input-total">{totalPages}</span>
        </div>

        <button className="pg__btn pg__btn_arrow"
                onClick={() => this.go(page + 1)}
                disabled={isFetching}>
          <LineAwesome icon="arrow-left"/>
        </button>
      </div>
    )
  }

  handleChange (e) {

    const { page } = this.state
    let { value: pageInput } = e.target
    pageInput = /^\d*$/.test(+pageInput)
      ? pageInput
      : page

    this.setState({ pageInput })
  }

  handleSubmit (e) {
    e.preventDefault()

    const {
      pageInput,
      page,
      totalPages,
    } = this.state

    if (pageInput > 0 && pageInput <= totalPages) {
      this.go(pageInput)
    } else {
      this.setState({ pageInput: page })
    }
  }

  handleBlur () {
    const { page = 1 } = this.props
    this.setState({ page })
  }
}

Pagination.defaultProps = defaultProps
Pagination.propTypes = propTypes

export default withRouter(Pagination)