import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import validators from '../../../utils/validators'
import { withLang } from '../../../utils/withLang'
import FormInput from '../../../components/FormInput'

class RestaurantFormGeneral extends Component {
  render () {
    const { lang } = this.props

    return (
      <div>
        <h3>{lang.general}</h3>

        <Row className="justify-content-center">
          <Col md={3}>
            <FormInput
              model=".title"
              label={lang.restaurant_title}
              validators={{ required: validators.required }}
              errorMessages={{ required: lang.errors.required_field }}
            />
          </Col>
          <Col md={3}>
            <FormInput
              type="phone"
              model=".phone"
              label={lang.phone}
            />
          </Col>
          <Col md={3}>
            <FormInput
              model=".website"
              label={lang.website}
              validators={{ link: validators.link }}
              errorMessages={{ link: lang.errors.link }}
            />
          </Col>
          <Col md={3}>
            <FormInput
              type="text"
              model=".age_range"
              label={lang.restaurant_age_limit}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default withLang(RestaurantFormGeneral)