import React from 'react'
import { CustomInput, FormGroup } from 'reactstrap'
import classnames from 'classnames'

export const Checkbox = (props) => {
  const {
    status, id, onClick,
  } = props

  return (
    <FormGroup
      className={classnames('row justify-content-end mb-0',
        onClick ? 'col' : 'col-4')}
      onClick={onClick}>
      <CustomInput
        readOnly
        type="checkbox"
        id={id}
        className={
          classnames(`custom-checkbox`,
            { 'task-check_static': onClick })
        }
        checked={status}
        disabled={!onClick}
      />
    </FormGroup>
  )

}