import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import selectors from '../redux/selectors/index'
import { connect } from 'react-redux'


const mapStateToProps = state => ({
  messagesTypes: selectors.messagesTypes.getCampaignTemplatesOnly(state),
})

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

class SelectMessageType extends Component {
  render () {
    const {
      messagesTypes,
      value,
      onChange,
      ...otherProps
    } = this.props

    const options = messagesTypes.map(({ id, name }) => ({
      value: id,
      label: name,
    }))

    return (
      <Select
        type="select"
        name="messagesType"
        id="message-type"
        placeholder=""
        value={options.filter(option => option.value === value)}
        options={options}
        onChange={({ value }) => onChange(value)}
        {...otherProps}
      />
    )
  }
}

SelectMessageType.propTypes = propTypes

export default connect(mapStateToProps)(SelectMessageType)