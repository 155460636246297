import moment from 'moment';

import {
	WHEN_MEETING_TYPES,
} from '../../config/constants';

export const transformToMeetingParticipant = (
	{
		id: participant_id,
		first_name,
		last_name,
		gender,
		phone,
		is_phone_verified,
		is_email_verified,
	},
	status = 0,
) => (
	{
		participant_id,
		first_name,
		last_name,
		gender,
		is_interviewed: false,
		phone,
		is_phone_verified,
		is_email_verified,
		status,
	}
);

export const filterParticipantsByMeeting = (participants, meeting) => (
	participants.filter(p => {
		let isExist = false;
		meeting.participants.forEach(mp => {
			if (mp.participant_id === p.id) {
				isExist = true;
			}
		});
		return !isExist;
	})
);

// filter one of: all, future, past
export const filterActionEvents = (actionEvents, filter) => {
	if (!actionEvents) return null;

	if (filter === WHEN_MEETING_TYPES.all) return actionEvents;

	return actionEvents.filter((actionEvent) => {

		const meetingInfo = actionEvent && actionEvent.meeting;
		const dateTime = moment(meetingInfo && meetingInfo.date_time);
		const now = moment();

		if (filter === WHEN_MEETING_TYPES.future) {
			return dateTime.isAfter(now);
		} else if (filter === WHEN_MEETING_TYPES.past) {
			return now.isAfter(dateTime);
		} else {
			return true; // fallback
		}
	});
};