import React from 'react'
import { withLang } from '../../../utils/withLang'
import MeetingStateTasks from './MeetingStateTasks'

function MeetingState (props) {
  const { lang } = props

  return (
    <div className="meeting-tasks">
      <h3>{lang.state}</h3>
      <MeetingStateTasks/>
    </div>
  )
}

export default withLang(MeetingState)
