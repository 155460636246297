import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Form } from 'reactstrap'
import { Form as ReduxForm } from 'react-redux-form'
import { lang, withLang } from '../../utils/withLang'
import FormInput from '../../components/FormInput'
import validators from '../../utils/validators'

const mapStateToProps = state => ({
  
})

const IdForMen = () => {
  return (<div className="row justify-content-center mr-2">
    <div className="col-12">
      <FormInput
        type="number"
        model=".at_template_id_men"
        label={lang.id_for_men}
        validators={{
          required: validators.required,
        }}
        errorMessages={{
          required: lang.errors.required_field,
        }}
      />
    </div>
  </div>)
}

const IdForWomen = () => {
  return (<div className="row justify-content-center mr-2">
    <div className="col-12">
      <FormInput
        type="number"
        model=".at_template_id_women"
        label={lang.id_for_women}
        validators={{
          required: validators.required,
        }}
        errorMessages={{
          required: lang.errors.required_field,
        }}
      />
    </div>
  </div>)
}

const EmailTemplatesForm = () => {
  return (
    <Form
      tag={ReduxForm}
      model="forms.emailTemplate">
      <div className="card-body">

        <div className="row justify-content-center">
          <div className="col-md-6">
            <FormInput
              type="text"
              model=".name"
              label={lang.name}
              validators={{
                required: validators.required,
              }}
              errorMessages={{
                required: lang.errors.required_field,
              }}
            />
          </div>
          <div className="col-md-6">
              <FormInput
                  type="checkbox"
                  id="is_campaign"
                  model=".is_campaign"
                  label={lang.campaign_template}
              />

          </div>
        </div>

        <hr/>

        {IdForWomen()}
        {IdForMen()}
        
      </div>
    </Form>
  )

}

export default withLang(connect(mapStateToProps)(EmailTemplatesForm))