import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import selectors from '../redux/selectors/index'
import { connect } from 'react-redux'

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  ageRanges: (selectors.ageRanges.getAll(state)),
})

class SelectAgeRange extends Component {
  render () {
    const {
      ageRanges,
      value,
      onChange,
      ...otherProps
    } = this.props

    const ageRangeOptions = Object.keys(ageRanges).
      map(rangeId => ({
        value: ageRanges[rangeId].id,
        label: ageRanges[rangeId].title,
      }))

    return (
      <Select
        type="select"
        value={ageRangeOptions.filter(option => option.value === +value)}
        options={ageRangeOptions}
        onChange={({ value }) => onChange(value)}
        {...otherProps}
      />
    )
  }
}

SelectAgeRange.propTypes = propTypes

export default connect(mapStateToProps)(SelectAgeRange)