import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { WEEKDAYS } from '../../../config/constants'
import { withLang } from '../../../utils/withLang'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const MessagesEventsSelectDay = (props) => {

  const {
    value,
    onChange,
    lang,
    ...otherProps
  } = props

  const weekdays = Object.keys(WEEKDAYS).map(
    type => ({
      label: lang[WEEKDAYS[type]],
      value: +type,
    }))

  return (
    <Select
      type="select"
      name="weekday"
      id="select-weekday"
      placeholder=""
      value={weekdays.filter(
        option => option.value === value)}
      options={weekdays}
      onChange={({ value }) => onChange(value)}
      {...otherProps}
    />
  )
}

MessagesEventsSelectDay.propTypes = propTypes

export default withLang(MessagesEventsSelectDay)