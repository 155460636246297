import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { requestRead } from '../../../redux/actions/participantActions'
import { withLang } from '../../../utils/withLang'
import { getFullName } from '../../../utils/functions'
import Phone from '../../../components/Phone'
import Email from '../../../components/Email'
import DotsPreloader from '../../../components/DotsPreloader'
import Label from 'reactstrap/es/Label'
import FormGroup from 'reactstrap/es/FormGroup'

const propTypes = {
  onSelect: PropTypes.func,
}

const initialState = {
  opened: false,
  changed: false,
  search: '',
  isLastPage: false,
  isFetching: false,
  results: [],
}

class ParticipantSearch extends Component {
  componentRef = React.createRef()

  state = {
    ...initialState,
  }

  timeout = null

  componentWillMount () {
    document.addEventListener('mousedown', this.handleOutsideClick, false)
    document.addEventListener('mousedown', this.handleResultsScroll)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleOutsideClick, false)
    document.removeEventListener('mousedown', this.handleResultsScroll)

    clearTimeout(this.timeout)
  }

  handleChange = ({ target: { value } }) => {
    this.setState({
      search: value,
      results: [],
      changed: true,
      isLastPage: false,
    })

    clearTimeout(this.timeout)

    if (value) {
      this.fetch()
    }
  }

  handleFocus = () => {
    const {
      search,
      changed,
      opened,
    } = this.state

    if (search.trim() && changed && !opened) {
      this.setState({
        opened: true,
      })
    }
  }

  handleOutsideClick = (e) => {
    if (!this.componentRef.current.contains(e.target)) {
      this.setState({
        opened: false,
      })
    }
  }

  handleResultsScroll = e => {
    const {
      changed,
      isFetching,
      isLastPage,
    } = this.state

    if (!changed) {
      return
    }

    const isBottom = e.target.scrollHeight - e.target.scrollTop ===
      e.target.clientHeight

    if (isBottom && !isLastPage && !isFetching) {
      this.setState({
        page: this.state.page + 1,
      }, () => this.fetch())
    }
  }

  fetch = () => {
    if (this.state.search.trim() === '') {
      return
    }

    this.timeout = setTimeout(() => {
      const {
        search,
      } = this.state

      this.setState({
        isFetching: true,
      })

      requestRead({ search }).then(this.handleResponse).catch(
        () => this.setState({
          isFetching: false,
          isLastPage: true,
        }))

      clearTimeout(this.timeout)
    }, 600)
  }

  handleResponse = response => {
    this.setState({
      opened: true,
      results: !this.state.changed
        ? response.results
        : this.state.results.concat(response.results),
      isFetching: false,
    })
  }

  handleSelect = (p) => {
    this.setState({
      ...initialState,
    })

    this.props.onSelect(p)
  }

  render () {
    const { lang } = this.props
    const {
      search,
      opened,
      results,
      isFetching,
    } = this.state

    const className = 'participants-search' + (opened ? ' opened' : '')

    return (
      <div className={className} ref={this.componentRef}>
        <FormGroup>
          <Label>{lang.search}</Label>

          <Input
            value={search}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
          />
        </FormGroup>

        <div className="participants-search-dropdown">

          <div className="results" onScroll={this.handleResultsScroll}>
            {
              results.map(p => (
                <ul className="list-unstyled"
                    key={p.id}
                    onClick={() => this.handleSelect(p)}>
                  <li>
                    <b>{lang.full_name}</b>
                    <div className="long-line-safe" title={getFullName(p)}>
                      {getFullName(p)}
                    </div>
                  </li>
                  <li>
                    <b>{lang.email}</b>
                    <div className="long-line-safe ltr" title={p.email}>
                      <Email>{p.email}</Email>
                    </div>
                  </li>
                  <li>
                    <b>{lang.phone}</b>
                    <Phone>{p.phone}</Phone>
                  </li>
                </ul>
              ))
            }
          </div>

          {
            results.length === 0 && !isFetching && (
              <p className="text-muted text-center my-2">
                {lang.noResults}
              </p>
            )
          }

          {
            isFetching && <DotsPreloader/>
          }
        </div>
      </div>
    )
  }
}

ParticipantSearch.propTypes = propTypes

export default withLang(ParticipantSearch)