import React, { Component } from 'react'
import { connect } from 'react-redux'
import selectors from '../../redux/selectors'
import { withLang } from '../../utils/withLang'
import { MESSAGES_EVENTS } from '../../config/table-columns'
import ReactTable from 'react-table'
import PageTitle from '../../components/PageTitle'
import { ITEMS_PER_PAGE } from '../../config/constants'
import actions from '../../redux/actions'
import { setDocumentTitle } from '../../utils/functions'

const mapStateToProps = state => ({
  messagesEvents: selectors.messagesEvents.getAll(state),
  count: selectors.messagesEvents.getCount(state),
  isFetching: selectors.messagesEvents.getIsFetching(state),
})

class MessagesEventsHome extends Component {

  componentDidMount () {
    setDocumentTitle('Messages Events')
  }

  handleNewClick = () => {
    this.props.dispatch(actions.messagesEvents.openModalForm())
  }

  render () {
    const { messagesEvents, lang, isFetching } = this.props
    return (
      <div className="age-groups">

        <PageTitle
          title={lang.sending_message}
          icon="la la-calendar-o"
          onCreateClick={this.handleNewClick}
        />
        <ReactTable
          showPagination={false}
          data={messagesEvents}
          loading={isFetching}
          columns={MESSAGES_EVENTS}
          defaultPageSize={ITEMS_PER_PAGE}
          className="-striped -highlight"
        />

      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(MessagesEventsHome))