import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { withLang } from '../../utils/withLang'
import LineAwesome from '../LineAwesome'
import actions from '../../redux/actions'
import selectors from '../../redux/selectors'
import Logo from '../Logo'

const mapStateToProps = state => ({
  isActive: selectors.core.getLayout(state).isHeader,
  user: selectors.core.getAuth(state).user,
})

class AppHeader extends Component {
  constructor (props) {
    super(props)

    this.handleSignOutClick = this.handleSignOutClick.bind(this)
  }

  handleSignOutClick () {
    this.props.dispatch(actions.core.signOut())
  }

  renderManagerBlock () {
    const { user, lang } = this.props

    if (!user) return null

    return (
      <div className="app-header-manager">
        {lang.hi}, {user.first_name}!

        <Button
          color="icon"
          onClick={this.handleSignOutClick}>
          <LineAwesome icon="sign-out"/>
        </Button>
      </div>
    )
  }

  render () {
    const { isActive } = this.props

    return (
      <header
        className={`app-header${!isActive ? ' app-header_hidden' : ''}`}>
        <Logo/>

        <nav className="navbar navbar-static-top">
          {this.renderManagerBlock()}
        </nav>
      </header>
    )
  }
}

export default withRouter(withLang(connect(
  mapStateToProps,
)(AppHeader)))