export const CRUD_READ_PENDING = 'CRUD_PARTICIPANT_MESSAGES_READ_PENDING';
export const CRUD_READ_SUCCEEDED = 'CRUD_PARTICIPANT_MESSAGES_READ_SUCCEEDED';
export const CRUD_READ_FAILED = 'CRUD_PARTICIPANT_MESSAGES_READ_FAILED';

export const CRUD_UPDATE_SUCCEEDED = 'CRUD_PARTICIPANT_MESSAGES_UPDATE_SUCCEEDED';
export const CRUD_UPDATE_FAILED = 'CRUD_PARTICIPANT_MESSAGES_UPDATE_FAILED';

export const CRUD_CREATE_SUCCEEDED = 'CRUD_PARTICIPANT_MESSAGES_CREATE_SUCCEEDED';
export const CRUD_CREATE_FAILED = 'CRUD_PARTICIPANT_MESSAGES_CREATE_FAILED';

export const CRUD_DELETE_SUCCEEDED = 'CRUD_PARTICIPANT_MESSAGES_CREATE_SUCCEEDED';
export const CRUD_DELETE_FAILED = 'CRUD_PARTICIPANT_MESSAGES_CREATE_FAILED';
export const PARTICIPANT_MESSAGES_SET_PAGE = 'PARTICIPANT_MESSAGES_SET_PAGE';