import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'reactstrap'
import { Form as ReduxForm } from 'react-redux-form'
import { lang, withLang } from '../../utils/withLang'
import FormInput from '../../components/FormInput'
import validators from '../../utils/validators'
import { MESSAGES_EVENT_FORM_FILTERS } from '../../config/constants'

const mapStateToProps = state => ({
  messagesEvent: state.forms.messagesEvent,
  messagesEventType: state.forms.messagesEvent.event_type,

})

class MessagesEventsForm extends Component {

  defineSelectedFilter = (eventType) => {

    /** According to new instructions we need to hide everything what connected with MeetingStatusBasedSendingEvent.
     * When we need to return it back - delete and fix everything with phrase "ReturnMeetingStatusBasedSendingEvent"
     * */

    /** delete next line for ReturnMeetingStatusBasedSendingEvent*/
    return MESSAGES_EVENT_FORM_FILTERS[1]

    // create new MessagesEvents
    // if (!eventType) return null
    //
    // if (eventType === MESSAGES_EVENTS_TYPES[0]) {
    //   return MESSAGES_EVENT_FORM_FILTERS[0] // update MeetingStatusBasedSendingEvent
    // } else {
    //   return MESSAGES_EVENT_FORM_FILTERS[1] // update ParticipantStatusBasedSendingEvent
    // }

  }

  state = {
    selectedFilter: this.defineSelectedFilter(this.props.messagesEventType),
  }

  setForm = (filter) => {
    if (filter === MESSAGES_EVENT_FORM_FILTERS[0]) {
      return this.renderTimeRegardingForm()
    } else if (filter === MESSAGES_EVENT_FORM_FILTERS[1]) {
      return this.renderStatusChangedForm()
    } else
      return null
  }

  render () {
    const { lang } = this.props
    const {
      selectedFilter,
    } = this.state

    return (
      <div className="message-events-form">
        <Form
          tag={ReduxForm}
          model="forms.messagesEvent">
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <FormInput
                  type="text"
                  model=".name"
                  label={lang.name}
                  validators={{
                    required: validators.required,
                  }}
                  errorMessages={{
                    required: lang.errors.required_field,
                  }}
                />
              </div>
            </div>
            <hr/>
            {/** return commented code for ReturnMeetingStatusBasedSendingEvent*/}
            {/*<div className="text-center">*/}
            {/*  <ButtonGroup size="sm my-2">*/}
            {/*    {*/}
            {/*      MESSAGES_EVENT_FORM_FILTERS.map(filter => (*/}
            {/*          <Button color={selectedFilter === filter ?*/}
            {/*              'success' :*/}
            {/*              'secondary'}*/}
            {/*                  onClick={() => {*/}
            {/*                    this.setState({selectedFilter: filter});*/}
            {/*                    dispatch(customFormUpdate(*/}
            {/*                        'forms.messagesEvent.event_type',*/}
            {/*                        filter ===*/}
            {/*                        MESSAGES_EVENT_FORM_FILTERS[0] ?*/}
            {/*                            MESSAGES_EVENTS_TYPES[0] :*/}
            {/*                            MESSAGES_EVENTS_TYPES[1]*/}
            {/*                        , true));*/}
            {/*                  }}*/}
            {/*                  key={filter}*/}
            {/*          >*/}
            {/*            {lang[filter]}*/}
            {/*          </Button>*/}
            {/*      ))*/}
            {/*    }*/}
            {/*  </ButtonGroup>*/}
            {/*</div>*/}
            {this.setForm(selectedFilter)}
            <hr/>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <FormInput
                  type="message_type"
                  model=".template"
                  label={lang.message_type}
                  validators={{
                    required: validators.required,
                  }}
                  errorMessages={{
                    required: lang.errors.required_field,
                  }}
                />
              </div>
              {/*// Automatic messages will not be supported soon*/}
              {/*<div className="col-md-4">*/}
              {/*  <FormInput*/}
              {/*      type="checkbox"*/}
              {/*      model=".is_automatic"*/}
              {/*      label={lang.message_auto_send}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>

          </div>
        </Form>
      </div>
    )
  }

  renderTimeRegardingForm = () => {
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-md-9">
            <FormInput
              type="multi_participant_type"
              model=".participation_statuses"
              label={lang.participation_status}
              validators={{
                required: validators.required,
              }}
              errorMessages={{
                required: lang.errors.required_field,
              }}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-9">
            <FormInput
              type="meeting_status"
              model=".meeting_statuses"
              label={lang.meeting_status}
              validators={{
                required: validators.required,
              }}
              errorMessages={{
                required: lang.errors.required_field,
              }}
            />
          </div>
        </div>

        <div className="row justify-content-center">

          <div className="col-md-5">
            <FormInput
              type="when_send"
              model=".when"
              label={lang.when_send}
            />
          </div>


          <div className="col-md-3">
            <FormInput
              type="time"
              model=".time"
              label={lang.time}
            />
          </div>


        </div>

        <div className="row justify-content-center">


          <div className="col-md-4">
            <FormInput
              type="number"
              model=".offset_days"
              label={lang.days_before_meeting}
              errorMessages={{
                required: lang.errors.required_field,
              }}
            />
          </div>

          <div className="col-md-3">
            <FormInput
              type="weekdays"
              model=".weekday"
              label={lang.weekday}
            />
          </div>


        </div>
      </div>

    )
  }
  renderStatusChangedForm = () => {
    return (
      <div className="row justify-content-center">
        <div className="col-md-5">
          <FormInput
            type="participant_type"
            model=".trigger_status"
            label={lang.participation_status}
            validators={{
              required: validators.required,
            }}
            errorMessages={{
              required: lang.errors.required_field,
            }}
          />
        </div>
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(MessagesEventsForm))
