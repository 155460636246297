import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Button, Col, Form, Label} from 'reactstrap'
import { Form as ReduxForm } from 'react-redux-form'
import { withLang } from '../../../utils/withLang'
import FormInput from '../../../components/FormInput'
import validators from '../../../utils/validators'
import LineAwesome from "../../../components/LineAwesome";
import {sendCampaignMen, sendCampaignWomen, updateDailyGroup} from "../../../redux/actions/ageGroupActions";
import SelectEmailTemplate from '../../../components/SelectEmailTemplate'
import SelectClub from '../../../components/SelectClub'
import SelectMessageType from '../../../components/SelectMessageType'
import SelectBoolean from '../../../components/SelectBoolean'


const mapStateToProps = state => ({
  ageGroup: state.forms.ageGroup,
})

class AgeGroupForm extends Component {

  constructor (props) {
    super(props)

    this.state = {
      emailTemplateId: null,
      clubId: null,
      isCampaignTravelAbroadInterested: null
    }
  }

  handleUpdateDailyGroupClick = () => {
    const {ageGroup, dispatch } = this.props
    dispatch(updateDailyGroup(ageGroup.id))
  }

  handleSendCampaignWomenClick = () => {
    const {ageGroup, dispatch } = this.props
    const { emailTemplateId, messageTypeId, clubId, isCampaignTravelAbroadInterested } = this.state
    dispatch(sendCampaignWomen(ageGroup.id, emailTemplateId, messageTypeId, clubId, isCampaignTravelAbroadInterested))
  }

  handleSendCampaignMenClick = () => {
    const {ageGroup, dispatch } = this.props
    const { emailTemplateId, messageTypeId, clubId, isCampaignTravelAbroadInterested } = this.state
    dispatch(sendCampaignMen(ageGroup.id, emailTemplateId, messageTypeId, clubId, isCampaignTravelAbroadInterested))
  }

  handleEmailTemplateChange = emailTemplateId => {
    this.setState({ emailTemplateId })
  }

  handleMessageTypeChange = messageTypeId => {
    this.setState({ messageTypeId })
  }

  handleClubChange = clubId => {
    this.setState({ clubId })
  }

  handleIsCampaignTravelAbroadInterestedChange = isCampaignTravelAbroadInterested => {
    this.setState({ isCampaignTravelAbroadInterested })
  }

  render () {
    const { lang } = this.props
    const { emailTemplateId } = this.state
    const { messageTypeId } = this.state
    const { clubId } = this.state
    const { isCampaignTravelAbroadInterested } = this.state
    
    return (
      <Form
        tag={ReduxForm}
        model="forms.ageGroup">
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <FormInput
                type="text"
                model=".title"
                label={lang.name}
                validators={{
                  required: validators.required,
                }}
                errorMessages={{
                  required: lang.errors.required_field,
                }}
              />
            </div>
          </div>
          <div className="row">

            <div className="col-md-3">
              <FormInput
                type="number"
                model=".men_from"
                label={`${lang.men} ${lang.from}`}
                validators={{
                  required: validators.required,
                  number: validators.number,
                }}
                errorMessages={{
                  required: lang.errors.required_field,
                  number: lang.errors.non_int_value,
                }}
              />
            </div>

            <div className="col-md-3">
              <FormInput
                type="number"
                model=".men_to"
                label={`${lang.men} ${lang.to}`}
                validators={{
                  required: validators.required,
                  number: validators.number,
                }}
                errorMessages={{
                  required: lang.errors.required_field,
                  number: lang.errors.non_int_value,
                }}
              />
            </div>

            <div className="col-md-3">
              <FormInput
                type="number"
                model=".women_from"
                label={`${lang.women} ${lang.from}`}
                validators={{
                  required: validators.required,
                  number: validators.number,
                }}
                errorMessages={{
                  required: lang.errors.required_field,
                  number: lang.errors.non_int_value,
                }}
              />
            </div>

            <div className="col-md-3">
              <FormInput
                type="number"
                model=".women_to"
                label={`${lang.women} ${lang.to}`}
                validators={{
                  required: validators.required,
                  number: validators.number,
                }}
                errorMessages={{
                  required: lang.errors.required_field,
                  number: lang.errors.non_int_value,
                }}
              />
            </div>
          </div>
          <hr/>
          <div className="row justify-content-center">
              <Button
                type="submit"
                size="md"
                className="mb-2 mr-2"
                color="info"
                onClick={this.handleUpdateDailyGroupClick}>
                <LineAwesome icon="envelope"/>
                {lang.update_daily_group_for_age_group}
              </Button>
          </div>
          <hr/>
          <div className="row justify-content-center mb-3">
            <Col md={5}>
              <Label>{lang.email_template}</Label>
              <SelectEmailTemplate
               value={emailTemplateId}
                onChange={this.handleEmailTemplateChange}
              />
            </Col>
            <Col md={5}>
              <Label>{lang.campaign_travel_abroad_interested}</Label>
              <SelectBoolean
               value={isCampaignTravelAbroadInterested}
                onChange={this.handleIsCampaignTravelAbroadInterestedChange}
              />
            </Col>
            <Col md={5}>
              <Label>{lang.followup_message_templates} {lang.not_mandatory}</Label>
              <SelectMessageType
                value={messageTypeId}
                onChange={this.handleMessageTypeChange}
              />
            </Col>
            <Col md={5}>
              <Label>{lang.club} {lang.not_mandatory}</Label>
              <SelectClub
                value={clubId}
                onChange={this.handleClubChange}
              />
            </Col>
          </div>
          <div className="row justify-content-center">
              <Button
                type="submit"
                size="md"
                className="mb-2 mr-2"
                color="warning"
                onClick={this.handleSendCampaignWomenClick}>
                <LineAwesome icon="bullhorn"/>
                {lang.send_campaign_for_age_group_women}
              </Button>
              <Button
                type="submit"
                size="md"
                className="mb-2 mr-2"
                color="primary"
                onClick={this.handleSendCampaignMenClick}>
                <LineAwesome icon="bullhorn"/>
                {lang.send_campaign_for_age_group_men}
              </Button>
          </div>
          
        </div>
      </Form>
    )
  }
}

export default withLang(connect(mapStateToProps)(AgeGroupForm))