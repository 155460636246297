import React from 'react'
import PropTypes from 'prop-types'
import { withLang } from '../../utils/withLang'
import StatePreview from './components/MeetingStateTasksPreview'
import { formatDateTime } from '../../utils/functions'
import { connect } from 'react-redux'
import LineAwesome from '../../components/LineAwesome'
import selectors from '../../redux/selectors/index'
import ParticipantsStatTable from './components/MeetingParticipantsPreview'
import {
    MEETING_SCHEDULE_TYPE_PLANNED,
    MEETING_SCHEDULE_TYPE_SPONTANEOUS,
    MEETING_SCHEDULE_TYPES,
    MEETING_TYPE_REGULAR,
    MEETING_TYPES,
    MEETING_DAY_PART_MORNING,
    MEETING_DAY_PART_NOON,
    MEETING_DAY_PART_EVENING,
    MEETING_DAY_PARTS,
    MEETING_TYPE_WOMEN_ONLY,
    PARTICIPATION_STATUS_REGISTERED,
    PARTICIPATION_STATUS_CONFIRMED,
    PARTICIPATION_STATUS_ARRIVED,
    PARTICIPATION_STATUS_ANSWERED_QUESTIONNAIRE,
    MEETING_TYPE_TRAVEL_ABROAD,
    MEETING_TYPE_TRAVEL_LOCAL
} from "../../config/constants";
import { lang } from '../../utils/withLang';
import {Col, Row} from "reactstrap";

const mapStateToProps = (state, props) => {
  const meeting = selectors.collections.getEntity(state, 'meetings',
    props.meetingId)
  return {
    currentStage: selectors.settings.getStageById(state,
      meeting.current_status),
    restaurants: selectors.collections.getCollection(state, 'restaurants'),
    meeting,
    ageGroup: selectors.ageGroups.getById(state, meeting.age_group),
    tasksList: selectors.meetings.getTasksByStageId(meeting.tasks,
      meeting.current_status),
    stageIndex: selectors.settings.getIndexStageById(state,
      meeting.current_status),
  }
}

const propTypes = {
  meetingId: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

const MeetingCard = (props) => {

  const { meeting, lang, onClick, currentStage, tasksList } = props
  const cardClassName = 'card meeting-card' +
    (!currentStage ? ' meeting-card_completed' : '')

  return (
    <div className={cardClassName}
         onClick={() => onClick(meeting.id)}>
      <div className="card-body">
        {renderHeader(props)}

        <div className="meeting-card-content">
          {/*<StatePreview*/}
          {/*  state={meeting.state}*/}
          {/*  tasksList={tasksList}*/}
          {/*/>*/}

          <div
            className="meeting-card-content__block meeting-participiants">
            <h3 className="meeting-card__sub-title">
              <LineAwesome icon="users"/> {lang.people}
            </h3>

            <ParticipantsStatTable
              participants={meeting.participants}
            />
          </div>
        </div>
      </div>
    </div>)
}

const renderHeader = (props) => {
  const {
    meeting,
    restaurants,
    ageGroup,
    currentStage,
  } = props

  const restaurant = restaurants[meeting.restaurant.toString()]

  const meeting_schedule_type_icon =
  meeting.meeting_schedule_type == MEETING_SCHEDULE_TYPE_PLANNED ? "calendar-check-o" : "flash"

  const meeting_day_part_icon =
  meeting.meeting_day_part == MEETING_DAY_PART_EVENING? "glass" :
      meeting.meeting_day_part == MEETING_DAY_PART_NOON ? "sun-o" : "coffee"

  const meeting_type_icon =
  meeting.meeting_type == MEETING_TYPE_REGULAR? "group" :
      meeting.meeting_type == MEETING_TYPE_WOMEN_ONLY ? "female" : 
        meeting.meeting_type == MEETING_TYPE_TRAVEL_ABROAD ? "plane" :
            meeting.meeting_type == MEETING_TYPE_TRAVEL_LOCAL ? "tree" : ""

  const status_that_count = [PARTICIPATION_STATUS_REGISTERED,
                                PARTICIPATION_STATUS_CONFIRMED,
                                PARTICIPATION_STATUS_ARRIVED,
                                PARTICIPATION_STATUS_ANSWERED_QUESTIONNAIRE]
  const status_full_icon =
      meeting.is_full ? "battery-full" :
          meeting.participants.some(item => status_that_count.includes(item.status)) ?
              "battery-quarter" : "battery-empty"

  return (
    <div className="meeting-card-header">
      <div className="meeting-card-header-title" style={getBackground(props)}>
        {getTitle(props)}

      </div>

      <div className="meeting-card-header-datetime">
        <LineAwesome icon="tag"/> {meeting.id + "   "}
        <i className="la la-calendar"/> {formatDateTime(meeting.date_time)}
      </div>

      <div className="meeting-card-meta">
      <Row className="justify-content-center">
      <Col>
        <div className="meeting-card-meta__item">
          <LineAwesome icon="cutlery"/>
          {restaurant ? restaurant.title : '-'}
        </div>
      </Col>
          <Col>
              <div className="meeting-card-meta__item">
                <LineAwesome icon={status_full_icon}/>
                {lang.status_full}
              </div>
           </Col>



          <Col>
        <div className="meeting-card-meta__item">
          <LineAwesome icon="child"/>
          {ageGroup.title}
        </div>
        </Col>
      </Row>
      </div>
        <div className="meeting-card-meta">

      <Row className="justify-content-center">

          <Col>
          <div className="meeting-card-meta__item" >
            <LineAwesome icon={meeting_type_icon}/>
            {lang[MEETING_TYPES[meeting.meeting_type]]}

          </div>
        </Col>

          <Col>
              <div className="meeting-card-meta__item">
                <LineAwesome icon={meeting_day_part_icon}/>
                {lang[MEETING_DAY_PARTS[meeting.meeting_day_part]]}
              </div>
           </Col>

          <Col>
          <div className="meeting-card-meta__item">
            <LineAwesome icon={meeting_schedule_type_icon}/>
            {lang[MEETING_SCHEDULE_TYPES[meeting.meeting_schedule_type]]}
          </div>
        </Col>
      </Row>
      </div>

    </div>
  )
}

const getTitle = (props) => {
  const { lang, meeting, currentStage, stageIndex } = props

  if (meeting.is_archived) {
      return (
      <h2 style={{ color: currentStage ? '#fff' : '' }}>
        {lang.archived_meeting} &nbsp;
      </h2>
    )
  }

  if (meeting.is_draft) {
      return (
          <h2 style={{color: '#fff'}}>
              {lang.duplicated_meeting} &nbsp;
          </h2>
      )
  }

  if (!meeting.is_taking_place) {
      return (
          <h2 style={{color: '#fff'}}>
              {lang.not_taking_place_meeting} &nbsp;
          </h2>
      )
  }

  if (meeting.is_to_be_notice) {
      return (
          <h2 style={{color: '#fff'}}>
              {lang.to_be_notice_meeting} &nbsp;
          </h2>
      )
  }

  if (currentStage) {
    return (
      <h2 style={{ color: currentStage ? '#fff' : '' }}>
        {currentStage.name} &nbsp;
        <small>#{stageIndex + 1}</small>
      </h2>
    )
  }

  return (
    <h2>
      {lang.management_is_finished} &nbsp;
      <LineAwesome icon="flag-checkered"/>
    </h2>
  )
}

const getBackground = (props) => {
  const { lang, meeting, currentStage, stageIndex } = props

  if (meeting.is_archived) {
      return (
       {background: '#e14343'}
    )
  }

  if (meeting.is_draft) {
      return (
          {background: '#16a2b8'}
      )
    }

  if (!meeting.is_taking_place) {
      return (
          {background: '#e17543'}
      )
    }

    if (meeting.is_to_be_notice) {
      return (
          {background: '#ff9902'}
      )
  }

  if (currentStage) {
    return (
      {background: currentStage.color}
    )
  }

  return (
      {background: ''}
  )
}

MeetingCard.propTypes = propTypes

export default withLang(connect(mapStateToProps)(MeetingCard))