import React from 'react';
import { lang } from '../utils/withLang';
import ReactTableEditCreateButtons
	from '../components/ReactTableEditCreateButtons';
import Phone from '../components/Phone';
import moment from 'moment';
import {
	ENTITY_AGE_GROUPS,
	ENTITY_AGE_RANGES,
	ENTITY_MEETINGS_MESSAGES,
	ENTITY_MESSAGE_EVENTS,
	ENTITY_MESSAGE_TYPES, ENTITY_PARTICIPANT_MESSAGES,
	ENTITY_PARTICIPANTS,
	ENTITY_RESTAURANTS,
	ENTITY_CITIES,
	GENDER_MAN,
	GENDER_WOMAN,
	MESSAGES_EVENTS_TYPES, ENTITY_EMAIL_TEMPLATES,
} from './constants';
import MeetingsMessageSendButton
	from '../modules/meetings/components/MeetingsMessageSendButton';
import PopoverLongText from '../components/PopoverLongText';
import MessagesTypesById
	from '../modules/messages-types/components/MessagesTypesById';
import ParticipantStatusById
	from '../modules/messages-events/components/MessagesEventsRestInformation';
import MessagesEventsById
	from '../modules/messages-events/components/MessagesEventsById';
import ParticipantNameById
	from '../modules/participants/components/ParticipantNameById';
import ParticipantMessagesStatus
	from '../modules/participants/components/ParticipantMessagesStatus';

export const PARTICIPANTS_COLUMNS = [
	{
		Header: lang.first_name,
		accessor: 'first_name',
	},
	{
		Header: lang.last_name,
		accessor: 'last_name',
	},
	{
		id: 'email',
		Header: lang.email,
		accessor: 'email',
	},
	{
		Header: lang.is_email_verified,
		Cell: p => p.original.is_email_verified ? lang.yes : lang.no,
	},
	{
		id: 'gender',
		Header: lang.gender,
		accessor: p => p.gender === GENDER_MAN ? lang.man : lang.woman,
	},
	{
		id: 'city',
		Header: lang.city,
		accessor: 'city',
	},
	{
		Header: lang.phone,
		Cell: p => <Phone>{p.original.phone}</Phone>,
	},
	{
		Header: lang.is_phone_verified,
		Cell: p => p.original.is_phone_verified ? lang.yes : lang.no,
	},
	{
		id: 'next_meeting',
		Header: lang.next_meeting,
		Cell: p => p.original.last_event_name ? lang.yes : lang.no,
	},
	{
		id: 'buttons',
		Header: lang.manage,
		accessor: 'interested_in_age_groups',
		Cell: p => (
			<ReactTableEditCreateButtons
				id={p.original.id}
				entity={ENTITY_PARTICIPANTS}
			/>
		),
	},
];

export const PARTICIPANT_CONTACTS_COLUMNS = [
	{
		Header: lang.first_name,
		accessor: 'first_name',
		filterable: true,
	},
	{
		Header: lang.last_name,
		accessor: 'last_name',
		filterable: true,
	},
	{
		id: 'gender',
		Header: lang.gender,
		filterable: true,
		accessor: p => p.gender === GENDER_MAN ? lang.man : lang.woman,
		filterMethod: (filter, row) => !filter.value || row._original.gender ===
			+filter.value,
		Filter: ({ filter, onChange }) => (
			<select
				onChange={event => onChange(event.target.value)}
				style={{ width: '100%' }}
				value={filter ? filter.value : 'all'}
			>
				<option value="">{lang.all}</option>
				<option value={GENDER_MAN}>{lang.man}</option>
				<option value={GENDER_WOMAN}>{lang.woman}</option>
			</select>
		),
	},
	{
		Header: lang.city,
		filterable: true,
		accessor: 'city',
	},
	{
		Header: lang.phone,
		filterable: false,
		Cell: p => <Phone>{p.original.phone}</Phone>,
	},
];

export const PARTICIPANT_RESTAURANTS_COLUMNS = [
	{
		Header: lang.first_name,
		accessor: 'first_name',
	},
	{
		Header: lang.last_name,
		accessor: 'last_name',
	},
];

export const RESTAURANTS_COLUMNS = [
	{
		Header: lang.title,
		accessor: 'title',
	},
	{
		Header: lang.address,
		accessor: 'address',
		filterable: false,
	},
	{
		Header: lang.phone,
		filterable: false,
		Cell: p => <Phone>{p.original.phone}</Phone>,
	},
	{
		Header: `${lang.contact_person} - ${lang.first_name}`,
		accessor: 'contact_person[0].first_name',
		filterable: false,
	},
	{
		Header: `${lang.contact_person} - ${lang.phone}`,
		filterable: false,
		Cell: p => Array.isArray(p.original.contact_person) &&
		p.original.contact_person[0]
			? <Phone>{p.original.contact_person[0].phone}</Phone>
			: null,
	},
	{
		Header: `${lang.contact_person} - ${lang.position}`,
		accessor: 'contact_person[0].position',
		filterable: false,
	},
	{
		Header: `${lang.contact_person} - ${lang.note}`,
		accessor: 'contact_person[0].note',
		filterable: false,
	},
	{
		id: 'is_receive_messages',
		Header: `${lang.contact_person} - ${lang.is_receive_messages}`,
		Cell: p => Array.isArray(p.original.contact_person) &&
		p.original.contact_person[0] &&
		p.original.contact_person[0].is_receive_messages
			? lang.receive
			: lang.no_receive,
		filterable: false,
	},
	{
		id: 'buttons',
		Header: lang.manage,
		filterable: false,
		accessor: 'interested_in_age_groups',
		Cell: p => <ReactTableEditCreateButtons id={p.original.id}
												entity={ENTITY_RESTAURANTS}/>,
	},
];

export const AGE_GROUPS_COLUMNS = [
	{
		Header: `${lang.title}`,
		accessor: 'title',
	},
	{
		Header: `${lang.men} ${lang.from}`,
		accessor: 'men_from',
	},
	{
		Header: `${lang.men} ${lang.to}`,
		accessor: 'men_to',
	},
	{
		Header: `${lang.women} ${lang.from}`,
		accessor: 'women_from',
	},
	{
		Header: `${lang.women} ${lang.to}`,
		accessor: 'women_to',
	},
	{
		id: 'buttons',
		Header: lang.manage,
		filterable: false,
		Cell: p => (
			<ReactTableEditCreateButtons
				id={p.original.id}
				entity={ENTITY_AGE_GROUPS}
			/>
		),
	},
];

export const MESSAGES_EVENTS = [
	{
		Header: `${lang.event_name}`,
		accessor: 'name',
	},
	{
		Header: `${lang.event_conditions}`,
		accessor: 'event_type',
		Cell: p => {
			return p.original.event_type === MESSAGES_EVENTS_TYPES[0] ?
				lang.time_regarding_meeting :
				lang.participant_status_changed;
		},
	},
	{
		Header: `${lang.event_knowledge}`,
		accessor: 'participation_statuses',
		Cell: p => <ParticipantStatusById
			original={p.original}
		/>,
	},
	{
		Header: `${lang.message_name}`,
		accessor: 'template',
		Cell: p => <MessagesTypesById
			id={p.original.template}
		/>,
	},
	// Automatic messages will not be supported soon
	// {
	//   Header: `${lang.message_auto_send}`,
	//   accessor: 'message_auto_send',
	//   className: 'task-check_centered',
	//   Cell: p => <Checkbox
	//       id={p.original.id}
	//       status={+p.original.message_auto_send}
	//   />,
	// },
	{
		id: 'buttons',
		Header: lang.manage,
		filterable: false,
		Cell: p => (
			<ReactTableEditCreateButtons
				id={p.original.id}
				entity={ENTITY_MESSAGE_EVENTS}
			/>
		),
	},
];

export const MESSAGES_TYPES = [
	{
		Header: `${lang.message_name}`,
		accessor: 'name',
	},
	{
		Header: `${lang.content_for_men}`,
		accessor: 'text_men',
		Cell: p => <PopoverLongText
			text={p.original.text_men}
			id={'content_for_men_' + p.original.id}
			previewLength={40}
		/>,
	},
	{
		Header: `${lang.content_for_women}`,
		accessor: 'text_women',
		Cell: p => <PopoverLongText
			text={p.original.text_women}
			id={'content_for_women_' + p.original.id}
			previewLength={40}
		/>,
	},
	{
		Header: `${lang.campaign_followup_template}`,
		Cell: p => p.original.is_campaign_followup ? lang.yes : lang.no,
	},
	{
		Header: `${lang.send_for_unsubscribed}`,
		Cell: p => p.original.send_for_unsubscribed ? lang.yes : lang.no,
	},
	{
		id: 'buttons',
		Header: lang.edit_message,
		filterable: false,
		Cell: p => (
			<ReactTableEditCreateButtons
				id={p.original.id}
				entity={ENTITY_MESSAGE_TYPES}
			/>
		),
	},
];

export const MEETINGS_MESSAGES = [
	{
		Header: `${lang.event_sending}`,
		//accessor: 'sending_event',
		Cell: p => <MessagesEventsById
			id={p.original.sending_event}
			is_auto={p.original.is_auto_send}
		/>,
	},
	{
		Header: `${lang.message_name}`,
		accessor: 'template',
		Cell: p => <MessagesTypesById
			id={p.original.template}
		/>,
	},
	{
		Header: `${lang.addressee}`,

		Cell: p => p.original.recipient
			? <ParticipantNameById
			id={p.original.recipient}
			/>
			: p.original.recipient_name
				? <span>
        	{p.original.recipient_name}
      		</span>
		: null,
	},
	{
		Header: `${lang.message_content}`,
		accessor: 'text',
		Cell: p => <PopoverLongText
			text={p.original.text}
			id={'content_for_men_' + p.original.id}
			previewLength={10}
		/>,
	},
	{
		Header: `${lang.time_sending}`,
		accessor: 'sent_at',
		Cell: p => p.original.sent_at ?
			`${moment(p.original.sent_at).format('lll')}` :
			`${lang.did_not_send}`,
		width: 150
	},
	{
		id: 'buttons',
		Header: lang.send,
		filterable: false,
		Cell: p => (
			<MeetingsMessageSendButton
				status={p.original.status}
				id={p.original.id}
				entity={ENTITY_MEETINGS_MESSAGES}
			/>
		),
	},
	{
		Header: `${lang.time_scheduled}`,
		accessor: 'scheduled_for',
		Cell: p => p.original.scheduled_for ?
			`${moment(p.original.scheduled_for).format('lll')}` :
			`${lang.did_not_send}`,
		width: 150

	},
];

export const PARTICIPANT_MESSAGES = [
	{
		Header: `${lang.addressee}`,
		accessor: 'recipient',
		Cell: p => <ParticipantNameById
			id={p.original.recipient}
		/>,
	},
	{
		Header: `${lang.message_content}`,
		accessor: 'text',
		Cell: p => <PopoverLongText
			text={p.original.text}
			id={'content_for_men_' + p.original.id}
			previewLength={20}
		/>,
	},
	{
		Header: `${lang.time_sending}`,
		accessor: 'sent_at',
		Cell: p => p.original.sent_at ?
			`${moment(p.original.sent_at).format('lll')}` :
			`${lang.did_not_send}`,
	},
	{
		id: 'status',
		Header: lang.status,
		filterable: false,
		Cell: p => (
			<ParticipantMessagesStatus
				status={p.original.status}
				id={p.original.id}
				entity={ENTITY_PARTICIPANT_MESSAGES}
			/>
		),
	},
	{
		Header: `${lang.time_scheduled}`,
		accessor: 'scheduled_for',
		Cell: p => p.original.scheduled_for ?
			`${moment(p.original.scheduled_for).format('lll')}` :
			`${lang.did_not_send}`,
	},
];

export const FILL_UP_MESSAGES = [
	{
		Header: `${lang.time_arrow_sent}`,
		Cell: `${lang.time_arrow_sent}`,
	},
	{
		Header: `${lang.criteria}`,
		Cell: `${lang.first_arrow}`,
	},
	{
		Header: `${lang.sent_count}`,
		Cell: `${lang.sent_count}`,
	},
	{
	},
	{
	},
]

export const EMAIL_TEMPLATES = [
	{
		Header: `${lang.template_name}`,
		accessor: 'name',
	},
	{
		Header: `${lang.id_for_men}`,
		accessor: 'at_template_id_men',
	},
	{
		Header: `${lang.id_for_women}`,
		accessor: 'at_template_id_women',
	},
	{
		Header: `${lang.campaign_template}`,
		Cell: p => p.original.is_campaign ? lang.yes : lang.no,
	},
	{
		id: 'buttons',
		Header: lang.edit_template,
		filterable: false,
		Cell: p => (
			<ReactTableEditCreateButtons
				id={p.original.id}
				entity={ENTITY_EMAIL_TEMPLATES}
			/>
		),
	},
];