import selectors from './index'
import { ENTITY_MESSAGE_EVENTS } from '../../config/constants'

export const getIds = state => state.messagesEvents.ids
export const getAll = state => state.messagesEvents.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_MESSAGE_EVENTS, id))
export const getById = (
  state, id) => state.resources[ENTITY_MESSAGE_EVENTS]['' +
id]
export const getPage = state => state.messagesEvents.page
export const getCount = state => state.messagesEvents.count
export const getIsFetching = state => state.messagesEvents.isFetching
export const getSearch = state => state.messagesEvents.search