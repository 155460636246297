import React, { Component } from 'react'
import { connect } from 'react-redux'
import selectors from '../../redux/selectors'
import { withLang } from '../../utils/withLang'
import { MESSAGES_TYPES } from '../../config/table-columns'
import ReactTable from 'react-table'
import PageTitle from '../../components/PageTitle'
import { ITEMS_PER_PAGE } from '../../config/constants'
import actions from '../../redux/actions'
import { setDocumentTitle } from '../../utils/functions'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  messagesTypes: selectors.messagesTypes.getAll(state),
  isFetching: selectors.messagesTypes.getIsFetching(state),
})

class MessagesTypesHome extends Component {

  handleNewClick = () => {
    this.props.dispatch(actions.messagesTypes.openModalForm())
  }

  componentDidMount () {
    setDocumentTitle('Messages Types')
  }

  render () {
    let { messagesTypes, lang, isFetching } = this.props

    return (
      <div className="age-groups">

        <PageTitle
          title={lang.event_type}
          icon="la la-envelope"
          onCreateClick={this.handleNewClick}
        />

        <ReactTable
          showPagination={false}
          data={messagesTypes}
          columns={MESSAGES_TYPES}
          defaultPageSize={messagesTypes.length}
          className="-striped -highlight"
          loading={isFetching}
        />

      </div>
    )
  }
}

export default withRouter(
  withLang(connect(mapStateToProps)(MessagesTypesHome)))