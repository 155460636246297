import React, { Component } from 'react'
import { connect } from 'react-redux'
import selectors from '../../../redux/selectors'
import { withLang } from '../../../utils/withLang'
import { AGE_GROUPS_COLUMNS } from '../../../config/table-columns'
import ReactTable from 'react-table'
import PageTitle from '../../../components/PageTitle'
import { ITEMS_PER_PAGE } from '../../../config/constants'
import actions from '../../../redux/actions'

const mapStateToProps = state => ({
  ageGroups: selectors.ageGroups.getAll(state),
})

class AgeGroupsHome extends Component {

  handleNewClick = () => {
    this.props.dispatch(actions.ageGroups.openModalForm())
  }

  render () {
    const { ageGroups, lang } = this.props

    return (
      <div className="age-groups">
        <PageTitle
          title={lang.age_groups}
          icon="child"
          onCreateClick={this.handleNewClick}
        />

        <ReactTable
          showPagination={false}
          data={ageGroups}
          columns={AGE_GROUPS_COLUMNS}
          defaultPageSize={ageGroups.length}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(AgeGroupsHome))