import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { PARTICIPANT_RESTAURANTS_COLUMNS } from '../../../config/table-columns'
import { ITEMS_PER_PAGE } from '../../../config/constants'

const mapStateToProps = state => ({
  restaurants: state.forms.participant.restaurants
})

class ParticipantRestaurants extends Component {
  render () {
    const { restaurants } = this.props

    return (
      <div>
        <ReactTable
          data={restaurants}
          loading={!restaurants}
          defaultPageSize={ITEMS_PER_PAGE}
          showPagination={restaurants && restaurants.length > ITEMS_PER_PAGE}
          columns={PARTICIPANT_RESTAURANTS_COLUMNS}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
)(ParticipantRestaurants)