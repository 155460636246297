import React, { useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import ActionEventsMeeting from './ActionEventsMeeting';
import { lang } from '../../../utils/withLang';
import Tabs from '../../../components/Tabs';
import { filterActionEvents } from '../functions';
import { WHEN_MEETING_TYPES } from '../../../config/constants';

const renderMeetings = (actionEvents) => {
	return actionEvents.map(({ meeting, events }) => {

		if (!meeting && !events) return null;

		const reversedEvents = [...events].reverse();

		return <ActionEventsMeeting
			key={meeting.id}
			id={meeting.id}
			type={meeting.type}
			date={meeting.date_time}
			restaurant={meeting.restaurant}
			events={reversedEvents}
		/>;
	});
};

const ParticipantActionEvents = ({ actionEvents }) => {

	const [activeTab, setActiveTab] = useState(WHEN_MEETING_TYPES.all);

	const tabs = [
		{
			title: lang.all_meetings,
			label: WHEN_MEETING_TYPES.all,
			component: null,
		},
		{
			title: lang.future_meetings,
			label: WHEN_MEETING_TYPES.future,
			component: null,
		},
		{
			title: lang.past_meetings,
			label: WHEN_MEETING_TYPES.past,
			component: null,
		},
	];

	const filteredActionEvents = useMemo(() => filterActionEvents(actionEvents, activeTab),[actionEvents, activeTab]) || [];

	if (!actionEvents || !actionEvents.length) {
		return <h4>{lang.no_info}</h4>;
	}

	return (
		<div>
			<Tabs
				tabs={tabs}
				activeTab={activeTab}
				onTabClick={tabLabel => setActiveTab(tabLabel)}
				isEmptyContent={true}
			/>
			{
				renderMeetings(filteredActionEvents)
			}
		</div>
	);
};

const mapStateToProps = state => ({
	actionEvents: state.forms.participant.actionEvents,
});

export default connect(
	mapStateToProps,
)(ParticipantActionEvents);
