import selectors from './index'
import { ENTITY_MESSAGE_TYPES } from '../../config/constants'

export const getAll = state => state.messagesTypes.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_MESSAGE_TYPES, id))
export const getCampaignTemplatesOnly = state => state.messagesTypes.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_MESSAGE_TYPES, id))
  .filter(template => template.is_campaign_followup == true)
export const getById = (state, id) => state.resources[ENTITY_MESSAGE_TYPES]['' +
id]
export const getIds = state => state.messagesTypes.ids
export const getCount = state => state.messagesTypes.count
export const getIsFetching = state => state.messagesTypes.isFetching
export const getPage = state => state.messagesTypes.page