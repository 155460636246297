import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return ({
    participants: state.forms.meeting.participants,
  })
}

// Component for react-table: accepts ID participant  -> return Name of participant
const ParticipantNameById = (props) => {

  const { participants, id: participantId } = props

  const participant = participants.find((obj) => {
    return participantId === obj.participant_id
  })

  if (!participant) {
    return '-'
  }
  return (
    <span>
        {participant.first_name} {participant.last_name}
      </span>
  )
}

export default connect(mapStateToProps)(ParticipantNameById)
