import React, { Component } from 'react'
import RestaurantsHome from './RestaurantsHome'

class RestaurantsModule extends Component {
  render () {
    return (
      <div id="module-restaurants">
        <RestaurantsHome/>
      </div>
    )
  }
}

export default RestaurantsModule