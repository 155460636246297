import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLang } from '../../../utils/withLang'
import { MEETINGS_MESSAGES } from '../../../config/table-columns'
import ReactTable from 'react-table'
import { ITEMS_PER_PAGE, MODAL_MEETING_MANUALLY_MESSAGE_MANAGE, MODAL_MEETING_RESTAURANT_MANUALLY_MESSAGE_MANAGE, } from '../../../config/constants'
import actions from '../../../redux/actions'
import Pagination from '../../../components/Pagination'
import { withRouter } from 'react-router-dom'
import LineAwesome from '../../../components/LineAwesome'
import { Button } from 'reactstrap'
import selectors from '../../../redux/selectors'
import { setPage } from '../../../redux/actions/meetingsMessagesActions'
import {createSMSFillUpGroup} from "../../../redux/actions/meetingActions";

const mapStateToProps = state => ({
  meetingsMessages: selectors.meetingsMessages.getAll(state),
  count: selectors.meetingsMessages.getCount(state),
  isFetching: selectors.meetingsMessages.getIsFetching(state),
  meetingId: state.forms.meeting.id,
})

class MeetingMessages extends Component {
  state = {
    page: 1,
  }

  handleClickSendManually = () => {
    const { dispatch, lang } = this.props
    dispatch(actions.core.openModalForm({
      title: lang.send_manually,
      withoutFooter: true,
      component: MODAL_MEETING_MANUALLY_MESSAGE_MANAGE,
      form: 'manuallyMessage',
      isNew: true,
    }, true))
  }

    handleClickSendManuallyRestaurant = () => {
    const { dispatch, lang } = this.props
    dispatch(actions.core.openModalForm({
      title: lang.send_manually_restaurant,
      withoutFooter: true,
      component: MODAL_MEETING_RESTAURANT_MANUALLY_MESSAGE_MANAGE,
      form: 'manuallyMessageRestaurant',
      isNew: true,
    }, true))
  }

  handleCreateSMSFillUpGroupClick = () => {
    const { meetingId, dispatch } = this.props
    dispatch(createSMSFillUpGroup(meetingId))
  }

  componentDidMount () {
    this.fetch(1)
  }

  componentWillUnmount () {
    this.props.dispatch(setPage(1))
  }

  handlePageChange = page => {
    this.setState({ page })
    this.props.dispatch(setPage(page))
    this.fetch(page)
  }

  handleClickRefresh = page => {
    this.fetch(page)
  }

  fetch (page) {
    const { dispatch, meetingId: id } = this.props

    //if it`s new created meeting we don`t need to read messages
    if (id) {
      dispatch(
        actions.meetingsMessages.crudRead({ page, id }),
      )
    }
  }

  render () {
    let { meetingsMessages, lang, count, isFetching } = this.props

    const Pag = () => (
      <Pagination
        onChange={this.handlePageChange}
        page={this.state.page}
        totalItems={count}
        isFetching={isFetching}
      />
    )

    return (
      <div className="age-groups">
        <div className='row justify-content-between'>
          <div>
          <Button
            color="info"
            size="md"
            className="mb-2 mr-2"
            onClick={this.handleClickSendManually}>
            <LineAwesome icon="pencil"/>
            {lang.send_manually}
          </Button>
          <Button
            color="info"
            size="md"
            className="mb-2 mr-2"
            onClick={this.handleClickSendManuallyRestaurant}>
            <LineAwesome icon="pencil"/>
            {lang.send_manually_restaurant}
          </Button>
          <Button
            type="submit"
            size="md"
            className="mb-2 mr-2"
            color="info"
            onClick={this.handleCreateSMSFillUpGroupClick}>
            <LineAwesome icon="envelope"/>
            {lang.create_sms_fill_up_group}
		  </Button>
            </div>
          <div
            color="success"
            className="ml-5 mt-2 btn-icon btn-refresh"
            onClick={() => {
              this.handleClickRefresh(this.state.page)
            }}>
            <LineAwesome icon="refresh"/>
          </div>
        </div>
        <ReactTable
          showPagination={false}
          data={meetingsMessages}
          columns={MEETINGS_MESSAGES}
          defaultPageSize={ITEMS_PER_PAGE}
          className="-striped -highlight"
          noDataText={isFetching ? `${lang.loading}...` : lang.no_info}
          style={{width: "750px"}}
        />

        <Pag/>
      </div>
    )
  }
}

export default withRouter(withLang(connect(mapStateToProps)(MeetingMessages)))