import actions from './index'
import { lang } from '../../utils/withLang'
import { ENDPOINTS, ENTITY_EMAIL_TEMPLATES, MODAL_EMAIL_TEMPLATES_MANAGE, } from '../../config/constants'
import { customFormUpdate } from './coreActions'
import selectors from '../selectors'
import actionTypes from '../action-types'
import { disassembleResults, setUrlParams } from '../../utils/functions'
import { storeEntities } from './resourceActions'
import axios from 'axios'
import { toast } from 'react-toastify'
import { generatePath } from 'react-router-dom'

/***********************
 * REQUESTS
 */

export const requestRead = options => (
  axios.get(setUrlParams(ENDPOINTS.EMAIL_TEMPLATES, options)).then(
    response => response.data)
)

export const requestCreate = emailTemplate => (
  axios.post(ENDPOINTS.EMAIL_TEMPLATES, { ...emailTemplate }).then(
    response => response.data)
)

export const requestUpdate = emailTemplate => (
  axios.patch(
    generatePath(ENDPOINTS.EMAIL_TEMPLATE, { id: emailTemplate.id }),
    { ...emailTemplate },
  )
)

export const requestDelete = id => {
  return (
    axios.delete(generatePath(ENDPOINTS.EMAIL_TEMPLATE, { id }))
  )
}

/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = (options = {}) => (dispatch, getState) => {

  dispatch(crudReadPending())
  return requestRead(options).then(response => {
    dispatch(crudReadSucceeded(response))
    return response.data
  })
}

export const crudReadPending = () => ({
  type: actionTypes.emailTemplates.CRUD_READ_PENDING,
})

export const crudReadSucceeded = data => dispatch => {
  const results = disassembleResults(data, ENTITY_EMAIL_TEMPLATES)
  dispatch(storeEntities(results.entities))
  dispatch({
    type: actionTypes.emailTemplates.CRUD_READ_SUCCEEDED,
    payload: results,
  })
}

/** READ */

/** CREATE */
export const crudCreate = emailTemplate => dispatch => (
  requestCreate(emailTemplate).then(
    ({ data: createdEmailTemplate }) => {
      toast.success(lang.successfully_created)
      dispatch(crudCreateSucceeded(createdEmailTemplate))
      dispatch(crudRead())
      return createdEmailTemplate
    })
)

export const crudCreateSucceeded = result => ({
  type: actionTypes.emailTemplates.CRUD_UPDATE_SUCCEEDED,
  payload: { ...result },
})/** CREATE */

/** UPDATE */
export const crudUpdate = emailTemplate => dispatch => (
  requestUpdate(emailTemplate).then(response => {
    toast.success(lang.successfully_updated)
    dispatch(crudUpdateSucceeded(response.data))
    dispatch(crudRead())
    return response.data
  })
)

export const crudUpdateSucceeded = emailTemplate => ({
  type: actionTypes.emailTemplates.CRUD_UPDATE_SUCCEEDED,
  payload: { ...emailTemplate },
})/** UPDATE */

/** DELETE */

export const crudDelete = emailTemplate => dispatch => {
  return (
    requestDelete(
      typeof emailTemplate === 'object' ? emailTemplate.id : emailTemplate).then(() => {
      toast.success(lang.successfully_deleted)
      dispatch(crudDeleteSucceeded())
      dispatch(crudRead())
    })
  )
}

export const crudDeleteSucceeded = () => ({
  type: actionTypes.emailTemplates.CRUD_DELETE_SUCCEEDED,
})

export const crudDeleteFailed = () => ({
  type: actionTypes.emailTemplates.CRUD_DELETE_FAILED,
})
/** DELETE */

/***********************
 MANAGE
 **********************/

export const openModalForm = (emailTemplateId = null) => (
  dispatch, getState) => {
  if (emailTemplateId) {
    const emailTemplate = selectors.emailTemplates.getById(getState(),
      emailTemplateId)
    dispatch(customFormUpdate('forms.emailTemplate', emailTemplate, true))
  }
  dispatch(actions.core.openModalForm({
    title: lang[!emailTemplateId ? 'new_email_template' : 'edit_email_template'],
    component: MODAL_EMAIL_TEMPLATES_MANAGE,
    form: 'emailTemplate',
    isNew: !emailTemplateId,
  }))
}