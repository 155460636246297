import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import { withLang } from '../../../utils/withLang'

const propTypes = {
  meetingId: PropTypes.number.isRequired,
}

class MeetingRegLink extends Component {
  render () {
    const {
      meetingId,
      lang,
    } = this.props

    const regLinkPattern = process.env.REACT_APP_REG_LINK

    if (!regLinkPattern)
      return (
        <p className="text-danger text-center">
          There is no pattern for registration link. Please, check server configuration.
        </p>
      )

    const meetingRegLink = regLinkPattern.replace('%MEETING_ID%', meetingId)

    return (
      <div className="text-center">
        <b>{lang.link_for_reg}: </b>
        <div className="ltr">
          {meetingRegLink}
        </div>

        <CopyToClipboard text={meetingRegLink}
                         onCopy={() => toast.success(lang.copied)}>
          <Button size="sm" color="link" className="mr-3">{lang.copy}</Button>
        </CopyToClipboard>
      </div>
    )
  }
}

MeetingRegLink.propTypes = propTypes

export default withLang(MeetingRegLink)
