import React from 'react'
import EmailTemplatesHome from './EmailTemplatesHome'

const EmailTemplatesModule = () => {
  return (
    <div id="module-email-templates">
      <EmailTemplatesHome/>
    </div>
  )
}

export default EmailTemplatesModule