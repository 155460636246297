import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatDateTime } from '../../../utils/functions'

const propTypes = {
  events: PropTypes.array,
}

class Events extends Component {
  render () {
    const { events } = this.props

    if (!events) return null

    return (
      <ul className="list-group">
        {
          this.props.events.map(event => (
            <li className="list-group-item d-flex justify-content-between"
                key={event.date}>
              {event.message}
              <div className="text-muted">{formatDateTime(
                +event.date)}</div>
            </li>
          ))
        }
      </ul>
    )
  }
}

Events.propTypes = propTypes

export default Events