import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { PARTICIPANT_CONTACTS_COLUMNS } from '../../../config/table-columns'
import { ITEMS_PER_PAGE } from '../../../config/constants'

const mapStateToProps = state => ({
  contacts: state.forms.participant.contacts,
})

class ParticipantContacts extends Component {
  render () {
    const { contacts } = this.props

    return (
      <div>
        <ReactTable
          data={contacts}
          defaultPageSize={ITEMS_PER_PAGE}
          showPagination={contacts && contacts.length > ITEMS_PER_PAGE}
          columns={PARTICIPANT_CONTACTS_COLUMNS}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
)(ParticipantContacts)