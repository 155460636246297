import { ENDPOINTS, ENTITY_MEETINGS_MESSAGES } from '../../config/constants'
import actionTypes from '../action-types'
import { disassembleResults, setUrlParams } from '../../utils/functions'
import { storeEntities } from './resourceActions'
import axios from 'axios'
import { generatePath } from 'react-router-dom'
import selectors from '../selectors'
import actions from './index'
import { toast } from 'react-toastify'
import { lang } from '../../utils/withLang'

/***********************
 * REQUESTS
 */
export const requestReadMessages = options => {
  return (
    axios.get(
      setUrlParams(generatePath(ENDPOINTS.MEETING_MESSAGES, { ...options }),
        { page: options.page })).then(
      response => {
        return response.data
      })
  )
}

export const requestCreateManuallyMessages = (id, form) => (
  axios.post(generatePath(ENDPOINTS.MEETING_MESSAGES_MANUALLY, { id }),
    { ...form })
)

export const requestSendMessage = (meetingId, messageId) => {
  //TODO to find out right modal request
  return (axios.post(
      generatePath(ENDPOINTS.SEND_ACTUAL_MESSAGES, { id: messageId }), {}).
      then(response => {
        return response.data
      })
  )
}

/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = (options = {}) => (dispatch, getState) => {
  options.page = options.page || selectors.meetingsMessages.getPage(getState())

  dispatch(crudReadPending())

  return requestReadMessages(options).then(response => {
    dispatch(crudReadSucceeded(response))
    return response.data
  }).catch(() => {console.log('Request Read Messages: Error')})
}

export const crudReadPending = () => ({
  type: actionTypes.meetingsMessages.CRUD_READ_PENDING,
})

export const crudReadSucceeded = data => dispatch => {

  const results = disassembleResults(data, ENTITY_MEETINGS_MESSAGES)
  dispatch(storeEntities(results.entities))
  dispatch({
    type: actionTypes.meetingsMessages.CRUD_READ_SUCCEEDED,
    payload: results,
  })
}

/** READ */

/** CREATE MANUALLY MESSAGES */

export const createManuallyMessages = (id, form) => (dispatch, getState) => {

  dispatch(crudReadPending())

  return requestCreateManuallyMessages(id, form).then((response) => {
    toast.success(lang.successfully_updated)
    dispatch(actions.core.closeModal(true, true))
    dispatch(crudRead({ id }))
    return response.data
  })

}

/** SEND MESSAGE */

export const sendMessage = (meetingId, messageId, page) => (
  dispatch, getState) => {

  dispatch(crudReadPending())

  return requestSendMessage(meetingId, messageId).then(() => {
    toast.success(lang.successfully_updated)
    dispatch(crudRead({ id: meetingId, page }))
  })

}

/** SET PAGE */

export const setPage = page => ({
  type: actionTypes.meetingsMessages.MEETINGS_MESSAGES_SET_PAGE,
  payload: {
    page,
  },
})

/** SET PAGE */

