export const get = (state) => state.settings['settings']

//select for selector MessagesEventsSelectMeetingStatus
export const getNamesIds = state => get(state).map(stage => {
  return {
    label: stage.name,
    value: stage.id,
  }
})

export const getStageById = (state, id) => get(state).find(stage => stage.id === id)
export const getIndexStageById = (state, id) => get(state).findIndex(stage => stage.id === id)