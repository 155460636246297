import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { withLang } from '../../utils/withLang'
import LineAwesome from '../LineAwesome'
import { PATHS } from '../../config/constants'

const mapStateToProps = state => ({
  isActive: state.core.layout.isSidebar
})

class AppSidebar extends Component {
  render () {
    const { lang, isActive } = this.props

    return (
      <aside className={`app-sidebar${!isActive ? ' app-sidebar_hidden' : ''}`}>
        <ul className="sidebar-menu tree" data-widget="tree">
          <li>
            <h5>{lang.general}</h5>
          </li>
          <li>
            <NavLink
              to={PATHS.MEETINGS}
              activeClassName="active">
              <LineAwesome icon="calendar-check-o"/> {lang.meetings}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHS.PARTICIPANTS}
              activeClassName="active">
              <LineAwesome icon="users"/> {lang.participants}
            </NavLink>
          </li>

          <li>
            <h5>{lang.sms_management}</h5>
          </li>
          <li>
            <NavLink
              to={PATHS.MESSAGES_EVENTS}
              activeClassName="active">
              <LineAwesome icon="la la-calendar-o"/> {lang.events}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHS.MESSAGES_TYPES}
              activeClassName="active">
              <LineAwesome icon="la la-envelope"/> {lang.event_type}
            </NavLink>
          </li>

          <li>
            <h5>{lang.campaign_management}</h5>
          </li>
          <li>
            <NavLink
              to={PATHS.CAMPAIGNS}
              activeClassName="active">
              <LineAwesome icon="bullhorn"/> {lang.campaign_send}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHS.EMAIL_TEMPLATES}
              activeClassName="active">
              <LineAwesome icon="la la-envelope"/> {lang.email_templates}
            </NavLink>
          </li>
          <li>
            <h5>{lang.settings}</h5>
          </li>
          <li>
            <NavLink
              to={PATHS.RESTAURANTS}
              activeClassName="active">
              <LineAwesome icon="cutlery"/> {lang.restaurants}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHS.AGE_GROUPS}
              activeClassName="active">
              <LineAwesome icon="child"/> {lang.age_groups}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHS.MEETING_CYCLE}
              activeClassName="active">
              <LineAwesome icon="rotate-right"/> {lang.meeting_cycle}
            </NavLink>
          </li>
        </ul>

        <div className="copyright">
          Happy Ananas &copy; 2019
        </div>
      </aside>
    )
  }
}

export default withLang(connect(
  mapStateToProps,
)(AppSidebar))