import selectors from './index'
import { ENTITY_RESTAURANTS } from '../../config/constants'

export const getAll = state => state.restaurants.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_RESTAURANTS, id))
export const getById = (state, id) => state.resources[ENTITY_RESTAURANTS]['' +
id]
export const getIds = state => state.restaurants.ids
export const getCount = state => state.restaurants.count
export const getIsFetching = state => state.restaurants.isFetching
export const getPage = state => state.restaurants.page