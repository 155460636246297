import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Form, actions as ReactReduxFormActions } from 'react-redux-form';
import { Button } from 'reactstrap';

import selectors from '../../../redux/selectors';
import {
	createManuallyMessages,
	crudRead,
	setPage,
} from '../../../redux/actions/participantMessagesActions';
import Pagination from '../../../components/Pagination';
import { PARTICIPANT_MESSAGES } from '../../../config/table-columns';
import {
	ITEMS_PER_PAGE, DEFAULT_TEMPLATE_TAGS,
	WYSIWYG_SETTINGS_FORMATS, PARTICIPANT_MESSAGE_TEMPLATE_TAGS,
} from '../../../config/constants';
import { lang } from '../../../utils/withLang';
import LineAwesome from '../../../components/LineAwesome';
import FormInput from '../../../components/FormInput';
import validators from '../../../utils/validators';

const ParticipantMessages = ({ participantId, dispatch, participantMessages, isFetching, count, page, form }) => {

	// observe participantId and Page in order to re-fetch messages
	useEffect(() => {
		fetch(page, participantId);
	}, [page, participantId]);

	// once participantId is changed - we update ReactReduxForm with new participantId
	useEffect(() => {
		dispatch(
			ReactReduxFormActions.change('forms.manuallyMessage.recipients',
				[participantId]));
	}, [participantId]);

	const fetch = (page, id) => {
		dispatch(crudRead({ page, id }));
	};

	const handlePageChange = useCallback((page) => dispatch(setPage(page)),
		[dispatch]);
	const handleRefresh = useCallback(() => fetch(page, participantId),
		[participantId, page]);
	const handleSendMessage = useCallback(() => {
		dispatch(createManuallyMessages(participantId, form));
	}, [participantId, form]);

	const Pag = () => (
		<Pagination
			onChange={handlePageChange}
			page={page}
			totalItems={count}
			isFetching={isFetching}
		/>
	);

	return (
		<div className="age-groups">

			<Form model="forms.manuallyMessage">
				<div className="card-body">
					<div className="row justify-content-center mt-1">
						<div className="col-md-12">
							<FormInput
								formats={WYSIWYG_SETTINGS_FORMATS}
								type="wysiwyg"
								model=".text"
								templateTags={PARTICIPANT_MESSAGE_TEMPLATE_TAGS}
								label={lang.message_content}
								validators={{
									required: validators.required,
									smsLength: validators.smsLength,
								}}
								errorMessages={{
									required: lang.errors.required_field,
									smsLength: lang.errors.sms_length,
								}}
							/>
						</div>
					</div>

					<div className="row justify-content-center mt-1 mb-4">
						<Button
							type="submit"
							color="success"
							onClick={handleSendMessage}>
							<LineAwesome icon="envelope"/> {lang.send_message}
						</Button>
					</div>
				</div>
			</Form>

			<hr/>

			<div className="participant-messages-table-header">
				<div>{lang.messages_to_participant}</div>

				<div
					color="success"
					className="ml-5 mt-2 mb-1 btn-icon btn-refresh"
					onClick={handleRefresh}>
					<LineAwesome icon="refresh"/>
				</div>
			</div>
			<ReactTable
				showPagination={false}
				data={participantMessages}
				columns={PARTICIPANT_MESSAGES}
				defaultPageSize={ITEMS_PER_PAGE}
				className="-striped -highlight"
				noDataText={isFetching
					? `${lang.loading}...`
					: lang.no_info}
			/>

			<Pag/>

		</div>
	);
};

const mapStateToProps = state => ({
	participantMessages: selectors.participantMessages.getAll(state),
	count: selectors.participantMessages.getCount(state),
	page: selectors.participantMessages.getPage(state),
	isFetching: selectors.meetingsMessages.getIsFetching(state),
	participantId: state.forms.participant.id,
	form: state.forms.manuallyMessage,
});

export default connect(mapStateToProps)(ParticipantMessages);
