import React, { Component } from 'react'
import MeetingsHome from './MeetingsHome'

class MeetingsModule extends Component {
  render () {
    return (
      <div id="module-meetings">
        <MeetingsHome/>
      </div>
    )
  }
}

export default MeetingsModule