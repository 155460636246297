import React, { Component } from 'react'
import { connect } from 'react-redux'
import {lang, withLang} from '../../../utils/withLang'
import { withRouter } from 'react-router-dom'
import LineAwesome from '../../../components/LineAwesome'
import {Button, Col, Row} from 'reactstrap'
import selectors from '../../../redux/selectors'
import {sendSMSFillUpMen, sendSMSFillUpWomen} from "../../../redux/actions/meetingActions";
import MeetingSMSFillupList from "./MeetingSMSFillupList";

const mapStateToProps = state => ({
  meetingsMessages: selectors.meetingsMessages.getAll(state),
  count: selectors.meetingsMessages.getCount(state),
  isFetching: selectors.meetingsMessages.getIsFetching(state),
  meetingId: state.forms.meeting.id,
  meeting: state.forms.meeting

})

class MeetingFilling extends Component {
  state = {
  }


  handleSendSMSFillUpWomenClick = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpWomen(meetingId, '1'))
  }

    handleSendSMSFillUpMenClick = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpMen(meetingId, '1'))
  }

    handleSendSMSFillUpWomenClick2 = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpWomen(meetingId, '2'))
  }

    handleSendSMSFillUpMenClick2 = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpMen(meetingId, '2'))
  }

    handleSendSMSFillUpWomenClick3 = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpWomen(meetingId, '3'))
  }

    handleSendSMSFillUpMenClick3 = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpMen(meetingId, '3'))
  }

    handleSendSMSFillUpWomenClick4 = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpWomen(meetingId, '4'))
  }

    handleSendSMSFillUpMenClick4 = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpMen(meetingId, '4'))
  }

    handleSendSMSFillUpWomenClick5 = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpWomen(meetingId, '5'))
  }

    handleSendSMSFillUpMenClick5 = () => {
    const { meetingId, dispatch } = this.props
    dispatch(sendSMSFillUpMen(meetingId, '5'))
  }

  render () {
      const { meeting } = this.props
      let { lang } = this.props

    return (
      <div className="age-groups">
                  <div className="meeting-events-list">
                     <h3><LineAwesome icon="arrow-circle-left"/> {lang.arrows}</h3>
                  </div>
                  <div>
                  <Row style={{borderBottom:'1px solid', borderBottomColor:'#ccc'}}>
                      <Col>
                        <div>
                            {lang.first_arrow}
                        </div>
                      </Col>
                      <Col>
                        <div>
                            {lang.first_arrow_desc}
                        </div>
                      </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="info"
                            onClick={this.handleSendSMSFillUpWomenClick}>
                            <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_women}
                          </Button>
                        </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={this.handleSendSMSFillUpMenClick}>
                             <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_men}
                          </Button>
                        </Col>
                  </Row>
                  <Row style={{borderBottom:'1px solid', borderBottomColor:'#ccc'}}>
                      <Col>
                        <div>
                            {lang.second_arrow}
                        </div>
                      </Col>
                      <Col>
                        <div>
                            {lang.second_arrow_desc}
                        </div>
                      </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="info"
                            onClick={this.handleSendSMSFillUpWomenClick2}>
                            <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_women}
                          </Button>
                        </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={this.handleSendSMSFillUpMenClick2}>
                             <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_men}
                          </Button>
                        </Col>
                  </Row>
                  <Row style={{borderBottom:'1px solid', borderBottomColor:'#ccc'}}>
                      <Col>
                        <div>
                            {lang.third_arrow}
                        </div>
                      </Col>
                      <Col>
                        <div>
                            {lang.third_arrow_desc}
                        </div>
                      </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="info"
                            onClick={this.handleSendSMSFillUpWomenClick3}>
                            <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_women}
                          </Button>
                        </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={this.handleSendSMSFillUpMenClick3}>
                             <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_men}
                          </Button>
                        </Col>
                  </Row>
                  <Row style={{borderBottom:'1px solid', borderBottomColor:'#ccc'}}>
                      <Col>
                        <div>
                            {lang.fourth_arrow}
                        </div>
                      </Col>
                      <Col>
                        <div>
                            {lang.fourth_arrow_desc}
                        </div>
                      </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="info"
                            onClick={this.handleSendSMSFillUpWomenClick4}>
                            <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_women}
                          </Button>
                        </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={this.handleSendSMSFillUpMenClick4}>
                             <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_men}
                          </Button>
                        </Col>
                  </Row>
                  <Row>
                      <Col>
                        <div>
                            {lang.fifth_arrow}
                        </div>
                      </Col>
                      <Col>
                        <div>
                            {lang.fifth_arrow_desc}
                        </div>
                      </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="info"
                            onClick={this.handleSendSMSFillUpWomenClick5}>
                            <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_women}
                          </Button>
                        </Col>
                        <Col>
                            <Button
                            type="submit"
                            size="md"
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={this.handleSendSMSFillUpMenClick5}>
                             <LineAwesome icon="arrow-left"/>
                            {lang.send_arrow_men}
                          </Button>
                        </Col>
                  </Row>
                  </div>
        <div className="meeting-events">
        <MeetingSMSFillupList
          meeting={meeting}
        />
      </div>
      </div>

    )
  }
}


export default withRouter(withLang(connect(mapStateToProps)(MeetingFilling)))