import selectors from './index'
import { ENTITY_CITIES } from '../../config/constants'

export const getAll = state => state.cities.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_CITIES, id))
export const getById = (state, id) => state.resources[ENTITY_CITIES]['' +
id]
export const getIds = state => state.cities.ids
export const getCount = state => state.cities.count
export const getIsFetching = state => state.cities.isFetching
export const getPage = state => state.cities.page