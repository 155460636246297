import * as core from './coreSelectors';
import * as collections from './resourcesSelectors';
import * as meetings from './meetingsSelectors';
import * as participants from './participantsSelectors';
import * as restaurants from './restaurantsSelectors';
import * as ageGroups from './ageGroupsSelectors';
import * as ageRanges from './ageRangesSelectors';
import * as cities from './citiesSelectors';
import * as clubs from './clubsSelectors'
import * as settings from './settingsSelectors';
import * as messagesTypes from '../selectors/messagesTypesSelectors';
import * as messagesEvents from '../selectors/messagesEventsSelectors';
import * as meetingsMessages from './meetingMessagesSelectors';
import * as participantMessages from './participantMessagesSelectors';
import * as emailTemplates from './emailTemplatesSelectors';

const selectors = {
	core,
	collections,
	meetings,
	participants,
	restaurants,
	messagesTypes,
	messagesEvents,
	settings,
	ageGroups,
	ageRanges,
	cities,
	clubs,
	meetingsMessages,
	participantMessages,
	emailTemplates,
};

export default selectors;