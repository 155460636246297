import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withLang } from '../../../utils/withLang'
import selectors from '../../../redux/selectors'
import { connect } from 'react-redux'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  meetingStatuses: selectors.settings.getNamesIds(state),
})

const MessagesEventsSelectMeetingStatus = (props) => {

  const {
    meetingStatuses,
    value,
    onChange,
    lang,
    ...otherProps
  } = props

  // Define defaultValue if open UPDATE-MODAL`
  let defValue = null
  if (value) {
    defValue = value.map((id) => {
      return meetingStatuses.find(status => status.value === id)
    })
  }

  return (
    <Select
      type="select"
      name="meeting-status"
      id="select-meeting-status"
      isMulti
      defaultValue={defValue}
      getValue={meetingStatuses.filter(
        option => option.value === value)}
      options={meetingStatuses}
      onChange={(arr) => {
        const ids = arr.map(obj => obj.value)
        onChange(ids)
      }}
      {...otherProps}
    />
  )
}

MessagesEventsSelectMeetingStatus.propTypes = propTypes

export default withLang(
  connect(mapStateToProps)(MessagesEventsSelectMeetingStatus))
