import React, { Component } from 'react'

const defaultProps = {
  isVerified: false
}

class Phone extends Component {

  render () {
    if (!this.props.children)
      return null

    const isVerified = this.props.isVerified;
    let color;
    if (isVerified) {
      color = '#2bc34e'
    } else {
      color = 'black'
    }
    return (

      <div style={{ color:color}} className="ltr d-inline-block">
        {this.props.children}
      </div>
    )
  }
}

Phone.defaultProps = defaultProps

export default Phone