import React, { Component } from 'react'
import { withLang } from '../../utils/withLang'
import { connect } from 'react-redux'
import selectors from '../../redux/selectors'
import actions from '../../redux/actions'
import ReactTable from 'react-table'
import { PARTICIPANTS_COLUMNS } from '../../config/table-columns'
import { withRouter } from 'react-router-dom'
import { setDocumentTitle, setUrlParams } from '../../utils/functions'
import Pagination from '../../components/Pagination'
import PageTitle from '../../components/PageTitle'
import { ITEMS_PER_PAGE, PATHS } from '../../config/constants'
import FormGroup from 'reactstrap/es/FormGroup'
import Label from 'reactstrap/es/Label'
import Input from 'reactstrap/es/Input'
import { Button, Form, Row } from 'reactstrap'
import Col from 'reactstrap/es/Col'

const mapStateToProps = state => ({
  participants: selectors.participants.getAll(state),
  count: selectors.participants.getCount(state),
  isFetching: selectors.participants.getIsFetching(state),
  search: selectors.participants.getSearch(state),
})

class ParticipantsHome extends Component {
  state = {
    page: null,
    search: '',
    searchInput: '',
    touched: false,
  }

  componentDidMount () {
    setDocumentTitle('Participants')

    this.resolveParams()
  }

  componentDidUpdate () {
    this.resolveParams()
  }

  // Get params (page and text) for searching in the table. Fetch in the end.
  resolveParams = () => {

    let params = new URLSearchParams(this.props.location.search)

    const pageParam = params.get('page') ? +params.get('page') : 1
    const searchParam = params.get('search') ? params.get('search') : ''

    const {
      search,
      page,
    } = this.state

    if (search !== searchParam || page !== pageParam) {
      this.setState(
        {
          page: pageParam,
          search: searchParam,
          searchInput: searchParam,
        }, () => this.fetch(),
      )
    }
  }

  //Get data from server.
  fetch = () => {
    const { dispatch } = this.props
    const { page, search } = this.state

    if (search.length > 0) {
      dispatch(
        actions.participants.crudRead({ page, search }),
      )
    } else {
      dispatch(
        actions.participants.crudRead({ page }),
      )
    }

  }

  //Create new participate. Dispatch action for open modal.
  handleNewClick = () => {
    this.props.dispatch(actions.participants.openModalForm())
  }

  handleSearchSubmit = e => {

    e.preventDefault()

    const {
      searchInput: search,
    } = this.state

    const searchStr = setUrlParams('', { search, page: 1 })

    this.props.history.push({
      pathname: PATHS.PARTICIPANTS,
      search: searchStr,
    })
  }

  render () {
    const {
      participants,
      count,
      isFetching,
      lang,
      history,
    } = this.props

    const {
      searchInput,
    } = this.state

    const Pag = () => (
      <Pagination
        totalItems={count}
        path={PATHS.PARTICIPANTS}
        isFetching={isFetching}
      />
    )

    return (
      <div className="participants">
        <PageTitle
          title={lang.participants}
          icon="users"
          onCreateClick={this.handleNewClick}
        />

        {/*Input search*/}
        <Form id="participant-home-search"
              className="table-search"
              onSubmit={this.handleSearchSubmit}>

          <Row className="justify-content-center col-md-8 mx-auto">
            <Col>
              <FormGroup className="row">
                <Label
                  className="col-sm-2 col-form-label">{lang.search}: </Label>
                <div className="col-sm-10">
                  <Input
                    bsSize="sm"
                    value={searchInput}
                    onChange={({ target: { value } }) => {
                      this.setState({
                        searchInput: value,
                      })
                    }}
                  />
                </div>
              </FormGroup>
            </Col>

            <Col md="auto">
              <FormGroup>
                <Button type="submit"
                        size="sm"
                        color="success">
                  {lang.search}
                </Button>

                <Button type="button"
                        size="sm"
                        color="link"
                        onClick={() => history.push(PATHS.PARTICIPANTS)}>
                  {lang.reset}
                </Button>
              </FormGroup>
            </Col>
          </Row>

        </Form>

        <ReactTable
          showPagination={false}
          data={participants}
          columns={PARTICIPANTS_COLUMNS}
          defaultPageSize={ITEMS_PER_PAGE}
          className="-striped -highlight"
          noDataText={isFetching ? `${lang.loading}...` : lang.no_info}
        />

        <Pag/>
      </div>
    )
  }
}

export default withRouter(withLang(connect(mapStateToProps)(ParticipantsHome)))