import actionTypes from '../action-types'

const initialState = {
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.clubs.CRUD_READ_SUCCEEDED:
      return {
        ...state, data: action.payload,
      }

    default:
      return state
  }
}