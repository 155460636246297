import React from 'react';
import { lang } from '../../../utils/withLang';
import {
	MEETING_TYPES,
	PARTICIPATION_STATUSES,
} from '../../../config/constants';
import { formatDateTime } from '../../../utils/functions';
import classnames from 'classnames';

const createTextItem = (label, value) => {
	return (
		<div className="ml-5">
			<span className="font-weight-bold">
				{label}
			</span>
			<div>
				{value}
			</div>
		</div>
	);
};

const createInlineTextItem = (label, value) => {
	return (
		<div className="ml-5">
			<span className="font-weight-bold">
				{label}
			</span>
			<span>
				{value}
			</span>
		</div>
	);
};

const renderEvents = (events) => {

	if (!events || !events.length) return <div> {lang.no_info} </div>;

	return events.map(({ date_time, status }, i) => {

		const isRed = status === 3;

		return <div key={i} className={classnames(isRed
			? 'participant-action-event color-red'
			: 'participant-action-event')}>
			<span className="font-weight-bold ml-4">
				{i + 1}.
			</span>
			{date_time &&
			createInlineTextItem(`${lang.date}: `, formatDateTime(date_time))}

			{createInlineTextItem(`${lang.status}: `,
				lang[PARTICIPATION_STATUSES[status]])}
		</div>;
	});
};

const ActionEventsMeeting = ({ id, type, date, restaurant, events }) => {
	return (
		<div className="participant-action-event-meeting">

			<div className="row mr-2 mt-2">
				<span
					className="font-size-20">{lang.meeting_info}:
				</span>
			</div>

			<div className='row mr-2'>

				{id && createTextItem(`ID ${lang.meeting}: `, id)}
				{type && createTextItem(`${lang.meeting_type}: `,
					lang[MEETING_TYPES[type]])}
				{date && createTextItem(`${lang.date}: `, formatDateTime(date))}
				{restaurant &&
				createTextItem(`${lang.restaurant}: `, restaurant)}

			</div>

			<hr/>

			<div className="row mr-2 mb-2">
				<span
					className="font-size-20">{lang.participant_actions}:
				</span>
			</div>

			<div className="row mr-2">
				{
					renderEvents(events)
				}
			</div>

		</div>
	);
};

export default ActionEventsMeeting;
