import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  icon: PropTypes.string.isRequired
}

class LineAwesome extends Component {
  render () {
    const { icon } = this.props

    return (
      <i className={`la la-${icon}`}/>
    )
  }
}

LineAwesome.propTypes = propTypes

export default LineAwesome