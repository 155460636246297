import {
  disassembleResults,
  getFullName,
  setUrlParams,
} from '../../utils/functions'
import { storeEntities, storeEntity, updateEntity } from './resourceActions'
import { generatePath } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import actionTypes from '../action-types'
import selectors from '../selectors'
import actions from './index'
import { toast } from 'react-toastify'
import { lang } from '../../utils/withLang'
import {
  ENDPOINTS,
  ENTITY_PARTICIPANTS,
  MODAL_PARTICIPANT_MANAGE,
} from '../../config/constants'
import { customFormUpdate } from './coreActions'

/***********************
 * REQUESTS
 */

export const requestRead = options => (
  axios.get(setUrlParams(ENDPOINTS.PARTICIPANTS, options)).then(
    response => {
      return response.data
    })
)

export const requestReadOne = id => (
  axios.get(generatePath(ENDPOINTS.PARTICIPANT, { id })).then(
    response => response.data)
)

export const requestReadContacts = id => (
  axios.get(generatePath(ENDPOINTS.PARTICIPANT_CONTACTS, { id })).then(
    response => response.data.contacts)
)

export const requestReadEvents = id => (
    axios.get(generatePath(ENDPOINTS.PARTICIPANT_EVENTS, { id })).then(
        response => response.data.data)
)

export const requestReadRestaurants = id => (
  axios.get(generatePath(ENDPOINTS.PARTICIPANT_RESTAURANTS, { id })).then(
    response => response.data.restaurants)
)

export const requestCreate = participant => (
  axios.post(ENDPOINTS.PARTICIPANTS, { ...participant })
)

export const requestUpdate = participant => (
  axios.patch(
    generatePath(ENDPOINTS.PARTICIPANT, { id: participant.id }),
    { ...participant },
  )
)

export const requestDelete = id => (
  axios.delete(generatePath(ENDPOINTS.PARTICIPANT, { id }))
)

/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = (options = {}) => (dispatch, getState) => {
  let params = new URLSearchParams(window.location.search)
  options.page = params.get('page') ? +params.get('page') : 1
  const searchParam = params.get('search') ? params.get('search') : ''

  options.page = options.page || selectors.participants.getPage(getState())

  if (searchParam !== '') {
    options.search = searchParam
  }

  dispatch(crudReadPending())

  return requestRead(options).then(response => {
    dispatch(crudReadSucceeded(response))
    return response.data
  }).catch(error => {
    console.log(error)
    dispatch(crudReadFailed())
  })
}

export const crudReadPending = () => ({
  type: actionTypes.participants.CRUD_READ_PENDING,
})

export const crudReadSucceeded = data => dispatch => {
  const results = disassembleResults(data, ENTITY_PARTICIPANTS)
  dispatch(storeEntities(results.entities))
  dispatch({
    type: actionTypes.participants.CRUD_READ_SUCCEEDED,
    payload: results,
  })
}

export const crudReadFailed = () => ({
  type: actionTypes.participants.CRUD_READ_FAILED,
})
/** READ */

/** READ ONE */
export const crudReadOne = id => (dispatch, getState) => {
  return requestReadOne(id).then(response => {
    dispatch(crudReadOneSucceeded(response))
    return response.data
  }).catch(error => {
    console.log(error)
    dispatch(crudReadOneFailed())
  })
}

export const crudReadOneSucceeded = data => dispatch => {
  dispatch(storeEntity(ENTITY_PARTICIPANTS, data))
}

export const crudReadOneFailed = () => ({
  type: actionTypes.participants.CRUD_READ_FAILED,
})
/** READ ONE */

/** READ {CONTACTS} */
export const crudReadContacts = participantId => dispatch => {
  return requestReadContacts(participantId).then(contacts => {
    dispatch(crudReadContactsSucceeded(participantId, contacts))
  })
}

export const crudReadContactsSucceeded = (participantId, contacts) =>
  dispatch => {
    dispatch(
      updateEntity(ENTITY_PARTICIPANTS, { id: participantId, contacts }))
  }
/** READ {CONTACTS} */

/** READ {EVENTS} */
export const crudReadEvents = participantId => dispatch => {
  return requestReadEvents(participantId).then(actionEvents => {
    dispatch(crudReadEventsSucceeded(participantId, actionEvents))
  })
}

export const crudReadEventsSucceeded = (participantId, actionEvents) =>
    dispatch => {
      dispatch(
          updateEntity(ENTITY_PARTICIPANTS, { id: participantId, actionEvents }))
    }
/** READ {CONTACTS} */

/** READ {RESTAURANTS} */
export const crudReadRestaurants = participantId => dispatch => (
  requestReadRestaurants(participantId).then(restaurants => {
    dispatch(crudReadRestaurantsSucceeded(participantId, restaurants))
  })
)

export const crudReadRestaurantsSucceeded = (participantId, restaurants) =>
  dispatch => {
    dispatch(
      updateEntity(ENTITY_PARTICIPANTS, { id: participantId, restaurants }))
  }
/** READ {RESTAURANTS} */

/** CREATE */
export const crudCreate = participantObj => dispatch => {
  // TODO: remove this hack and update backend to have the same format of all dates in the system
  const participant = Object.assign({}, participantObj)
  participant.birthday = participant.birthday
    ? moment(participant.birthday).format('YYYY-MM-DD')
    : null

  return requestCreate(participant).then(({ data: createdParticipant }) => {
    toast.success(lang.successfully_created)
    dispatch(crudCreateSucceeded(createdParticipant))
    dispatch(crudRead())
    return createdParticipant
  })
}

export const crudCreateSucceeded = result => ({
  type: actionTypes.participants.CRUD_UPDATE_SUCCEEDED,
  payload: { ...result },
})
/** CREATE */

/** UPDATE */
export const crudUpdate = participantObj => dispatch => {
  // TODO: remove this hack and update backend to have the same format of all dates in the system
  const participant = Object.assign({}, participantObj)
  participant.birthday = participant.birthday
    ? moment(participant.birthday).format('YYYY-MM-DD')
    : null

  return requestUpdate(participant).then(response => {
    toast.success(lang.successfully_updated)
    dispatch(crudUpdateSucceeded(response.data))
    dispatch(crudRead())
    return response.data
  })
}

export const crudUpdateSucceeded = meeting => ({
  type: actionTypes.meetings.CRUD_UPDATE_SUCCEEDED,
  payload: { ...meeting },
})
/** UPDATE */

/** DELETE */
export const crudDelete = participant => dispatch => (
  requestDelete(
    typeof participant === 'object' ? participant.id : participant).then(
    () => {
      toast.success(lang.successfully_deleted)
      dispatch(crudDeleteSucceeded())
      dispatch(crudRead())
    })
)

export const crudDeleteSucceeded = () => ({
  type: actionTypes.participants.CRUD_DELETE_SUCCEEDED,
})

export const crudDeleteFailed = () => ({
  type: actionTypes.participants.CRUD_DELETE_FAILED,
})
/** DELETE */

/***********************
 MANAGE
 **********************/
export const openModalForm = (
  participantId = null, submodal = false) => (dispatch, getState) => {
  const openModal = participant => {
    const title = participantId ?
      getFullName(participant) :
      lang.new_participant
    dispatch(actions.core.openModalForm({
      title,
      component: MODAL_PARTICIPANT_MANAGE,
      form: 'participant',
      isNew: !participantId,
    }, submodal))
  }

  if (participantId) {
    const requests = [
      dispatch(crudReadOne(participantId)),
      dispatch(crudReadContacts(participantId)),
      dispatch(crudReadRestaurants(participantId)),
      dispatch(crudReadEvents(participantId)),
    ]

    Promise.all(requests).then(() => {
      const participant = selectors.participants.getById(getState(),
        participantId)
      dispatch(customFormUpdate(`forms.participant`, participant, true))
      openModal(participant)
    })
  } else {
    const participant = selectors.participants.getById(getState(),
      participantId)
    openModal(participant)
  }
}

/***********************
 COMMON
 **********************/
export const setSearch = search => ({
  type: actionTypes.participants.SET_SEARCH,
  payload: search,
})
