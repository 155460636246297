import React, { Component } from 'react'
import Logo from '../../components/Logo'

class ApiUnavailable extends Component {
  render () {
    return (
      <div className="error-page error-page_not-found" dir="ltr">
        <Logo color="color"/>

        <div className="error-page-title">
          Page Not found
        </div>

        <div className="error-page-subtitle">
          We can't seem to find the page you're looking for.
        </div>
      </div>
    )
  }
}

export default ApiUnavailable