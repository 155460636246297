import selectors from './index'
import { ENTITY_MEETINGS_MESSAGES } from '../../config/constants'

export const getIds = state => state.meetingsMessages.ids
export const getAll = state => state.meetingsMessages.ids.map(
  id => selectors.collections.getEntity(state, ENTITY_MEETINGS_MESSAGES, id))
export const getById = (
  state, id) => state.resources[ENTITY_MEETINGS_MESSAGES]['' + id]
export const getPage = state => state.meetingsMessages.page
export const getCount = state => state.meetingsMessages.count
export const getIsFetching = state => state.meetingsMessages.isFetching
export const getSearch = state => state.meetingsMessages.search
