import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import PropTypes from 'prop-types'

const defaultProps = {
  text: 'text',
  previewLength: 30,
}

const propTypes = {
  text: PropTypes.string,
  previewLength: PropTypes.number,
  id: PropTypes.any.isRequired,
}

const PopoverLongText = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const { text, id, previewLength } = props

  return (
    <div>
      <p className='popover-preview'
         id={id}>
        {text.slice(0, previewLength)}...
      </p>
      <Tooltip placement="right"
               isOpen={tooltipOpen}
               target={id}
               toggle={() => {
                 setTooltipOpen(!tooltipOpen)
               }}
               autohide={false}
               className='popover-tooltip alert-light'
      >
        {text}
      </Tooltip>
    </div>
  )
}

PopoverLongText.propTypes = propTypes
PopoverLongText.defaultProps = defaultProps

export default PopoverLongText