import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withLang } from '../utils/withLang'
import { RESTAURANT_FOOD_TYPES } from '../config/constants'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

class SelectCosher extends Component {
  render () {
    const {
      value,
      onChange,
      lang,
      ...otherProps
    } = this.props

    const cosherOptions = Object.keys(RESTAURANT_FOOD_TYPES).map(type => ({
      label: lang[RESTAURANT_FOOD_TYPES[type]],
      value: +type,
    }))

    return (
      <Select
        type="select"
        name="gender"
        id="select-gender"
        placeholder=""
        value={cosherOptions.filter(option => option.value === value)}
        options={cosherOptions}
        onChange={({ value }) => onChange(value)}
        {...otherProps}
      />
    )
  }
}

SelectCosher.propTypes = propTypes

export default withLang(SelectCosher)