import {
	ENDPOINTS,
	ENTITY_MEETINGS_MESSAGES,
	ENTITY_PARTICIPANT_MESSAGES,
} from '../../config/constants';
import actionTypes from '../action-types';
import { disassembleResults, setUrlParams } from '../../utils/functions';
import { storeEntities } from './resourceActions';
import axios from 'axios';
import { generatePath } from 'react-router-dom';
import selectors from '../selectors';
import actions from './index';
import { toast } from 'react-toastify';
import { lang } from '../../utils/withLang';

/***********************
 * REQUESTS
 */
export const requestReadMessages = options => {
	return (
		axios.get(
			setUrlParams(
				generatePath(ENDPOINTS.PARTICIPANT_MESSAGES, { ...options }),
				{ page: options.page })).then(
			response => {
				return response.data;
			})
	);
};

export const requestCreateManuallyMessages = (form) => {
	console.log('requestCreateManuallyMessages', form);
	return axios.post(ENDPOINTS.PARTICIPANT_MESSAGE_MANUALLY, { ...form });
};

/***********************
 CRUD
 **********************/

/** READ */
export const crudRead = (options = {}) => (dispatch, getState) => {
	options.page = options.page ||
		selectors.participantMessages.getPage(getState());

	dispatch(crudReadPending());

	return requestReadMessages(options).then(response => {
		dispatch(crudReadSucceeded(response));
		return response.data;
	}).catch(() => {console.log('Request Participant Read Messages: Error');});
};

export const crudReadPending = () => ({
	type: actionTypes.participantMessages.CRUD_READ_PENDING,
});

export const crudReadSucceeded = data => dispatch => {

	const results = disassembleResults(data, ENTITY_PARTICIPANT_MESSAGES);
	dispatch(storeEntities(results.entities));
	dispatch({
		type: actionTypes.participantMessages.CRUD_READ_SUCCEEDED,
		payload: results,
	});
};

/** READ */

/** CREATE MANUALLY MESSAGES */

export const createManuallyMessages = (participantId, form) => (
	dispatch, getState) => {

	dispatch(crudReadPending());
	const page = selectors.participantMessages.getPage(getState());
	console.log('createManuallyMessages', form);
	return requestCreateManuallyMessages(form).then((response) => {
		toast.success(lang.successfully_updated);
		return dispatch(crudRead({ id: participantId, page }));
	});

};

/** SET PAGE */

export const setPage = page => ({
	type: actionTypes.participantMessages.PARTICIPANT_MESSAGES_SET_PAGE,
	payload: {
		page,
	},
});

/** SET PAGE */

