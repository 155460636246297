import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions as ReduxFormActions } from 'react-redux-form'
import { withLang } from '../../../utils/withLang'
import Events from './Events'
import OneFieldForm from '../../../components/OneFieldForm'

const mapStateToProps = state => ({
  participant: state.forms.participant,
})

class ParticipantNotes extends Component {
  handleEventCreate = message => {
    this.props.dispatch(
      ReduxFormActions.push('forms.participant.events',
        { message, date: +new Date() }),
    )
  }

  render () {
    const { participant: { events }, lang } = this.props

    return (
      <div>
        <h3>{lang.notes}</h3>

        <div className="events-editor">
          <Events
            events={events}
          />

          <OneFieldForm
            onSubmit={this.handleEventCreate}
            placeholder={lang.add_new_event}
          />
        </div>
      </div>
    )
  }
}

export default withLang(connect(mapStateToProps)(ParticipantNotes))