import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import selectors from '../../../redux/selectors/index'
import LineAwesome from '../../../components/LineAwesome'
import Phone from '../../../components/Phone'
import { withLang } from '../../../utils/withLang'
import { ENTITY_RESTAURANTS } from '../../../config/constants'

const propTypes = {
  restaurant: PropTypes.object.isRequired,
  restaurantId: PropTypes.number.isRequired
}

const mapStateToProps = (state, props) => ({
  restaurant: props.restaurantId
    ? selectors.collections.getEntity(state, ENTITY_RESTAURANTS, props.restaurantId)
    : props.restaurant
})

class RestaurantCard extends Component {
  renderCardContact () {
    const {
      restaurant: {
        contact_person
      },
      lang
    } = this.props

    const isNotEmpty = contact_person && Array.isArray(contact_person)

    return (
      <div className="restaurant-card-contact">
        {
          !isNotEmpty
          && <p className="text-muted text-center">{lang.no_info}</p>
        }

        {
          isNotEmpty && contact_person.map((contact, index) => (
            <ul key={index}>
              {
                contact.first_name && (
                  <li>
                    <LineAwesome icon="user"/> {contact.first_name}
                  </li>
                )
              }
              {
                contact.position && (
                  <li>
                    <LineAwesome icon="briefcase"/> {contact.position}
                  </li>
                )
              }
              {
                contact.telephone && (
                  <li>
                    <LineAwesome icon="phone"/> {contact.telephone}
                  </li>
                )
              }
              {
                contact.note && (
                  <li>
                    <LineAwesome icon="sticky-note"/> {contact.note}
                  </li>
                )
              }
              {
                <li>
                  <LineAwesome icon="envelope"/> {contact.is_receive_messages && lang.is_receive_messages} {!contact.is_receive_messages && lang.no_receive}
                </li>
              }
            </ul>
          ))
        }
      </div>
    )
  }

  render () {
    const { restaurant } = this.props

    return (
      <div className="card restaurant-card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="restaurant-card-header">
                <div className="restaurant-card-header__icon">
                  <LineAwesome icon="cutlery"/>
                </div>

                <h2>{restaurant.title}</h2>
              </div>

              <div className="restaurant-card-meta">
                <div className="restaurant-card-meta__item">
                  {restaurant.address}
                </div>

                <div className="restaurant-card-meta__item">
                  <Phone>{restaurant.phone}</Phone>
                </div>
              </div>
            </div>

            <div className="col-md-6 flex-grow-1">
              {this.renderCardContact()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RestaurantCard.propTypes = propTypes

export default withLang(connect(mapStateToProps)(RestaurantCard))