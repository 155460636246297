import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withLang } from '../../../utils/withLang'
import { connect } from 'react-redux'

const propTypes = {
  onChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return ({
    // contacts: state.forms.meeting.restaurant.contact_person,
      contacts : [].concat(state.forms.meeting.restaurant.contact_person)
  })
}

const MeetingMessagesMultiSelectContacts = (props) => {

  const {
    value,
    onChange,
    lang,
    contacts,
    ...otherProps
  } = props

  const contactsOptions =contacts ? contacts.map(
    contact => {
      return ({
        // label: `${contact.first_name} ${contact.last_name} (${contact.position}) - ${contact.is_receive_messages}`,
        label: contact,
        // value: contact.first_name+contact.last_name,
        value: contact,
      })
    })
    : null

  return (
    <Select
      type="select"
      name="meeting-contacts"
      id="select-meeting-contacts"
      isMulti
      getValue={contactsOptions.filter(
        option => option.value === value)}
      options={contactsOptions}
      onChange={(arr) => {
        const ids = arr.map(obj => obj.value)
        onChange(ids)
      }}
      {...otherProps}
    />
  )
}

MeetingMessagesMultiSelectContacts.propTypes = propTypes

export default withLang(
  connect(mapStateToProps)(MeetingMessagesMultiSelectContacts))
