import actionTypes from '../action-types'

export const storeEntities = data => ({
  type: actionTypes.resources.STORE_COLLECTIONS,
  payload: {
    ...data,
  },
})

export const storeEntity = (entityType, entity) => ({
  type: actionTypes.resources.ADD_ENTITY,
  payload: {
    entityType,
    entity,
  },
})

export const updateEntity = (entityType, entity) => ({
  type: actionTypes.resources.UPDATE_ENTITY,
  payload: {
    entityType,
    entity,
  },
})