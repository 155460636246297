import actionTypes from '../action-types'

const initialState = []

export default (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.settings.READ_SETTINGS_SUCCEEDED: {
      const { settings } = action.payload
      return {
        ...state,
        settings,
      }
    }

    case actionTypes.settings.UPDATE_SETTINGS_SUCCEEDED: {
      const { settings } = action.payload
      return {
        ...state,
        settings,
      }
    }

    default:
      return state
  }

};